import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-information-dialog',
  templateUrl: 'informationDialog.component.html',
  styleUrls: ['../dialogs.scss', 'informationDialog.component.scss'],
})
export class InformationDialogComponent {
  title: any;
  descriptions: string[];
  object?: any;
  objectKeys?: string[];

  ngOnInit(): void {
    if (this.data.object === undefined) return;
    this.objectKeys = Object.keys(this.data.object);
  }

  constructor(
    public dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;

    if (data.description instanceof Array) {
      this.descriptions = data.description;
    } else {
      this.descriptions = [data.description];
    }

    if (data.object) {
      this.object = data.object;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
