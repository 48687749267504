import { environment } from '../../environments/environment';
import { DecoratorService } from './decorator.service';

const INCLUDE_RESULTS = false;

const url = `${environment.apiUrl}/api/misc/log-events`;

let intervalFn = undefined;

const events = [];

function processError(error) {
  const { name, message, stack } = error;
  return {
    name,
    message,
    stack,
  };
}

export function logClass(ignoreNames: string[] = []) {
  return function<T extends Function>(target: T) {
    Object.getOwnPropertyNames(target.prototype).forEach(prop => {
      if (ignoreNames.includes(prop)) {
        return;
      }

      const originalFn = target.prototype[prop];
      target.prototype[prop] = INCLUDE_RESULTS
      ? function(...params: any[]) {
        try {
          let result = originalFn.call(this, ...params);
          if (result.subscribe) {
            return result.subscribe(
              res => events.push({
                class: target.name,
                function: prop,
                params,
                result: res,
                timestamp: new Date(),
              }),
              err => events.push({
                class: target.name,
                function: prop,
                params,
                error: processError(err),
                timestamp: new Date(),
              })
            );
          } else if (result.then) {
            return result.then(res => events.push({
              class: target.name,
              function: prop,
              params,
              result: res,
              timestamp: new Date(),
            })).catch(err => events.push({
              class: target.name,
              function: prop,
              params,
              error: processError(err),
              timestamp: new Date(),
            }));
          } else {
            events.push({
              class: target.name,
              function: prop,
              params,
              result,
              timestamp: new Date(),
            });
            return result;
          }
        } catch (err) {
          events.push({
            class: target.name,
            function: prop,
            params,
            error: processError(err),
            timestamp: new Date(),
          });
          throw err;
        }
      }
      : function(...params: any[]) {
        events.push({
          class: target.name,
          function: prop,
          params,
          timestamp: new Date(),
        });
        return originalFn.call(this, ...params);
      };
    });
    return target;
  }
}

export function logFunc(className: string) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalFn = target[propertyKey];
    descriptor.value = INCLUDE_RESULTS
    ? function(...params: any[]) {
      try {
        let result = originalFn.call(this, ...params);
        if (result.subscribe) {
          return result.subscribe(
            res => events.push({
              class: className,
              function: propertyKey,
              params,
              result: res,
              timestamp: new Date(),
            }),
            err => events.push({
              class: className,
              function: propertyKey,
              params,
              error: processError(err),
              timestamp: new Date(),
            })
          );
        } else if (result.then) {
          return result.then(res => events.push({
            class: className,
            function: propertyKey,
            params,
            result: res,
            timestamp: new Date(),
          })).catch(err => events.push({
            class: className,
            function: propertyKey,
            params,
            error: processError(err),
            timestamp: new Date(),
          }));
        } else {
          events.push({
            class: className,
            function: propertyKey,
            params,
            result,
            timestamp: new Date(),
          });
          return result;
        }
      } catch (err) {
        events.push({
          class: target.name,
          function: propertyKey,
          params,
          error: processError(err),
          timestamp: new Date(),
        });
        throw err;
      }
    }
    : function(...params: any[]) {
      events.push({
        class: className,
        function: propertyKey,
        params,
        timestamp: new Date(),
      });
      return originalFn.call(this, ...params);
    };
  }
}

// if (!intervalFn) {
//   intervalFn = setInterval(() => {
//     // Send all the accumulated events to be logged in the api
//     if (events.length > 0) {
//       const toSend = events.slice();
//       DecoratorService
//         .getHttp()
//         .post(url, toSend)
//         .subscribe(
//           res => {
//             events.splice(0, events.length);
//           }
//         )
//     }
//   }, 5000);
// }
