<!-- IMAGE -->
<ng-container *ngIf="imageUrl && tiny == false && medium == false">
  <div class="doc_container">
    <img
      [src]="imageUrl"
      (click)="openImageDialog()"
      [alt]="imageUrl"
    />
    <button
      (click)="deleteImage()"
      class="pinploy_button red_button small_button"
    >
      Delete image
    </button>
  </div>
</ng-container>

<!-- IMAGE IF SMALL -->
<ng-container *ngIf="imageUrl && tiny == true">
  <img
    [src]="imageUrl"
    (click)="openImageDialog()"
    [alt]="imageUrl"
    class="doc_container_tiny"
  />
</ng-container>

<!-- IMAGE IF MEDIUM -->
<ng-container *ngIf="imageUrl && medium == true">
  <img
    [src]="imageUrl"
    (click)="openImageDialog()"
    [alt]="imageUrl"
    class="doc_container_medium"
  />
</ng-container>

<!-- PDF -->
<ng-container *ngIf="!imageUrl">
  <div
    class="pdf_container doc_container"
    (click)="openDoc()"
  >
    <mat-icon> picture_as_pdf </mat-icon>
  </div>
</ng-container>

