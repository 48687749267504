import { Offer } from "./offer.model";
import { OfferComment } from "./offerComment.model";
import { Review } from "./review.model";
import { Task } from "./task.model";
import { User } from "./user.model";

export class Report {
    id: number;
    message: string;
    status: 'active'|'removed';
    reason: "spam"|"offensive"|"rule_breach"|"other";
    type: ReportTypes;

    targetId: number;
    target: User;

    reporterId: number;
    reporter: User;
    
    itemId: number;

    task: Task;
    taskComment: any;
    offer: Offer;
    offerComment: OfferComment;
    review: Review;

    createdAt: string;
    updatedAt: string;

    constructor(data: any = null){
        Object.assign(this, data);
        if(this.target == null) this.target = new User();
        if(this.reporter == null) this.target = new User();
    }
}

export enum ReportTypes {
    TASK_COMMENT = "task_comment",
    OFFER_COMMENT = "offer_comment",
    USER = "user",
    OFFER = "offer",
    REVIEW = "review",
    TASK = "task"
}

export enum ReportReasons {
    SPAM = "spam",
    OFFENSIVE = "offensive",
    RULE_BREACH = "rule_breach",
    OTHER = "other"
}