<div class="dialog_container">
  <i
    class="material-icons exit_icon"
    (click)="close()"
    >close</i
  >
  <h3 [innerHtml]="title"></h3>
  <div>
    <mat-radio-group
      color="primary"
      id="to_view"
      class="cancel_options"
      aria-label="Select an option"
      [(ngModel)]="cancellationReason"
    >
      <mat-radio-button
        value="1"
        [value]="reasons[0]"
        (click)="setReason(reasons[0])"
        >TO no time</mat-radio-button
      >
      <mat-radio-button
        value="2"
        [value]="reasons[1]"
        (click)="setReason(reasons[1])"
        >TO personal reason</mat-radio-button
      >
      <mat-radio-button
        value="3"
        [value]="reasons[2]"
        (click)="setReason(reasons[2])"
        >TO decided</mat-radio-button
      >
      <mat-radio-button
        value="4"
        [value]="reasons[3]"
        (click)="setReason(reasons[3])"
        >Task out of scope</mat-radio-button
      >
      <mat-radio-button
        value="5"
        [value]="reasons[4]"
        (click)="setReason(reasons[4])"
        >PP no show or answer</mat-radio-button
      >
      <mat-radio-button
        value="6"
        [value]="reasons[5]"
        (click)="setReason(reasons[5])"
        >PP could not complete task</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <div class="input_area">
    <mat-form-field>
      <mat-label>Reason Comment</mat-label>
      <textarea
        matInput
        [disabled]="cancellationOptions.option === 'Force'"
        [(ngModel)]="description"
        placeholder="Reason Comment"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="button_wrapper">
    <mat-radio-group
      [(ngModel)]="cancellationOptions"
      class="cancel_options"
    >
      <mat-radio-button
        value="1"
        [value]="options[0]"
        (click)="setOptions(options[0])"
        >As user</mat-radio-button
      >
      <mat-radio-button
        value="2"
        [value]="options[1]"
        (click)="setOptions(options[1])"
        >Force (carefull)</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="buttons">
    <button
      class="pinploy_button small_button"
      (click)="close()"
    >
      Cancel
    </button>
    <button
      class="pinploy_button small_button red_button"
      [disabled]="!cancellationReason"
      (click)="apply()"
    >
      Continue
    </button>
  </div>
</div>
