<div>
    <h3 class="header ">Users active offers</h3>
</div>
<div class="dialog_container">
    <mat-tab-group mat-stretch-tabs>
        <mat-tab label="Accepted Offers" *ngIf="userAcceptedOffers.length !== 0">
            <div class="dialog_row_container" *ngFor="let offer of userAcceptedOffers; let i = index">
                <div class="dialog_column_container">
                    <div class="task_card_container">
                        <app-task-card [task]="offer.task" (closeDialogEvent)="closeDialogEvent($event)"></app-task-card>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Completed Offers" *ngIf="userCompletedOffers.length !== 0">
            <div class="dialog_row_container" *ngFor="let offer of userCompletedOffers; let i = index">
                <div class="dialog_column_container">
                    <div class="task_card_container">
                        <app-task-card [task]="offer.task" (closeDialogEvent)="closeDialogEvent($event)"></app-task-card>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Cancelled Offers" *ngIf="userCancelledOffers.length !== 0">
            <div class="mat-tab-description">
                <p>Offers here can either be "canceled" or "disputed"</p>
            </div>
            <div class="dialog_row_container" *ngFor="let offer of userCancelledOffers; let i = index">
                <div class="dialog_column_container">
                    <div class="task_card_container">
                        <app-task-card [task]="offer.task" (closeDialogEvent)="closeDialogEvent($event)"></app-task-card>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>