<div class="login_container">
  <mat-card>
    <h3>Admin Login</h3>

    <mat-form-field>
      <input matInput type="email" placeholder="Email" [(ngModel)]="email">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Password" [(ngModel)]="password">
    </mat-form-field>

    <button class="pinploy_button" (click)="login()" [disabled]="isBtnDisabled()"
            [ngClass]="{'disabled': isBtnDisabled()}">Login</button>
  </mat-card>
</div>
