// Helper service to give access to different angular services inside decorators
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Services {
  http: HttpClient
}

@Injectable()
export class DecoratorService {
  private static services = {} as Services;

  constructor(http: HttpClient) {
    DecoratorService.services.http = http;
  }

  public static getHttp() {
    if (!DecoratorService.services.http) {
      throw new Error('HttpClient not initialized');
    }
    return DecoratorService.services.http;
  }
}
