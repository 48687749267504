import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { StateService } from './state.service';
import { BrowserPushNotificationService } from './browserPushNotification.service';
import { Router } from '@angular/router';

@Injectable()
export class SocketService {
  baseUrl = environment.socketUrl;
  private socket: Socket;

  constructor(
    private router: Router,
    private pushNotifService: BrowserPushNotificationService,
    private storage: StorageService,
    private stateService: StateService,
  ) {}

  connectSocket() {
    if (!this.socket || (this.socket && !this.socket.connected)) {
      this.socket = io(this.baseUrl, {
        query: { token: this.storage.token },
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
      });

      this.socket.connect();

      setTimeout(() => {
        // this.storage.socketId = this.socket.id;
        this.listenToEvents();
      }, 1500);
    }
  }

  // Emit events
  emitEvent(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  listenToEvents() {
    this.socket.on('report', (data: any) => {
      this.pushNotifService.createNotification(
        'Report',
        'New report received',
        () => {
          this.router.navigate(['/reports']);
        },
      );
      this.stateService.totalActiveReportsComponentSource.next(data);
    });

    this.socket.on('report_removed', (data: any) => {
      if (data.ids) {
        this.stateService.readActiveReportsComponentSource.next(data.ids);
      } else if (data.id) {
        this.stateService.readActiveReportsComponentSource.next(data.id);
      }
    });

    this.socket.on('report_re_activated', (data: any) => {
      this.stateService.totalActiveReportsComponentSource.next(data);
    });

    this.socket.on('support_ticket_created', (data: any) => {
      this.pushNotifService.createNotification(
        'Support Ticket',
        'New support ticket received',
        () => {
          this.router.navigate(['/support-tickets']);
        },
      );
      this.stateService.updateTotalActiveSupportTickets(data);
    });

    this.socket.on('support_ticket_closed', (data: any) => {
      this.stateService.readActiveSupportTickets(data);
    });

    this.socket.on('support_ticket_re_opened', (data: any) => {
      this.stateService.updateTotalActiveSupportTickets(data);
    });

    this.socket.on('admin_action_request', (data: any) => {
      this.pushNotifService.createNotification(
        'Admin Action Request',
        'New admin action request received',
        () => {
          this.router.navigate(['/requests']);
        },
      );
      this.stateService.totalActiveAdminActionRequestsComponentSource.next(
        data,
      );
    });
    this.socket.on('admin_action_request_handled', (data: any) => {
      this.stateService.readActiveAdminActionRequestsComponentSource.next(data);
    });
  }

  // Disconnect
  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  getSocketId() {
    return this.socket.id;
  }
}
