import * as dateFns from "date-fns";

export class CommonFunctions {
  formatString(comment?: string | null) {
    if (comment === null || comment === undefined) {
      return "n/a";
    }

    return comment;
  }

  formatAmount(
    amount?: number | null,
    inputUnit: "kr" | "øre" = "øre"
  ): string {
    if (amount === null || amount === undefined) {
      return "n/a";
    }

    return `${inputUnit === "øre" ? amount / 100 : amount} kr.`;
  }

  formatTimestamp(timestamp?: Date | string | null): string {
    if (timestamp === null || timestamp === undefined) {
      return "n/a";
    }

    const finalTimestamp =
      typeof timestamp === "string" ? new Date(timestamp) : timestamp;

    return dateFns.format(finalTimestamp, "dd/MM/yyyy HH:mm:ss");
  }

  formatInteger(value?: number | null): string {
    if (value === null || value === undefined) {
      return "n/a";
    }

    return `${value}`;
  }

  formatBoolean(value?: boolean | null): string {
    if (value === null || value === undefined) {
      return "n/a";
    }

    return value === true ? "Yes" : "No";
  }
}
