import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-dialog',
  templateUrl: 'questionDialog.component.html',
  styleUrls: ['../dialogs.scss']
})
export class QuestionDialogComponent {

  title: any;
  description: any;

  constructor(
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.title = data.title;
    this.description = data.description;
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  apply(): void {
    this.close('yes');
  }
}
