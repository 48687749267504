import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';

import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-waiting',
  animations: [
    trigger(
      'waitingAnimation', [
        transition(':enter', [
          style({opacity: '0'}),
          animate('500ms', style({opacity: '1'}))
        ]),
        transition(':leave', [
          style({opacity: '1'}),
          animate('500ms', style({opacity: '0'}))
        ])
      ]
    )
  ],
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent implements OnInit, OnDestroy {

  isVisible: boolean;
  toggleWaitingComponentRef: Subscription;

  constructor (private stateService: StateService) {
    this.isVisible = false;
  }

  ngOnInit() {
    this.toggleWaitingComponentRef = this.stateService.toggleWaitingComponentSource.subscribe(
      (res) => { this.isVisible = res; }
    );
  }

  ngOnDestroy() {
    this.toggleWaitingComponentRef.unsubscribe();
  }
}
