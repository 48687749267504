import { BucketDefinition } from "./BucketDefinition.model";
import { BucketNotificationSchedule } from "./BucketNotificationSchedule.model";

export class BucketNotification {
  id: number;
  language: string;
  bucketDefinitionId: number;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  context: any;
  periodicity: number;
  hour: number;
  dynamicData?: string[];
  emailWanted: boolean;
  smsWanted: boolean;
  webWanted: boolean;
  notificationSent: number;

  bucketNotificationSchedules?: BucketNotificationSchedule[];
  bucketDefinition?: BucketDefinition;

  repeat?: number;
}
