import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';


@logClass()
@Injectable()
export class BanService {
  baseUrl = `${environment.apiUrl}/api/admin/users/bans`;

  constructor(
    private http: HttpClient
  ) {}

  getActiveBans(): Observable<any[]> {
    const url = this.baseUrl;
    return this.http.get<any[]>(url);
  }

  getAllBans(): Observable<any[]> {
    const url = `${this.baseUrl}/all`;
    return this.http.get<any[]>(url);
  }

  getBansForUser(userId: number): Observable<any[]> {
    const url = `${this.baseUrl}/${userId}`;
    return this.http.get<any[]>(url);
  }

  banUser(userId: number, until: Date, reason?: string, adminNotes?: string) {
    const url = this.baseUrl;
    const data = { userId, until, reason, adminNotes };
    return this.http.post<any>(url, data);
  }

  cancelBan(banId: number) {
    const url = `${this.baseUrl}/${banId}`;
    return this.http.delete<any>(url);
  }
}
