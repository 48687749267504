<div id="container">
  <div id="buttons">
    <button (click)="close.emit()">Back</button>
    <button (click)="showUses.emit()">See Uses</button>
    <button (click)="edit.emit()">Edit</button>
    <button (click)="delete.emit()">Delete</button>
  </div>
  <div id="main_content" *ngIf="coupon !== null">
    <div class="column">
      <p><span>Id: </span>{{ coupon.id }}</p>
      <p><span>Code: </span>{{ coupon.code }}</p>
      <p><span>Name: </span>{{ coupon.name }}</p>
      <p><span>Type: </span>{{ coupon.type }}</p>
      <p><span>Value: </span>{{ getCouponValue() }}</p>
      <p *ngIf="coupon.type === 'percentage'">
        <span>Maximum Amount: </span>{{ coupon.maxAmount }}
      </p>
      <p><span>Created At: </span>{{ formatTimestamp(coupon.createdAt) }}</p>
      <p><span>Updated At: </span>{{ formatTimestamp(coupon.updatedAt) }}</p>
    </div>
    <div class="column">
      <p><span>Comment: </span>{{ formatString(coupon.comment) }}</p>
      <p>
        <span>Valability Start: </span
        >{{ formatTimestamp(coupon.valabilityStart) }}
      </p>
      <p>
        <span>Valability End: </span>{{ formatTimestamp(coupon.valabilityEnd) }}
      </p>
      <p>
        <span>Minimum Offer Size: </span>{{ formatAmount(coupon.minOfferSize) }}
      </p>
      <p>
        <span>Maximum Redemptions: </span
        >{{ formatInteger(coupon.maxRedemptions) }}
      </p>
      <p>
        <span>Maximum Redemptions Per User: </span
        >{{ formatInteger(coupon.maxRedemptions) }}
      </p>
      <p>
        <span>First Task Only: </span>
        {{ formatBoolean(coupon.onlyFirstTask) }}
      </p>
      <p>
        <span>Deleted At: </span>
        {{ formatTimestamp(coupon.deletedAt) }}
      </p>
    </div>
  </div>
</div>
