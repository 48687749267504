<div class="reports-container">
  <div class="list">
    <mat-tab-group mat-stretch-tabs>
      <!-- Mat tab for Active reports -->
      <mat-tab label="Active">
        <paginated-list
          [data]="activeReports"
          (displayDataChanged)="updateActiveDisplayReports($event)"
          (dataRequested)="fetchReports()"
          [filterKeys]="filterKeys"
          [moreDataAvailable]="hasMore"
        >
          <a
            class="item"
            *ngFor="let report of updatedActiveReports"
            routerLink="{{ '/reports/' + report.id }}"
          >
            <div class="status_wrapper">
              <p class="id"><span class="bold">ID: </span>{{ report.id }}</p>
              <div class="column">
                <p>
                  <span class="bold">Target: </span
                  >{{ report?.target.firstName }} {{ report?.target.lastName }}
                </p>
                <p>
                  <span class="bold">Reported by: </span
                  >{{ report?.reporter.firstName }}
                  {{ report?.reporter.lastName }}
                </p>
              </div>
              <p class="id">
                <span class="bold">Item ID: </span>{{ report.itemId }}
              </p>
              <p>
                <span class="bold">Type: </span
                >{{
                  report.type.charAt(0).toUpperCase() + report.type.slice(1)
                }}
              </p>
              <p>
                <span class="bold">Reason: </span
                >{{
                  report.reason.charAt(0).toUpperCase() + report.reason.slice(1)
                }}
              </p>
              <p class="status">
                <span class="bold">Status: </span
                >{{
                  report.status.charAt(0).toUpperCase() + report.status.slice(1)
                }}
              </p>
              <p class="date">
                <span class="bold">Created at: </span
                >{{ convertTimestamp(report.createdAt) }}
              </p>
            </div>
          </a>
        </paginated-list>
      </mat-tab>

      <!-- Mat tab for removed reports -->
      <mat-tab label="Removed">
        <paginated-list
          [data]="removedReports"
          (displayDataChanged)="updateRemovedDisplayReports($event)"
          (dataRequested)="fetchReports()"
          [filterKeys]="filterKeys"
          [moreDataAvailable]="hasMore"
        >
          <a
            class="item"
            *ngFor="let report of updatedRemovedReports"
            routerLink="{{ '/reports/' + report.id }}"
          >
            <div class="status_wrapper">
              <p class="id"><span class="bold">ID: </span>{{ report.id }}</p>
              <div class="column">
                <p>
                  <span class="bold">Target: </span
                  >{{ report?.target.firstName }} {{ report?.target.lastName }}
                </p>
                <p>
                  <span class="bold">Reported by: </span
                  >{{ report?.reporter.firstName }}
                  {{ report?.reporter.lastName }}
                </p>
              </div>
              <p class="id">
                <span class="bold">Item ID: </span>{{ report.itemId }}
              </p>
              <p>
                <span class="bold">Type: </span
                >{{
                  report.type.charAt(0).toUpperCase() + report.type.slice(1)
                }}
              </p>
              <p>
                <span class="bold">Reason: </span
                >{{
                  report.reason.charAt(0).toUpperCase() + report.reason.slice(1)
                }}
              </p>
              <p class="status">
                <span class="bold">Status: </span
                >{{
                  report.status.charAt(0).toUpperCase() + report.status.slice(1)
                }}
              </p>
              <p class="date">
                <span class="bold">Created at: </span
                >{{ convertTimestamp(report.createdAt) }}
              </p>
            </div>
          </a>
        </paginated-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
