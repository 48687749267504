import { Component, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { QuestionDialogComponent } from "src/app/components/dialogs/questionDialog/questionDialog.component";
import { BucketDefinition } from "src/app/models/BucketDefinition.model";
import { BucketNotification } from "src/app/models/BucketNotification.model";
import { EmailTemplate } from "src/app/models/EmailTemplate.model";
import { BucketNotificationFormService } from "src/app/services/bucket-notification-form.service";
import { BucketNotificationService } from "src/app/services/bucket-notification.service";
import { NotificationService } from "src/app/services/notification.service";
import { StateService } from "src/app/services/state.service";

@Component({
  selector: "app-bucket-notification-details",
  templateUrl: "bucket-notification-details.component.html",
  styleUrls: ["bucket-notification-details.component.scss"],
})
export class BucketNotificationDetailsComponent implements OnInit {
  isNew = false;
  definitionId: number;
  notificationId: number;

  templates$: Observable<EmailTemplate[]>;

  repeatDays: number;
  numberDays: number;
  hour: string;

  form: UntypedFormGroup;

  bucketNotification$: Observable<BucketNotification>;
  bucketNotification: BucketNotification;
  bucketDefinition$: Observable<BucketDefinition>;
  bucketDefinition: BucketDefinition;

  selectedIndex = 0;

  notifyChangeSchedule: Subject<boolean> = new Subject<boolean>();

  repeatable = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private bucketNotificationService: BucketNotificationService,
    private bucketNotificationFormService: BucketNotificationFormService,
    private stateService: StateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.stateService.toggleWaitingPage(true);
    this.bucketNotification$ = this.activatedRoute.paramMap.pipe(
      map((params) => {
        const strDefId = params.get("defId");
        const strId = params.get("id");
        this.isNew = false;
        if (strId === "new") {
          this.isNew = true;
        } else if (isNaN(+strId)) {
          this.toastr.error(
            "Bucket notification id is invalid",
            "Not a number"
          );
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(["/notification-buckets", this.definitionId]);
          return;
        } else {
          this.notificationId = +strId;
        }

        if (!strDefId || isNaN(+strDefId)) {
          this.toastr.error("Bucket definition id is invalid", "Not a number");
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(["/notification-buckets"]);
          return;
        }
        this.definitionId = +strDefId;
        return [this.definitionId, this.notificationId];
      }),
      switchMap(() => {
        if (this.isNew) {
          this.initBucketDefinition();
          return of(new BucketNotification());
        }
        return this.bucketNotificationService.getBucketNotification(
          this.definitionId,
          this.notificationId
        );
      }),
      catchError((err) => {
        this.toastr.error(err.message, "Unknown error");
        this.stateService.toggleWaitingPage(false);
        this.router.navigate(["/notification-buckets", this.definitionId]);
        return of(new BucketNotification());
      }),
      tap((bucketNotification) => {
        if (this.isNew) return;
        if (!bucketNotification) {
          this.toastr.error("Unable to find bucket notification", "Not found");
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(["/notification-buckets", this.definitionId]);
        }
        this.bucketDefinition = bucketNotification.bucketDefinition;
        this.bucketNotification = bucketNotification;
      }),
      tap((bucketNotification) => {
        if (this.isNew) {
          this.form =
            this.bucketNotificationFormService.getNewBucketNotificationForm();
        } else {
          this.form =
            this.bucketNotificationFormService.getBucketNotificationForm(
              bucketNotification
            );
        }
        this.numberDays = this.getDays(this.form.get("periodicity").value);
        this.repeatDays = this.getDays(this.form.get("repeat").value);
        this.getHour(this.form.get("hour").value);
        this.addFormSubscribers();
      }),
      tap(() => this.stateService.toggleWaitingPage(false))
    );

    this.templates$ = this.notificationService.getTemplates().pipe(
      tap((templates) => {
        if (!templates || templates.length === 0) {
          this.toastr.error("Unable to find email templates", "Not found");
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(["/notification-buckets", this.definitionId]);
        }

        if (!this.isNew) {
          const template = this.bucketNotificationFormService.fillEmailTemplate(
            this.form,
            this.bucketNotification,
            templates
          );
          if (template) {
            this.notificationService.getFormGroupForTemplate(
              this.form.get("emailContext") as UntypedFormGroup,
              template
            );
            this.bucketNotificationFormService.fillEmailForm(
              this.form,
              this.bucketNotification
            );
          }
        }
      })
    );
  }

  initBucketDefinition() {
    this.bucketDefinition$ = this.bucketNotificationService
      .getBucketDefinition(this.definitionId)
      .pipe(
        tap((bucketDefinition) => {
          if (!bucketDefinition) {
            this.toastr.error("Unable to find bucket definition", "Not found");
            this.stateService.toggleWaitingPage(false);
            this.router.navigate(["/notification-buckets"]);
          }
          this.bucketDefinition = bucketDefinition;
        })
      );
  }

  getDays(ms: number) {
    const msPerDay = 1000 * 60 * 60 * 24;

    const days = Math.floor(ms / msPerDay);
    return days;
  }

  onRepeatableChange() {
    if (this.repeatable) {
      this.form.get("repeat").enable();
    } else {
      this.form.get("repeat").disable();
    }
  }

  onChangeRepeatDays() {
    if (this.repeatDays == null) {
      this.form.get("repeat").setValue(null);
      return;
    }

    const msPerDay = 1000 * 60 * 60 * 24;
    const ms = this.repeatDays * msPerDay;
    this.form.get("repeat").setValue(ms);
  }

  onChangePeriodicity() {
    if (this.numberDays == null) {
      this.form.get("periodicity").setValue(null);
      return;
    }

    const msPerDay = 1000 * 60 * 60 * 24;
    const ms = this.numberDays * msPerDay;
    this.form.get("periodicity").setValue(ms);
  }

  tabsIsInvalid(...forms: AbstractControl[]) {
    return forms.some((form) => form?.touched && form?.invalid);
  }

  private addFormSubscribers() {
    const onChanges = (value: boolean, index: number) => {
      if (value) this.selectedIndex = index;
      else this.goToSelectedOne(this.selectedIndex);
    };

    this.form.get("emailWanted").valueChanges.subscribe((value) => {
      onChanges(value, 0);
    });
    this.form.get("webWanted").valueChanges.subscribe((value) => {
      onChanges(value, 1);
    });
    this.form.get("pushWanted").valueChanges.subscribe((value) => {
      onChanges(value, 2);
    });
    this.form.get("smsWanted").valueChanges.subscribe((value) => {
      onChanges(value, 3);
    });
    if (this.isNew || this.form.get("emailWanted").value)
      this.selectedIndex = 0;
    else this.goToSelectedOne(this.selectedIndex);
  }

  private goToSelectedOne(currentIndex: number) {
    if (currentIndex !== this.selectedIndex) return;

    if (this.form.get("webWanted").value) this.selectedIndex = 1;
    else if (this.form.get("pushWanted").value) this.selectedIndex = 2;
    else if (this.form.get("smsWanted").value) this.selectedIndex = 3;
    else this.selectedIndex = 0;
  }

  getHour(msSinceMidnight: number) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setTime(date.getTime() + msSinceMidnight);
    const hour = date.getHours();
    const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
    const minute = date.getMinutes();
    const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
    this.hour = `${hourStr}:${minuteStr}`;
  }

  onChangeHour() {
    if (!this.hour) {
      this.form.get("hour").setValue(null);
      return;
    }
    const [hour, minute] = this.hour.split(":");
    const date = new Date();
    date.setHours(+hour, +minute, 0, 0);
    const msSinceMidnight = date.getTime() - date.setHours(0, 0, 0, 0);
    this.form.get("hour").setValue(msSinceMidnight);
  }

  onClickSave() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) {
      this.toastr.error("Please fill all the required fields!");
      return;
    }

    this.stateService.toggleWaitingPage(true);
    this.bucketNotificationService
      .createOrUpdateBucketNotification(
        this.form.value,
        this.isNew,
        this.definitionId,
        this.notificationId
      )
      .subscribe({
        next: (bucketNotification) => {
          this.toastr.success(
            `Bucket notification ${this.isNew ? "created" : "updated"}!`
          );
          this.stateService.toggleWaitingPage(false);
          this.router.navigate([
            "/notification-buckets",
            this.definitionId,
            bucketNotification.id,
          ]);
          this.notifyChangeSchedule.next(true);
        },
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(err);
        },
      });
  }

  onClickDelete() {
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      width: "583px",
      data: {
        title: "Delete bucket notification",
        description:
          "Are you sure you want to delete this bucket notification?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.stateService.toggleWaitingPage(true);
        this.deleteNotification();
      }
    });
  }
  deleteNotification() {
    this.bucketNotificationService
      .deleteNotification(this.notificationId)
      .subscribe({
        next: () => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success("Bucket notification deleted!");
          this.router.navigate(["/notification-buckets", this.definitionId]);
        },
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(err.message);
        },
      });
  }
}
