import { Title } from '@angular/platform-browser';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { isBefore, format } from 'date-fns';

import { MiscService } from 'src/app/services/misc.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../../services/payment.service';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { StateService } from '../../../services/state.service';
import { TagService } from '../../../services/tag.service';
import { BanService } from '../../../services/ban.service';
import { TaskService } from '../../../services/task.service';
import { StoredCreditsService } from 'src/app/services/storedCredits.service';
import { User } from '../../../models/user.model';

import { QuestionDialogComponent } from '../../../components/dialogs/questionDialog/questionDialog.component';
import { InformationDialogComponent } from 'src/app/components/dialogs/informationDialog/informationDialog.component';
import { CreateUserNoteDialog } from '../../../components/dialogs/createUserNoteDialog/createUserNoteDialog.component';
import { Tag } from '../../../models/tag.model';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { COMMA, ENTER, W } from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import { TaskListDialogComponent } from 'src/app/components/dialogs/taskListDialog/taskListDialog.component';
import { RemoveProfilePicDialogComponent } from 'src/app/components/dialogs/remove-profile-pic/removeProfilePicDialog.component';
import { UserStoredCreditsComponent } from 'src/app/components/dialogs/userStoredCredits/userStoredCredits.component';
import { UserStoredCreditsHistoryComponent } from 'src/app/components/dialogs/userStoredCreditsHistory/userStoredCreditsHistory.component';
import { Task } from 'src/app/models/task.model';

import { DeviceDetectorService } from 'ngx-device-detector';
import { UserTasksDialogComponent } from 'src/app/components/dialogs/userTasks/userTasks.component';
import { UserMediaPortfolioDialogComponent } from 'src/app/components/dialogs/userMediaPortfolio/userMediaPortfolio.component';
import { UserHeaderImageDialogComponent } from 'src/app/components/dialogs/userHeaderImage/userHeaderImage.component';
import { Offer } from 'src/app/models/offer.model';
import { OfferService } from 'src/app/services/offer.service';
import { UserActiveOffersDialogComponent } from 'src/app/components/dialogs/userActiveOffers/userActiveOffers.component';
import { UserPenaltiesDialogComponent } from 'src/app/components/dialogs/userPenaltiesDialog/userPenaltiesDialog.component';
import { UserProfileMergerComponent } from 'src/app/components/dialogs/user-profile-merger/user-profile-merger.component';
import { UserGiveGraceDialogComponent } from 'src/app/components/dialogs/userGiveGraceDialog/userGiveGraceDialog.component';
import { environment } from 'src/environments/environment';
import { Review } from 'src/app/models/review.model';
import { UntypedFormControl } from '@angular/forms';

enum NotificationFrequency {
  Off = 'off',
  Hourly = 'hourly',
  Daily = 'daily',
  Instant = 'instant',
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  user: User;
  userChanges: User;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  Tags: string[] = [];
  userTagsAmountTitle: string = 'Tags';
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<Tag[]>;
  allTags = [];
  tagChanged;
  tempTagArr = [];
  banDate: any;
  banReason = undefined;
  banError = undefined;
  adminNotes = undefined;
  banAndGraceHistory = [];
  userPenalties;

  userHHReviews: Review[] = [];
  userHHReviewsHasMore: boolean = false;
  userHHReviewLastPage = 0;
  userHHReviewsFilterKeys: string[] = ['id', 'message', 'fromUser'];
  displayUserHHReviews: any[] = [];

  userTOReviews: Review[] = [];
  userTOReviewsHasMore: boolean = false;
  userTOReviewLastPage = 0;
  userTOReviewsFilterKeys: string[] = ['id', 'message', 'fromUser'];
  displayUserTOReviews: any[] = [];

  initialUserReviewsTotalTitle: string = 'Reviews (0)';

  userPortfolio;
  userHeaderImage;

  phoneReadable;
  balance: number;
  otherBanReasonSelected = false;

  activeOffers: Offer[] = [];
  acceptedOffers: Offer[] = [];
  completedOffers: Offer[] = [];
  cancelledOffers: Offer[] = [];

  userNotificationSettings: any;
  notificationFrequencies: any;

  stripeUrl = environment.production
    ? 'https://dashboard.stripe.com'
    : 'https://dashboard.stripe.com/test';

  isBusy = false;

  userNotes = [];
  userNotesHasMore: boolean = false;
  lastPage = 0;
  userNotesFilterKeys: string[] = ['id', 'comment'];
  displayUserNotes: any[] = [];

  lastActivity: any;

  constructor(
    private title: Title,
    private miscService: MiscService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private stateService: StateService,
    private authService: AuthService,
    private userService: UserService,
    private storedCreditsService: StoredCreditsService,
    private tagService: TagService,
    private banService: BanService,
    private paymentService: PaymentService,
    private offerService: OfferService,
    private deviceDetector: DeviceDetectorService,
    private taskService: TaskService,
  ) {}

  ngOnInit(): void {
    this.user = new User();
    this.userChanges = { ...this.user };
    this.populateAllTagsFromDb();
    this.getUserProfile();
  }

  onTagChange(tag: string): void {
    this.filteredTags = of(tag ? this._filter(tag) : this.allTags.slice());
  }

  syncHeight(): void {
    const userInfoLeft = document.getElementById('userInfoLeft');
    const userInfoRight = document.getElementById('userInfoRight');
    const userInfoMaxHeight = Math.max(
      userInfoLeft.clientHeight,
      userInfoRight.clientHeight,
    );
    userInfoLeft.style.height = userInfoMaxHeight + 'px';
    userInfoRight.style.height = userInfoMaxHeight + 'px';
  }

  setMetaTitle() {
    this.title.setTitle(
      `${this.user.firstName} ${this.user.lastName}'s profile`,
    );
  }

  getUserProfile() {
    this.stateService.toggleWaitingPage(true);
    this.route.params.subscribe((params) => {
      this.userService.getUserProfile(params.id).subscribe({
        next: (res) => {
          this.user = res;
          this.getUserActiveOffers();
          this.setMetaTitle();
          this.phoneReadable = this.makePhoneReadable(this.user.phone);
          this.userChanges = { ...this.user };
          this.getUserStoredCreditsBalance(this.user.id);
          this.getUsersTagsForUser(this.user.id);
          this.getPenalties(this.user.id);
          this.fetchUserPortfolio();
          this.fetchUserHeaderImage();
          this.getUserLastActivity();
          this.banService.getBansForUser(res.id).subscribe((res2) => {
            this.banAndGraceHistory = res2
              .map((b) => ({
                ...b,
                isActive:
                  !b.cancelled && isBefore(new Date(), new Date(b.bannedUntil)),
              }))
              .sort((a, b) => b.id - a.id);

            // sort bans by createdAt date
            this.banAndGraceHistory.sort((a, b) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            });
            this.mapBansToLinks();
          });
        },
        error: (err) => {
          this.authService.handleError(err, 'Failed to get user profile');
          this.stateService.toggleWaitingPage(false);
        },
        complete: () => {
          this.syncHeight();
          this.fetchUserNotes();
          this.fetchUserReviews();
          this.fetchNotificationSettings();
          this.stateService.toggleWaitingPage(false);
        },
      });
    });
  }

  getUserLastActivity() {
    this.userService.getLastActivity(this.user.id).subscribe({
      next: (res) => {
        this.lastActivity = res;
      },
      error: (err) => {
        this.authService.handleError(err, 'Failed to get user last activity');
      },
    });
  }

  updateDisplayUserNotes(userNotes: any[]): void {
    this.displayUserNotes = userNotes;
  }

  mapBansToLinks() {
    const regex = /Offer: (\d+)|Offer comment: (\d+)|Task comment: (\d+)/;

    this.banAndGraceHistory = this.banAndGraceHistory.map((entry) => {
      const match = regex.exec(entry.adminNotes);
      if (match) {
        let linkType;
        let linkId;
        if (match[1]) {
          linkType = 'Offer';
          linkId = parseInt(match[1]);
        } else if (match[2]) {
          linkType = 'Offer comment';
          linkId = parseInt(match[2]);
        } else if (match[3]) {
          linkType = 'Task comment';
          linkId = parseInt(match[3]);
        }
        return { ...entry, linkId, linkType };
      }
      return entry;
    });
  }

  navToBanItem(banItem: any, event: MouseEvent) {
    this.stateService.toggleWaitingPage(true);
    const newWindow = event.ctrlKey || event.metaKey;
    switch (banItem.linkType) {
      case 'Offer':
        this.taskService.getTaskByOfferId(banItem.linkId).subscribe({
          next: (res) => {
            const url = this.router
              .createUrlTree(['/tasks', res.id, 'offer', banItem.linkId])
              .toString();
            if (event.metaKey || event.ctrlKey) {
              window.open(url, '_blank', 'noopener');
            } else {
              this.router.navigateByUrl(url);
            }

            this.stateService.toggleWaitingPage(false);
          },
          error: () => {
            this.stateService.toggleWaitingPage(false);
            this.toastr.error('Offer not found');
          },
        });
        break;
      case 'Offer comment':
        this.taskService.getTaskByOfferCommentId(banItem.linkId).subscribe({
          next: (res) => {
            const url = this.router
              .createUrlTree(['/tasks', res.id, 'offer-comm', banItem.linkId])
              .toString();
            newWindow
              ? window.open(url, '_blank', 'noopener')
              : this.router.navigateByUrl(url);
            this.stateService.toggleWaitingPage(false);
          },
          error: () => {
            this.stateService.toggleWaitingPage(false);
            this.toastr.error('Offer comment not found');
          },
        });
        break;
      case 'Task comment':
        this.taskService.getTaskByTaskCommentId(banItem.linkId).subscribe({
          next: (res) => {
            const url = this.router
              .createUrlTree(['/tasks', res.id, 'task-comm', banItem.linkId])
              .toString();
            newWindow
              ? window.open(url, '_blank', 'noopener')
              : this.router.navigateByUrl(url);
            this.stateService.toggleWaitingPage(false);
          },
          error: () => {
            this.stateService.toggleWaitingPage(false);
            this.toastr.error('Task comment not found');
          },
        });
        break;
      default:
        this.stateService.toggleWaitingPage(false);
        this.toastr.error('Invalid link type');
    }
  }

  updateDisplayUserHHReviews(userHHReviews: any[]): void {
    this.displayUserHHReviews = userHHReviews;
    this.initialUserReviewsTotalTitle =
      'Reviews (' +
      (this.userHHReviews.length + this.userTOReviews.length) +
      ')';
  }
  updateDisplayUserTOReviews(userTOReviews: any[]): void {
    this.displayUserTOReviews = userTOReviews;
    this.initialUserReviewsTotalTitle =
      'Reviews (' +
      (this.userHHReviews.length + this.userTOReviews.length) +
      ')';
  }

  fetchUserReviews(): void {
    if (this.user.id) {
      this.fetchUserHHReviews();
      this.fetchUserTOReviews();
    }
  }

  fetchUserHHReviews(): void {
    this.userService
      .getUserReviews(this.user.id, 'pp', this.userHHReviewLastPage + 1)
      .subscribe(
        (res) => {
          this.userHHReviews = this.userHHReviews.concat(res.reviews);
          this.userHHReviewsHasMore = res.hasMore;
          this.userHHReviewLastPage += 1;
        },
        (err) => {
          this.authService.handleError(
            err,
            'Failed to get user Handyhander reviews!',
          );
        },
      );
  }

  fetchUserTOReviews(): void {
    this.userService
      .getUserReviews(this.user.id, 'to', this.userTOReviewLastPage + 1)
      .subscribe(
        (res) => {
          this.userTOReviews = this.userTOReviews.concat(res.reviews);
          this.userTOReviewsHasMore = res.hasMore;
          this.userTOReviewLastPage += 1;
        },
        (err) => {
          this.authService.handleError(
            err,
            'Failed to get user Task Owner reviews!',
          );
        },
      );
  }

  fetchUserNotes(): void {
    if (this.user.id) {
      if (!this.isBusy && this.userNotesHasMore) {
      }
      this.isBusy = true;
      this.userService.getUserNotes(this.user.id, this.lastPage + 1).subscribe(
        (res) => {
          if (res.hasMore && this.userNotes.length > 0) {
            this.userNotes = this.userNotes.concat(res.data);
          } else if (res.hasMore && this.userNotes.length === 0) {
            this.userNotes = res.data;
          } else if (!res.hasMore && this.userNotes.length === 0) {
            this.userNotes = res.data;
          }
          this.lastPage += 1;
          this.userNotesHasMore = res.hasMore;
        },
        (err) => {
          this.authService.handleError(err, 'Failed to get user notes!');
        },
        () => (this.isBusy = false),
      );
    }
  }

  updateNotificationSettings() {
    this.userService
      .updateUserNotificationSettings(
        this.user.id,
        this.userNotificationSettings,
      )
      .subscribe(
        (res) => {
          if (res) {
            this.toastr.success('Notification settings updated');
          }
        },
        (err) => {
          this.authService.handleError(
            err,
            'Failed to update notification settings',
          );
          this.toastr.error('Failed to update notification settings');
        },
      );
  }

  mapNotificationToValue(key: string): string {
    return NotificationFrequency[key];
  }

  fetchNotificationSettings(): void {
    if (this.user.id) {
      this.userService
        .getUserNotificationSettings(this.user.id)
        .subscribe((res) => {
          this.userNotificationSettings = res;
          this.notificationFrequencies = Object.keys(NotificationFrequency);
        });
    }
  }

  getPenalties(userId) {
    this.userService.getPenalties(userId).subscribe((res) => {
      this.userPenalties = res.slice();
      this.userPenalties.applied = res.filter(
        (penalty) => penalty.status === 'applied',
      );
      this.userPenalties.pending = res.filter(
        (penalty) => penalty.status === 'pending',
      );
      this.userPenalties.canceled = res.filter(
        (penalty) => penalty.status === 'canceled',
      );
      this.userPenalties.completed = res.filter(
        (penalty) => penalty.status === 'completed',
      );
      this.userPenalties.transfered = res.filter(
        (penalty) => penalty.status === 'transfered',
      );
    });
  }

  getUserStoredCreditsBalance(userId: number) {
    this.storedCreditsService.getBalance(userId).subscribe((res) => {
      let result = res.balance;
      this.balance = result;
      let realNumber = result / 100;
      this.user.storedCreditsBalance = realNumber.toString() + ' DKK';
    });
  }

  getUserLimitations(userId: number) {
    this.userService.getUserLimitations(userId).subscribe({
      next: (res) => {
        this.user.userLimitations = res;
      },

      error: (err) => console.error(err),
    });
  }

  makePhoneReadable(phone: string) {
    // Return the string phone in a format that has a space every 2 digits of the string
    if (phone !== null && phone !== undefined && phone !== '') {
      return phone.replace(/(\d{2})/g, '$1 ').trim();
    } else {
      return 'No number provided';
    }
  }

  getUserActiveOffers() {
    if (this.user.id) {
      this.offerService.getAllOffersByUserId(this.user.id).subscribe((res) => {
        this.activeOffers = res;
        this.completedOffers = this.activeOffers.filter(
          (offer) => offer.status === 'completed',
        );
        this.acceptedOffers = this.activeOffers.filter(
          (offer) => offer.status === 'accepted',
        );
        this.cancelledOffers = this.activeOffers.filter(
          (offer) => offer.status === 'canceled' || offer.status === 'disputed',
        );
      });
    }
  }

  selectionChange(gender: string) {
    this.userChanges.gender = gender;
  }

  isBtnDisabled() {
    for (const property in this.user) {
      if (this.user[property] !== this.userChanges[property]) {
        return false;
      }
    }
    return true;
  }

  isWithdrawalBtnDisabled() {
    if (this.user.storedCreditsBalance >= 0) {
      return false;
    }
    return true;
  }

  isUserTasksDialogBtnDisabled() {
    if (this.user.tasks && this.user.tasks.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  isUserActiveOffersDialogBtnDisabled() {
    if (this.activeOffers.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  isBanBtnDisabled() {
    if (this.banReason && this.banDate) {
      return false;
    }
    return true;
  }

  isRemoveProfilePictureBtnDisabled() {
    if (this.user.profileImg) {
      return false;
    }
    return true;
  }

  banUser() {
    if (!this.banDate || isBefore(this.banDate, new Date())) {
      this.banError = 'The ban date must be in the future';
    } else {
      this.banError = undefined;
      this.banService
        .banUser(this.user.id, this.banDate, this.banReason, this.adminNotes)
        .subscribe(
          (res) => {
            this.getUserProfile();
            this.toastr.success('User has been banned');
          },
          (err) => this.toastr.error('Failed banning user'),
        );
    }
  }

  onReasonSelectionChange(reason: any) {
    if (reason.value === 'true') {
      this.otherBanReasonSelected = true;
      this.banReason = null;
      const elem = document.getElementById('banReasonInput');
      if (elem) {
        const options = { focusVisible: true } as FocusOptions;
        elem.focus(options);
      }
    } else {
      this.banReason = reason.value;
    }
  }

  resetBanReason() {
    this.banReason = null;
    this.otherBanReasonSelected = false;
  }

  cancelBan() {
    const ban = this.getActiveBan();
    this.banService.cancelBan(ban.id).subscribe((_) => this.getUserProfile());
  }

  getActiveBan() {
    return this.banAndGraceHistory.find(
      (b) => !b.cancelled && isBefore(new Date(), new Date(b.bannedUntil)),
    );
  }

  getActiveGracePeriod() {
    const activeGracePeriod = this.banAndGraceHistory.find(
      (b) => b.type && isBefore(new Date(), new Date(b.until)) && !b.deletedAt,
    );

    if (activeGracePeriod) {
      return true;
    } else {
      return false;
    }
  }

  getDate(date: string) {
    return format(new Date(date), 'dd-MM-yyyy');
  }

  getLastOnlineDate() {
    return format(new Date(this.user.lastOnline), 'dd-MM-yyyy HH:mm');
  }

  goToUserPenalties(userId) {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(UserPenaltiesDialogComponent, {
      width: width,
      data: {
        userId: this.user.id,
        userActiveOffers: this.activeOffers,
        userAcceptedOffers: this.acceptedOffers,
        userCompletedOffers: this.completedOffers,
        userCancelledOffers: this.cancelledOffers,
      },
    });
  }

  openUserStoredCreditsDialog() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    const userStoredCreditDialog = this.dialog.open(
      UserStoredCreditsComponent,
      {
        width: width,
        data: { userId: this.user.id },
      },
    );

    userStoredCreditDialog.afterClosed().subscribe((result) => {
      this.getUserStoredCreditsBalance(this.user.id);
    });
  }

  openUserStoredCreditsHistoryDialog() {
    const width = this.deviceDetector.isDesktop() ? '80%' : '80%';
    const userStoredCreditsHistoryDialog = this.dialog.open(
      UserStoredCreditsHistoryComponent,
      {
        width: width,
        data: { userId: this.user.id },
      },
    );
  }

  removeUnchangedProperties() {
    for (const property in this.user) {
      if (this.user[property] === this.userChanges[property]) {
        delete this.userChanges[property];
      } else {
        this.userChanges[property] = this.user[property];
      }
    }
  }

  saveChanges() {
    this.stateService.toggleWaitingPage(true);
    this.removeUnchangedProperties();
    this.userService.editUser(this.user.id, this.userChanges).subscribe(
      (res) => {
        this.user = res;
        this.userChanges = { ...this.user };
        this.toastr.success('Your changes have been saved');
        this.ngOnInit();
      },
      (err) => {
        this.authService.handleError(err, 'Failed to change user profile');
      },
    );
  }

  deactivateAccount() {
    const dialogTitle = 'Deactivate account';
    const dialogDescription = `Are you sure you want to deactivate the account for user with id ${this.user.id}?`;

    const dialogRef = this.openQuestionDialog(dialogTitle, dialogDescription);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        const data = { isActive: false };
        this.userService.editUser(this.user.id, data).subscribe(
          () => {
            this.toastr.success('User account was deactivated successfully');
            this.user.isActive = false;
            this.userChanges = { ...this.user };
          },
          (err) => {
            this.authService.handleError(
              err,
              'Failed to deactivate user account',
            );
          },
        );
      }
    });
  }

  updateAccountCapabilities() {
    this.paymentService
      .updateAccountCapabilities(this.user.stripeAccountId)
      .subscribe((res) => {
        if (res) {
          this.toastr.success('Account capabilities updates', res);
        }
      });
  }

  deleteAccount() {
    const dialogTitle = 'Delete account';
    const dialogDescription = `Are you sure you want to delete the
      account for user with id ${this.user.id}? All objects ( tasks, comments, etc.)
      related with this user will be deleted as well.
      <span>This change is irreversible!</span>`;

    const dialogRef = this.openQuestionDialog(dialogTitle, dialogDescription);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.stateService.toggleWaitingPage(true);
        this.userService.deleteUser(this.user.id).subscribe(
          () => {
            this.stateService.toggleWaitingPage(false);
            this.toastr.success('User deleted successfully');
            this.router.navigate(['/users']);
          },
          (err) => {
            this.stateService.toggleWaitingPage(false);
            this.authService.handleError(err, 'Failed to delete user profile');
          },
        );
      }
    });
  }

  openQuestionDialog(title: string, description: string) {
    return this.dialog.open(QuestionDialogComponent, {
      width: '500px',
      data: { title: title, description: description },
    });
  }

  browseUsersForward() {
    const forwardUser = this.user.id + 1;
    this.browseUser(forwardUser);
  }

  browseUsersBackwards() {
    this.user.id -= 1;
    this.router.navigate([`/users/${this.user.id}`]);
    const backUserId = (this.user.id -= 1);
    this.browseUser(backUserId);
  }

  browseUser(userId) {
    this.Tags = [];
    this.allTags = [];
    this.userService.getUserProfile(userId).subscribe(
      (res) => {
        this.user = res;
        this.userChanges = { ...this.user };
        this.getUsersTagsForUser(userId);
        this.user.id = userId;
        this.stateService.toggleWaitingPage(false);
      },
      (err) => {
        this.authService.handleError(err, 'Failed to get user profile');
      },
    );
  }

  /* Tag Section for User */
  getUsersTagsForUser(userId) {
    this.tagService.getUsersTagsFromUserId(userId).subscribe((result) => {
      this.tempTagArr = result.slice();
      for (let i = 0; i < result.length; i++) {
        this.Tags.push(result[i].tag);
      }
      this.userTagsAmountTitle = 'Tags (' + this.Tags.length + ')';
    });
  }

  populateAllTagsFromDb() {
    try {
      this.tagService.getAllTags().subscribe((res) => {
        this.allTags = res;
      });
    } catch (error) {
      console.log(error);
    }
  }

  isTagBtnDisabled() {
    return !this.allTags || !this.Tags.length;
  }

  isUserPenaltiesBtnDisabled() {
    if (this.userPenalties?.length === 0) {
      return true;
    }
    return false;
  }

  isUserMediaPortfolioBtnDisabled() {
    if (this.userPortfolio?.length === 0) {
      return true;
    }
    return false;
  }

  isUserHeaderImageBtnDisabled() {
    if (this.userHeaderImage === undefined) {
      return true;
    }
    return false;
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    const filteredTags = this.allTags.filter(
      (tag) => tag.tag.toLowerCase().indexOf(filterValue) === 0,
    );
    return filteredTags;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const newTag = this.allTags.filter((x) => x.tag === value.toLowerCase());
    if ((value || '').trim()) this.Tags.push(newTag[0]);
    if (input) input.value = '';
    this.tagChanged.setValue(null);
  }

  remove(tag: string): void {
    const index = this.Tags.indexOf(tag);
    if (index >= 0) {
      this.Tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedTag = this.allTags.filter(
      (x) => x.tag === event.option.viewValue.toLowerCase(),
    );
    this.Tags.push(selectedTag[0].tag);
    this.tagInput.nativeElement.value = '';
    this.tagChanged.setValue(null);
  }

  saveTagChanges() {
    const userTagsToSave = {
      tags: [],
      userId: this.user.id,
    };

    // Reduce load on API - filter whole tag obj with id to send to backend.
    for (let j = 0; j < this.allTags.length; j += 1) {
      for (let i = 0; i < this.Tags.length; i++) {
        if (this.Tags[i] === this.allTags[j].tag) {
          userTagsToSave.tags.push(this.allTags[j]);
        }
      }
    }

    this.tagService.updateTagFromUser(userTagsToSave).subscribe((res) => {
      if (res) {
        this.toastr.success(`Tag updated for User ${this.user.firstName}`);
      }
    });
  }

  onSendEmailWithTaskParameter(template: string, type: string) {
    this.dialog
      .open(TaskListDialogComponent, {
        width: '800px',
        data: {},
      })
      .afterClosed()
      .subscribe((result: Task) => {
        if (result != null) this.sendEmail(template, type, result.id);
      });
  }

  onSendEmailWithoutTaskParameter(template: string, type: string) {
    this.sendEmail(template, type);
  }

  sendEmail(template: string, type: string, taskId: number = null) {
    const taskText = taskId != null ? ` regarding task ${taskId}` : '';
    const dialogDescription = `Send a ${type} e-mail to user ${this.user.id}${taskText}?`;
    if (confirm(dialogDescription)) {
      this.userService.sendEmail(this.user.id, template, taskId).subscribe(
        () => {
          this.toastr.success('E-mail sent');
        },
        (err) => {
          this.authService.handleError(err, 'Failed to send e-mail');
        },
      );
    }
  }

  openRemoveProfilePicture() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog
      .open(RemoveProfilePicDialogComponent, {
        width: width,
        data: { userId: this.user.id },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.user.profileImg = null;
          this.userChanges.profileImg = null;
        }
      });
  }

  openUserHeaderImageDialog() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    const headerDialog = this.dialog.open(UserHeaderImageDialogComponent, {
      width: width,
      data: { userId: this.user.id },
    });

    headerDialog.afterClosed().subscribe(() => {
      this.userService.getMediaHeader(this.user.id).subscribe(
        (result) => {
          if (result !== undefined) {
            this.userHeaderImage = result[0];
          } else {
            this.userHeaderImage = undefined;
          }
        },
        (error) => {
          console.log(error);
        },
      );
    });
  }

  fetchUserPortfolio() {
    this.miscService.getMediaPortfolio(this.user.id).subscribe(
      (result) => {
        if (result !== undefined) {
          this.userPortfolio = result;
        } else {
          this.userPortfolio = [];
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  fetchUserHeaderImage() {
    this.userService.getMediaHeader(this.user.id).subscribe(
      (result) => {
        if (result !== undefined) {
          this.userHeaderImage = result[0];
        } else {
          this.userHeaderImage = null;
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  openUserMediaPortfolio() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(UserMediaPortfolioDialogComponent, {
      width: width,
      data: { userId: this.user.id },
    });
  }

  openUserActiveOffersDialog() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(UserActiveOffersDialogComponent, {
      width: width,
      data: {
        userId: this.user.id,
        userActiveOffers: this.activeOffers,
        userAcceptedOffers: this.acceptedOffers,
        userCompletedOffers: this.completedOffers,
        userCancelledOffers: this.cancelledOffers,
      },
    });
  }

  openNewUserNoteDialog() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    const dialogRef = this.dialog.open(CreateUserNoteDialog, {
      width: width,
      data: {
        userId: this.user.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userService
          .createUserNote(this.user.id, result.userNote, result.additionalData)
          .subscribe((res) => {
            this.userNotes.push(res);
            this.displayUserNotes.push(res);
          });
      }
    });
  }

  sendGeneratedPassword() {
    const dialog = this.dialog.open(QuestionDialogComponent, {
      width: '500px',
      data: {
        title: 'Send new (generated) password to user',
        description: 'Are you sure?',
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 'yes') {
        this.userService.resetPassword(this.user.id).subscribe(
          (res) => {
            this.toastr.success('Password sent');
          },
          (err) => {
            this.authService.handleError(err, 'Failed to send password');
          },
        );
      }
    });
  }

  openUserTasksDialog() {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(UserTasksDialogComponent, {
      width: width,
      data: { userId: this.user.id, userTasks: this.user.tasks },
    });
  }

  isActiveBanOrGracePeriod(banOrGrace: any) {
    if ('cancelled' in banOrGrace) {
      if (banOrGrace.cancelled) {
        return false;
      }

      if (
        banOrGrace.cancelledAt === null ||
        banOrGrace.cancelledAt === undefined
      ) {
        return new Date(banOrGrace.bannedUntil) > new Date();
      }

      return true;
    } else if ('type' in banOrGrace) {
      if (banOrGrace.type) {
        return new Date(banOrGrace.until) > new Date();
      }

      return true;
    }

    throw new Error('Invalid object type');
  }

  openGiveGracePeriodDialog(type: 'ban' | 'handyhander-limitations') {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    const graceDialog = this.dialog.open(UserGiveGraceDialogComponent, {
      width: width,
      data: { userId: this.user.id, type: type },
    });

    graceDialog.afterClosed().subscribe((res) => {
      if (res === 'success') {
        this.getUserProfile();
      }
    });
  }

  confirmRemoveGracePeriod(type: 'ban' | 'handyhander-limitations') {
    let title = '';
    switch (type) {
      case 'ban':
        title = 'Remove ban grace period';
        break;
      case 'handyhander-limitations':
        title = 'Remove Handyhander limitations grace period';
        break;
    }
    const dialog = this.dialog.open(QuestionDialogComponent, {
      width: '500px',
      data: {
        title,
        description: 'Are you sure?',
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 'yes') {
        this.userService.removeGracePeriod(this.user.id, type).subscribe(
          (res) => {
            this.toastr.success('Grace period removed');
            this.getUserProfile();
          },
          (err) => {
            this.authService.handleError(err, 'Failed to remove grace period');
          },
        );
      }
    });
  }
  openMergeUsersDialog() {
    const mergeDialogRef = this.dialog.open(UserProfileMergerComponent, {
      width: '80%',
      data: { user: this.user },
    });

    mergeDialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.getUserProfile();
      }
    });
  }

  openUserInformationDetailsDialog() {
    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '80%',
      data: { title: 'User information', object: this.user },
    });
  }

  updateUserLimitations() {
    this.userService.updateUserLimitations(this.user.id).subscribe({
      next: (res) => {
        this.toastr.success('User limitations updated');
      },
      error: (err) => {
        this.authService.handleError(err, 'Failed to update user limitations');
      },
      complete: () => {
        this.getUserLimitations(this.user.id);
      },
    });
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }
}
