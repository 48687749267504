import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { resourceLimits } from 'worker_threads';
import { MiscService } from 'src/app/services/misc.service';
@Component({
  selector: 'user-media-portfolio-dialog',
  templateUrl: 'userMediaPortfolio.component.html',
  styleUrls: ['../dialogs.scss', 'userMediaPortfolio.component.scss'],
})
export class UserMediaPortfolioDialogComponent implements OnInit {
  constructor(
    private miscService: MiscService,
    public userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserMediaPortfolioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user media portfolio dialog');
    }
  }

  userData: User;
  userPortfolio: any;

  ngOnInit(): void {
    this.fetchUserData();
    this.fetchUserPortfolio();
  }

  closeDialogEvent() {
    this.close();
  }

  fetchUserData() {
    this.userService
      .getUserProfile(this.data.userId)
      .subscribe((result) => (this.userData = result));
  }

  fetchUserPortfolio() {
    this.miscService.getMediaPortfolio(this.data.userId).subscribe((result) => {
      this.userPortfolio = result;
    });
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }

  reloadDialogData() {
    this.fetchUserPortfolio();
    if (this.userPortfolio.length <= 1) {
      this.close();
    }
  }
}
