<ng-container *ngIf="bucketNotification$ | async as bucketNotification">
  <div class="page_container" *ngIf="!isNew || (bucketDefinition$ | async)">
    <div class="header">
      <h2 *ngIf="isNew">New bucket notification</h2>
      <h2 *ngIf="!isNew">Bucket notification: {{ bucketNotification.id }}</h2>
      <button
        class="pinploy_button small_button"
        [routerLink]="['/notification-buckets', definitionId]"
      >
        Back
      </button>
    </div>

    <mat-divider></mat-divider>

    <div class="top_actions">
      <div class="row row_def_name">
        <div class="def_name">Definition name: {{ bucketDefinition.name }}</div>
        <div class="right">
          <button
            class="pinploy_button small_button red_button"
            *ngIf="!isNew"
            (click)="onClickDelete()"
          >
            Delete notification
          </button>
          <button class="pinploy_button small_button" (click)="onClickSave()">
            {{ isNew ? "Create" : "Save" }}
          </button>
        </div>
      </div>

      <div class="row">
        <!-- Description -->
        <mat-form-field appearance="fill" class="w100">
          <mat-label>Description</mat-label>
          <input matInput [formControl]="form.get('description')" required />
          <mat-error *ngIf="form.get('description').hasError('required')">
            Description is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row time">
        <mat-form-field appearance="fill">
          <mat-label>Days</mat-label>
          <input
            matInput
            [(ngModel)]="numberDays"
            type="number"
            min="0"
            required
            (change)="onChangePeriodicity()"
          />
          <mat-hint>
            Number of days before sending notification after event/campaign has
            been triggered
          </mat-hint>
          <mat-error *ngIf="form.get('periodicity').hasError('required')">
            Days is required
          </mat-error>
          <mat-error *ngIf="form.get('periodicity').hasError('min')">
            Days must be positive
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Hour</mat-label>
          <input
            matInput
            [(ngModel)]="hour"
            type="time"
            required
            (change)="onChangeHour()"
          />
          <mat-hint>
            Hour of the day when notification should be sent
          </mat-hint>
          <mat-error *ngIf="form.get('hour').hasError('required')">
            Hour is required
          </mat-error>
          <mat-error *ngIf="form.get('hour').hasError('min')">
            Hour must be positive
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="fill" class="language">
          <mat-label>Language</mat-label>
          <mat-select [formControl]="form.get('language')" required>
            <mat-option selected value="userSettings">User settings</mat-option>
            <mat-option value="dk">Danish</mat-option>
            <mat-option value="en">English</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('language').invalid">
            Language is required
          </mat-error>
        </mat-form-field>
        <div class="wanted">
          <mat-slide-toggle [formControl]="form.get('emailWanted')">
            Email
          </mat-slide-toggle>
          <mat-slide-toggle [formControl]="form.get('webWanted')">
            Web
          </mat-slide-toggle>
          <mat-slide-toggle [formControl]="form.get('pushWanted')">
            Push
          </mat-slide-toggle>
          <mat-slide-toggle [formControl]="form.get('smsWanted')">
            SMS
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="row flex1">
          <mat-slide-toggle [formControl]="form.get('repeatEnabled')">
            Repeatable
          </mat-slide-toggle>

          <mat-form-field appearance="fill" class="flex1">
            <mat-label>Days</mat-label>
            <input
              matInput
              [(ngModel)]="repeatDays"
              type="number"
              min="1"
              [required]="form.get('repeatEnabled').value"
              [disabled]="!form.get('repeatEnabled').value"
              (change)="onChangeRepeatDays()"
            />
            <mat-hint>
              After the first notification, how many days should pass before
              repeating the notification
            </mat-hint>
            <mat-error *ngIf="form.get('repeat').hasError('required')">
              Days is required
            </mat-error>
            <mat-error *ngIf="form.get('repeat').hasError('min')">
              Days must be positive
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex1"></div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="forms">
      <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
        <mat-tab
          label="Email"
          [disabled]="!form.get('emailWanted').value"
          [labelClass]="
            tabsIsInvalid(
              form.get('emailContext.template'),
              form.get('emailContext.dk'),
              form.get('emailContext.en')
            )
              ? 'tabs_error'
              : ''
          "
        >
          <ng-container
            *ngIf="templates$ | async as templates; else loadingEmail"
          >
            <app-notification-email-section
              [templates]="templates"
              [disabled]="!form.get('emailWanted').value"
              [contextForm]="form.get('emailContext')"
              [choosedLanguage]="form.get('language').value"
            ></app-notification-email-section>
          </ng-container>
          <ng-template #loadingEmail>
            <div class="loading_form_container">
              <mat-spinner></mat-spinner>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab
          label="Web"
          [disabled]="!form.get('webWanted').value"
          [labelClass]="
            tabsIsInvalid(form.get('webContext')) ? 'tabs_error' : ''
          "
        >
          <app-notification-web-section
            [contextForm]="form.get('webContext')"
            [choosedLanguage]="form.get('language').value"
            [disabled]="!form.get('webWanted').value"
          ></app-notification-web-section>
        </mat-tab>
        <mat-tab
          label="Push"
          [disabled]="!form.get('pushWanted').value"
          [labelClass]="
            tabsIsInvalid(form.get('pushContext')) ? 'tabs_error' : ''
          "
        >
          <app-notification-push-section
            [contextForm]="form.get('pushContext')"
            [choosedLanguage]="form.get('language').value"
            [disabled]="!form.get('pushWanted').value"
          ></app-notification-push-section>
        </mat-tab>
        <mat-tab
          label="SMS"
          [disabled]="!form.get('smsWanted').value"
          [labelClass]="
            tabsIsInvalid(form.get('smsContext')) ? 'tabs_error' : ''
          "
        >
          <app-notification-sms-section
            [contextForm]="form.get('smsContext')"
            [choosedLanguage]="form.get('language').value"
            [disabled]="!form.get('smsWanted').value"
          ></app-notification-sms-section>
        </mat-tab>
      </mat-tab-group>
    </div>

    <mat-divider></mat-divider>

    <div
      class="schedules"
      *ngIf="definitionId != null && notificationId != null"
    >
      <app-bucket-notification-schedule-list
        [definitionId]="definitionId"
        [notificationId]="notificationId"
        [requireChange]="notifyChangeSchedule"
      ></app-bucket-notification-schedule-list>
    </div>
  </div>
</ng-container>
