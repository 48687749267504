<div class="offer_container" *ngIf="loading == false">
  <h4 class="section_title first_title">Offer details</h4>

  <div class="section_body">

    <!-- ID -->
    <mat-form-field>
      <input matInput placeholder="ID" [(ngModel)]="offer.id" [disabled]="true">
    </mat-form-field>

    <!-- Status -->
    <mat-form-field>
      <mat-select placeholder="Status" [value]="offer.status" [disabled]="true">
        <mat-option value="pending">Pending</mat-option>
        <mat-option value="accepted">Accepted</mat-option>
        <mat-option value="rejected">Rejected</mat-option>
        <mat-option value="canceled">Canceled</mat-option>
        <mat-option value="completed">Completed</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>

  <h4 class="section_title">Offer details</h4>

  <div class="section_body">
    <!-- Offer creator -->
    <app-user-card *ngIf="offer.user" title="Offer creator" [userId]="offer.user.id"></app-user-card>

    <!-- Task details -->
    <app-task-card title="Task details" [task]="offer.task"></app-task-card>

    <!-- Task creator -->
    <app-user-card *ngIf="offer.task.user" title="Task creator" [userId]="offer.task.user.id"></app-user-card>
  </div>
  <mat-divider></mat-divider>
</div>
