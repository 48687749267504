<mat-card>
  <div class="card_body">
    <div class="card_row">
      <p class="strong">Id</p>
      <p>{{ penalty.id }}</p>
    </div>
    <div class="card_row">
      <p class="strong">Status</p>
      <p>{{ penalty.status }}</p>
    </div>
    <div class="card_row">
      <p class="strong">Amount</p>
      <p>{{ penalty.amount }}</p>
    </div>
    <div class="card_row">
      <p class="strong">Task</p>
      <p class="flex_p">
        {{ penalty.cancelTask?.task.title }} ({{
          penalty?.cancelTask?.task?.city
        }}, {{ penalty?.cancelTask?.task?.zipCode }})
        <a
          href="/tasks/{{ penalty?.cancelTask?.task?.id }}"
          target="_blank"
        >
          <mat-icon>open_in_new</mat-icon>
        </a>
      </p>
    </div>
    <div class="card_row">
      <p class="strong">Reason</p>
      <p>
        {{ penalty.cancelTask?.reasonComment }} ({{
          penalty.cancelTask?.reason
        }})
      </p>
    </div>
    <div class="card_row">
      <p class="strong">Applied to offer</p>
      <p>{{ penalty.appliedOfferId }}</p>
    </div>
  </div>
</mat-card>
