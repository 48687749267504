<div>
  <form class="form-container">
      <mat-form-field class="input">
        <input matInput placeholder="Object's id ..." type="number" [(ngModel)]="id" name="objectId" />
      </mat-form-field>
  
      <mat-form-field>
        <mat-select placeholder="Type" [(ngModel)]="type" name="typeSelector">
          <mat-option *ngFor="let type of types" [value]="type.value">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="getData()">Submit</button>
    </form>

</div>  

