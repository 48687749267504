import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { User } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-us-list",
  templateUrl: "./userList.component.html",
  styleUrls: ["./userList.component.scss"],
})
export class UserListComponent implements OnInit {
  users: User[] = [];
  displayUsers: User[] = [];
  filterKeys: string[] = ["id", "firstName", "lastName", "email", "phone"];
  lastPage = 0;
  hasMore = true;
  isBusy = false;

  constructor(
    private title: Title,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.setMetaTitle();
  }

  fetchUsers(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.userService.getUsers(this.lastPage + 1).subscribe(
        (res) => {
          this.users = this.users.concat(res.data);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
        },
        (err) => this.authService.handleError(err, "Failed to get users!"),
        () => (this.isBusy = false)
      );
    }
  }

  setMetaTitle() {
    this.title.setTitle(`Users`);
  }

  updateDisplayUsers(users: User[]): void {
    this.displayUsers = users;
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }
}
