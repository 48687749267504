<div class="penalties-container">
  <div id="controls">
    <h1>Penalties</h1>
    <p>
      Containing Penalties given to Pinloyees, that previously are responsible
      for tasks cancellations
    </p>
    <p>
      Penalty will be applied 10% of task budget max 150kr. on next accepted
      offer.
    </p>
    <br />
  </div>
  <paginated-list
    [data]="penalties"
    (displayDataChanged)="updateDisplayPenalties($event)"
    (dataRequested)="fetchPenalties()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
  >
    <div class="list">
      <a
        class="item"
        *ngFor="let penalty of displayPenalties"
        routerLink="{{ '/penalty/' + penalty.id }}"
      >
        <p class="id"><span class="bold">ID: </span>{{ penalty.id }}</p>
        <p>
          <span class="bold">IsTransfered: </span>{{ penalty.isTransfered }}
        </p>
        <p><span class="bold">Status: </span>{{ penalty.status }}</p>
        <p>
          <span class="bold">CancelTaskId: </span>{{ penalty.cancelTaskId }}
        </p>
        <p class="id">
          <span class="bold">OfferId: </span>{{ penalty.offerId }}
        </p>
        <p>
          <span class="bold">AppliedOfferId: </span>{{ penalty.appliedOfferId }}
        </p>
        <p class="id">
          <span class="bold">ChargeId: </span>{{ penalty.chargeId }}
        </p>
        <p class="id"><span class="bold">UserId: </span>{{ penalty.userId }}</p>
        <p class="date">
          <span class="bold">Created at: </span
          >{{ convertTimestamp(penalty.updatedAt) }}
        </p>
      </a>
    </div>
  </paginated-list>
</div>
