import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NotificationSegment } from "src/app/models/notificationSegment";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-segment-list",
  templateUrl: "notification-segment-list.component.html",
  styleUrls: ["notification-segment-list.component.scss"],
})
export class NotificationSegmentListComponent implements OnInit {
  notifications: NotificationSegment[] = [];
  displayNotifications: NotificationSegment[] = [];

  hasMore: boolean = true;
  isBusy: boolean = false;
  lastPage: number = 0;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  updateDisplayNotification(notifications: NotificationSegment[]): void {
    this.displayNotifications = notifications;
  }

  fetchMoreNotifications(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.notificationService.getNotifications(this.lastPage + 1).subscribe(
        (res) => {
          this.notifications = this.notifications.concat(res.notifications);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
        },
        (err) => this.toastr.error(err.message, "Failed to get notifications!"),
        () => (this.isBusy = false)
      );
    }
  }

  getEllapsedTime(notification: NotificationSegment): string {
    if (!notification.started_at || !notification.ended_at) return "-";
    const elapsedTime = Math.abs(
      new Date(notification.started_at).getTime() -
        new Date(notification.ended_at).getTime()
    );
    return new Date(elapsedTime).toLocaleTimeString("dk", {
      timeZone: "UTC",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  navigate(notification: NotificationSegment): void {
    this.router.navigate(["/notification-segments", notification.id]);
  }
}
