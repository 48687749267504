import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  hiddenPaths: string[];
  loggedUser: any;


  constructor(
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.hiddenPaths = ['/login'];

  }

  ngOnInit() {
    this.loggedUser = this.storageService.user;
  }

  showRightSide() {
    return this.hiddenPaths.indexOf(this.router.url) === -1;
  }

  logout() {
    this.authService.logout();
  }

  toggleNavbarOnPhone(): void {
    if (window.matchMedia('(max-width: 600px)').matches) {
      const navbar = document.getElementsByClassName('navbar_container')[0];
      const page = document.getElementsByClassName('page_container')[0];
      if (navbar.classList.contains('hidden')) {
        navbar.classList.remove('hidden');
        page.classList.add('hidden');
      } else {
        navbar.classList.add('hidden');
        page.classList.remove('hidden');
      }
    }
  }
  
  
}
