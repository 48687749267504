import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { NotificationSegment } from "src/app/models/notificationSegment";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-segment-details",
  templateUrl: "./notification-segment-details.component.html",
  styleUrls: ["./notification-segment-details.component.scss"],
})
export class NotificationSegmentDetails implements OnInit {
  notificationId: number;
  notification$: Observable<NotificationSegment>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.notification$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        const notificationId = params.get("id");
        if (!notificationId || isNaN(+notificationId)) {
          this.router.navigate(["/notification-segments"]);
          this.toastr.error("Invalid notification id!", "Error");
          return;
        }
        this.notificationId = +notificationId;
        return this.notificationService.searchNotification(+notificationId);
      }),
      catchError((err) => {
        this.router.navigate(["/notification-segments"]);
        this.toastr.error(err.message, "Error");
        return [];
      })
    );
  }

  getEllapsedTime(notification: NotificationSegment): string {
    if (!notification.started_at || !notification.ended_at) {
      return "N/A";
    }
    const ellapsedTime =
      new Date(notification.ended_at).getTime() -
      new Date(notification.started_at).getTime();

    const days = Math.floor(ellapsedTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (ellapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((ellapsedTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ellapsedTime % (1000 * 60)) / 1000);

    let result = "";
    if (days > 0) {
      result += `${days} day${days > 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
    }
    if (seconds > 0 || result.length === 0) {
      result += `${seconds} second${seconds > 1 ? "s" : ""} `;
    }
    return result.trim();
  }
}
