<mat-card>
  <div class="card_header">
    <h5>{{title}}</h5>
    <button class="pinploy_button small_button" (click)="goToCommentDetails()">Go to comment</button>
  </div>
  <div class="card_body">
    <div class="card_row">
      <p class="strong">Id</p>
      <p>{{comment.id}}</p>
    </div>
    <div class="card_row">
      <p class="strong">Status</p>
      <p>{{comment.status}}</p>
    </div>
    <div class="card_row">
      <p class="strong">Text</p>
      <p>{{comment.text}}</p>
    </div>
  </div>
</mat-card>
