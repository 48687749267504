import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss', '../cards.scss']
})
export class UserCardComponent implements OnInit {

  @Input() title: string;
  @Input() user: User;
  @Input() inclStripeCustomer: boolean;
  @Input() inclStripeConnect: boolean;
  @Input() userId?: number;
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    if(this.userId){
      this.userService.getUserProfile(this.userId).subscribe((user) => {
        this.user = user;
      });
    }
  }


  goToUserProfile(id: number, event: MouseEvent) {
    if (event.metaKey || event.ctrlKey) {
      const url = this.router.createUrlTree(['/users', id]).toString();
      window.open(url, '_blank');
    } else {
      this.router.navigate(['/users', id]);
    }
  }

}
