<!--Start of wrapper div-->
<div>
  <div
    *ngIf="offers"
    class="task-conversation-container"
  >
    <div
      id="title"
      *ngIf="offers.length != 0 && taskCommentsExist"
    >
      <br />
      <h3>Task Conversations</h3>
    </div>
  </div>
  <!--Offer and offer comments area-->
  <div
    *ngIf="offers"
    class="task-conversation-container"
  >
    <div
      class="offers-container"
      *ngIf="offers.length != 0"
    >
      <h3 class="padding">Offers</h3>
      <div
        #offerElements
        *ngFor="let offer of offers"
        id="{{ 'offer' + offer.id }}"
        [ngClass]="{ 'winning-plus-OPI': offer.offerPriceIncreases }"
      >
        <mat-expansion-panel
          *ngIf="offer?.offerPriceIncreases"
          [ngClass]="{
            offerPriceIncrease: true
          }"
          hideToggle
        >
          <mat-expansion-panel-header
            collapsedHeight="fit-content"
            expandedHeight="fit-content"
            *ngFor="let opi of offer.offerPriceIncreases"
            [ngClass]="{
              'individual-win':
                opi.status == 'accepted' || opi.status == 'completed',
              'individual-lost':
                opi.status == 'rejected' || opi.status == 'canceled'
            }"
          >
            <mat-panel-title>
              <div class="panel-title">
                <span class="bold OPI-Info"
                  >Price Increase ID:
                  <p class="OPI-Info">{{ opi.id }}</p>
                </span>
                <span class="bold OPI-Info splitter">|</span>
                <span class="bold OPI-Info"
                  >Status:
                  <p class="OPI-Info">{{ opi.status }}</p>
                </span>
                <span class="bold OPI-Info splitter">|</span>
                <span class="bold OPI-Info"
                  >Created at:
                  <p class="OPI-Info">
                    {{ opi.createdAt | date : 'dd/MM/yyyy HH:MM' }}
                  </p></span
                >
                <span class="bold OPI-Info splitter">|</span>
                <span class="bold OPI-Info"
                  >PriceBid:
                  <p class="OPI-Info">{{ opi.priceBid }}</p>
                </span>
              </div>
              <div class="panel-description">
                {{ opi.message }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel
          [ngClass]="{
            cancelledOffer:
              offer.status == 'canceled' || offer.status == 'disputed',
            winningOffer:
              offer.status == 'accepted' || offer.status == 'completed',
            censoredAcceptedOffer:
              offer.censored !== null &&
              (offer.status == 'accepted' || offer.status == 'completed'),
            censoredOffer:
              offer.censored !== null &&
              !(offer.status == 'accepted' || offer.status == 'completed'),
            deletedOffer: offer.status == 'erased',
            searchedFor: offer.id == inputTypeId && inputType == 'offer'
          }"
          [expanded]="
            (inputType == 'offer' && offer.id == inputTypeId) ||
            (inputType == 'offer-comment' &&
              getOfferIdFromOfferCommentId(inputTypeId) == offer.id)
          "
        >
          <mat-expansion-panel-header
            collapsedHeight="fit-content"
            expandedHeight="fit-content"
            *ngIf="users"
          >
            <mat-panel-title>
              <div class="panel-title">
                <span
                  ><b>OfferID: </b
                  ><a
                    class="link"
                    routerLink="{{ '/offers/' + offer.id }}"
                    >{{ offer.id }}</a
                  ></span
                >
                <span class="bold OPI-Info splitter">|</span>

                <span *ngIf="users && users[offer.userId]?.firstName">
                  <b>Sent by: </b
                  ><a
                    class="link"
                    routerLink="{{ '/users/' + offer.userId }}"
                    >{{ users[offer.userId]?.firstName }}</a
                  ></span
                >
                <span *ngIf="users && !users[offer.userId]?.firstName">
                  <b>Sent by: </b
                  ><a
                    class="link deleted-user"
                    routerLink="{{ '/users/' + offer.userId }}"
                    >DELETED USER</a
                  ></span
                >
                <span class="bold OPI-Info splitter">|</span>

                <span>
                  <b>Created at: </b
                  >{{ offer?.createdAt | date : 'dd/MM/yyyy HH:mm' }}</span
                >
                <span class="bold OPI-Info splitter">|</span>
                <span>
                  <b>Expires at: </b>
                  @if (offer?.expiresAt) {
                  {{ offer?.expiresAt | date : 'dd/MM/yyyy HH:mm' }}
                  } @else {
                  <b>No expiration</b>
                  }
                </span>
                <span class="bold OPI-Info splitter">|</span>

                <span class="bold"> PriceBid: </span>
                <p>
                  {{ offer?.priceBid }} + (Fee: {{ offer?.taskOwnerFee / 100 }})
                  kr.
                </p>
                <span class="bold OPI-Info splitter">|</span>

                <span
                  [ngClass]="{
                    bold: '',
                    'erased-offer': offer?.status == 'erased'
                  }"
                >
                  Status: {{ offer?.status }}</span
                >
                <span class="bold OPI-Info splitter">|</span>

                <span class="bold">
                  Censored:
                  {{ offer?.censored !== null ? 'true' : 'false' }}</span
                >
                <span class="bold OPI-Info splitter">|</span>

                <span *ngIf="offer.status == 'accepted'">
                  Completion request:
                  {{
                    task?.lastCompletionRequest
                      ? (task?.lastCompletionRequest | date : 'dd/MM/yyyy')
                      : 'null'
                  }}
                </span>
                <span
                  *ngIf="offer.status == 'accepted'"
                  class="bold OPI-Info splitter"
                  >|</span
                >

                <span>Offer level: {{ offer.tierData.tier }}</span>

                <span class="bold OPI-Info splitter">|</span>

                <span
                  class="link"
                  matTooltip="Shortcut: CMD / CTRL + click"
                  (click)="openCensor('offer', offer?.id, $event)"
                  >EDIT</span
                >
                <span class="bold OPI-Info splitter">|</span>
                <span
                  class="banLink"
                  (click)="openBanUserDialog(offer?.userId, 'offer', offer?.id)"
                  >BAN</span
                >
              </div>
              <div class="panel-description">
                <p>{{ offer?.message }}</p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            class="comment"
            *ngFor="let comment of offerComments[offer?.id]"
            id="{{ 'offerComment' + comment?.id }}"
            [ngClass]="{
              searchedFor:
                comment?.id == inputTypeId && inputType == 'offer-comment'
            }"
          >
            <p
              class="comment-header"
              [ngClass]="addHtmlClass(comment, offer)"
            >
              <span class="bold">Id: {{ comment?.id }} </span> |
              <span class="bold">Sent by: </span>
              <a
                *ngIf="
                  users?.length &&
                  comment?.userId &&
                  users[comment?.userId] &&
                  users[comment?.userId]?.firstName
                "
                class="link"
                routerLink="{{ '/users/' + comment?.userId }}"
              >
                {{ users[comment?.userId]?.firstName }}
                {{ users[comment?.userId]?.lastName }}
              </a>
              |
              <a
                *ngIf="
                  users?.length &&
                  comment?.userId &&
                  users[comment?.userId] &&
                  !users[comment?.userId]?.firstName
                "
                class="link deleted-user"
                routerLink="{{ '/users/' + comment?.userId }}"
              ></a>

              |

              <span class="bold"> at </span>
              {{ convertTimestamp(comment?.createdAt) }} |
              <span class="bold"> Censored: </span
              >{{ comment?.censored ? 'true' : 'false' }} |
              <span
                class="link"
                (click)="openCensor('offer-comment', comment?.id, $event)"
                >Edit
              </span>
              <span
                class="banLink"
                (click)="
                  openBanUserDialog(
                    comment?.userId,
                    'offer_comment',
                    comment?.id
                  )
                "
                >BAN</span
              >
            </p>
            <div
              class="comment-content"
              [ngClass]="addHtmlClass(comment)"
            >
              <p>{{ comment?.text }}</p>
              <div *ngIf="comment?.images && comment?.images.length > 0">
                <app-image-preview
                  *ngFor="let img of comment?.images"
                  [imageUrl]="img.url"
                  [fullImageObject]="img"
                  [medium]="true"
                  class="support-img"
                  (deleteImageEvent)="deleteImage(img.id)"
                ></app-image-preview>
              </div>
            </div>
          </div>

          <div
            *ngIf="offer?.status === 'pending'"
            class="admin-comment"
          >
            <button
              class="pinploy_button small_button"
              (click)="openAdminOfferComment('offer', offer)"
            >
              Send message
            </button>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>

  <!--Taskcomments and subcomments area-->
  <div
    class="task-comments-container"
    *ngIf="taskCommentsExist"
  >
    <h3 class="padding">Task comments</h3>

    <div
      #taskCommentElements
      *ngFor="let parentComment of taskComments[0]"
      id="{{ 'thread' + parentComment?.id }}"
    >
      <mat-expansion-panel
        [ngClass]="{
          censoredComment: parentComment?.censored !== null,
          searchedFor:
            parentComment?.id == inputTypeId && inputType == 'task-comment'
        }"
        [expanded]="
          (inputType == 'task-comment' && parentComment?.id == inputTypeId) ||
          (inputType == 'task-comment' &&
            getParentIdFromTaskCommentId(inputTypeId) == parentComment?.id)
        "
      >
        <mat-expansion-panel-header
          collapsedHeight="fit-content"
          expandedHeight="fit-content"
          *ngIf="users"
        >
          <mat-panel-title>
            <div class="panel-title">
              <span class="bold">Taskcomment: {{ parentComment?.id }} </span>
              <b class="splitter">|</b>

              <span *ngIf="users && users[parentComment?.userId]?.firstName"
                >Sent by:
                <a
                  class="link"
                  routerLink="{{ '/users/' + parentComment?.userId }}"
                  >{{ users[parentComment?.userId]?.firstName }}</a
                >
              </span>
              <span *ngIf="users && !users[parentComment?.userId]?.firstName"
                >Sent by:
                <a
                  class="link deleted-user"
                  routerLink="{{ '/users/' + parentComment?.userId }}"
                  >DELETED USER</a
                >
              </span>
              <b class="splitter">|</b>

              <span class="bold"
                >Created at:
                {{ convertTimestamp(parentComment?.createdAt) }}</span
              >
              <b class="splitter">|</b>
              <span class="bold"
                >Censored: {{ parentComment?.censored ? 'true' : 'false' }}
              </span>
              <b class="splitter">|</b>

              <span
                class="link"
                (click)="openCensor('task-comment', parentComment?.id, $event)"
                >EDIT</span
              >
              <b class="splitter">|</b>

              <span
                class="banLink"
                (click)="
                  openBanUserDialog(
                    parentComment?.userId,
                    'task_comment',
                    parentComment?.id
                  )
                "
                >BAN</span
              >
            </div>
            <div class="panel-description">
              {{ parentComment?.text }}
            </div>
            <div
              *ngIf="parentComment?.images && parentComment?.images.length > 0"
            >
              <app-image-preview
                *ngFor="let img of parentComment?.images"
                [imageUrl]="img.url"
                [fullImageObject]="img"
                [medium]="true"
                class="support-img"
                (deleteImageEvent)="deleteImage(img.id)"
              ></app-image-preview>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="comment"
          *ngFor="let comment of taskComments[parentComment?.id]"
          id="{{ 'taskComment' + comment?.id }}"
          [ngClass]="{
            searchedFor:
              inputType == 'task-comment' && inputTypeId == comment?.id
          }"
        >
          <p
            class="comment-header"
            [ngClass]="addHtmlClass(comment)"
          >
            <span class="bold">Id: {{ comment?.id }} </span>
            |
            <span class="bold">Sent by </span>
            <a
              *ngIf="users && users[comment?.userId]?.firstName"
              class="link"
              routerLink="{{ '/users/' + comment?.userId }}"
              >{{ users[comment?.userId]?.firstName }}
              {{ users[comment?.userId]?.lastName }}</a
            >

            | at {{ convertTimestamp(comment?.createdAt) }}
            |
            <a
              *ngIf="
                users &&
                !users[comment?.userId]?.firstName &&
                users[comment?.userId] !== null
              "
              class="link deleted-user"
              routerLink="{{ '/users/' + comment?.userId }}"
              >DELETED USER</a
            >
            | at {{ convertTimestamp(comment?.createdAt) }}
            |
            <span
              class="bold"
              *ngIf="comment?.censored"
            >
              Censored |
            </span>
            <span
              class="link"
              (click)="openCensor('task-comment', comment?.id, $event)"
              >Edit
            </span>
            <span
              class="banLink"
              (click)="
                openBanUserDialog(comment?.userId, 'task_comment', comment?.id)
              "
              >BAN</span
            >
          </p>
          <div
            class="comment-content"
            [ngClass]="addHtmlClass(comment)"
          >
            <p>{{ comment?.text }}</p>
            <div *ngIf="comment?.images && comment?.images.length > 0">
              <app-image-preview
                *ngFor="let img of comment?.images"
                [imageUrl]="img.url"
                [fullImageObject]="img"
                [medium]="true"
                class="support-img"
                (deleteImageEvent)="deleteImage(img.id)"
              ></app-image-preview>
            </div>
          </div>
        </div>
        <div class="admin-comment">
          <button
            class="pinploy_button small_button"
            (click)="openAdminOfferComment('task-comment', parentComment)"
          >
            Send message
          </button>
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <!--End of wrapper div-->
</div>
