import { Component, ChangeDetectorRef, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommentService } from "src/app/services/comment.service";
import { AuthService } from "src/app/services/auth.service";
import { StorageService } from "src/app/services/storage.service";
import { StateService } from "src/app/services/state.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "admin-task-conversation-comment-dialog",
  templateUrl: "adminTaskConversationCommentDialog.component.html",
  styleUrls: [
    "../dialogs.scss",
    "adminTaskConversationCommentDialog.component.scss",
  ],
})
export class AdminTaskConversationCommentDialog implements OnInit {
  comment: string = "";
  user: any;

  constructor(
    private toastr: ToastrService,
    private stateService: StateService,
    private storageService: StorageService,
    private authService: AuthService,
    private commentService: CommentService,
    public dialogRef: MatDialogRef<AdminTaskConversationCommentDialog>,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!data) {
      throw new Error(
        "No data passed to admin task conversation comment dialog."
      );
    }
  }

  ngOnInit(): void {
    this.user = this.storageService.user;
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  send() {
    if(this.comment.trim() === "") {
      this.toastr.error("Please enter a comment.");
      return;
    }
    this.stateService.toggleWaitingPage(true);
    if (this.data.type === "offer") {
      const offerCommentCreated = {
        offerId: this.data.id,
        taskId: this.data.taskId,
        text: this.comment,
        user: this.user,
      }
      this.commentService
        .createAdminOfferComment(offerCommentCreated)
        .subscribe((res) => {
          this.stateService.toggleWaitingPage(false);
          const reponse = {
            ...res,
            type: this.data.type
          };
          this.close(reponse);
        });
    } else {
      this.commentService
        .createAdminTaskComment({
          taskId: this.data.taskId,
          parentId: this.data.id,
          text: this.comment,
          user: this.user,
        })
        .subscribe((res) => {
          this.stateService.toggleWaitingPage(false);
          const reponse = {
            ...res,
            type: this.data.type
          };
          this.close(reponse);
        });
    }
  }
}
