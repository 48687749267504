<!-- TREE FOR CATEGORIES-->
<div
  class="type-container"
  [@fadeUpAnimation]="tagAdded ? 'void' : '*'"
>
  <mat-spinner
    *ngIf="isLoading"
    class="tagging-local-spinner"
  ></mat-spinner>
  <div
    class="box-wrapper"
    [ngClass]="{ blur: isLoading }"
  >
    <div class="box">
      <div class="tagging-wrapper">
        <div class="request-information">
          <h2>Tagging Failed</h2>
          <i>at: {{ request?.createdAt | date : 'hh:MM, dd/MM/yy' }}</i>
        </div>

        <div class="task-information">
          <div class="link-icon-area">
            <a
              href="/tasks/{{ task.id }}"
              target="_blank"
              matTooltip="Open entity in dedicated are"
            >
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>
          <div class="small-information">
            <div class="info-box small">
              <h4>Task ID</h4>
              <p>{{ task?.id }}</p>
            </div>

            <div class="info-box small">
              <h4>Task status</h4>
              <p
                *ngIf="task?.status !== 'pending'"
                style="color: red"
              >
                {{ task?.status | titlecase }}
              </p>

              <p
                *ngIf="task?.status == 'pending' && !task?.acceptedOffer"
                style="color: orange"
              >
                {{ task?.status | titlecase }}
              </p>

              <p
                style="color: green"
                *ngIf="task?.status == 'pending' && task?.acceptedOffer"
              >
                Has accepted offer
              </p>
            </div>
          </div>

          <div class="info-box">
            <h4>Task title</h4>
            <p>{{ task?.title }}</p>
          </div>

          <div class="info-box">
            <h4>Task description</h4>
            <p>{{ task?.description }}</p>
          </div>
        </div>

        <mat-form-field>
          <mat-chip-grid #taglist>
            <ng-container *ngFor="let tag of Tags">
              <mat-chip-option
                selected
                color="primary"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(tag)"
                >{{ tag | titlecase }}
                <mat-icon
                  matChipRemove
                  *ngIf="removable"
                  >cancel</mat-icon
                >
              </mat-chip-option>
            </ng-container>
            <input
              placeholder="Search to add a tag"
              [matChipInputFor]="taglist"
              #tagInput
              [matAutocomplete]="auto"
              [formControl]="tagChanged"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)"
              class="input_field"
              matInput
            />
          </mat-chip-grid>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
          >
            <mat-option
              *ngFor="let Tag of filteredTags | async"
              [value]="Tag.tag"
            >
              {{ Tag.tag | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>Tag categories</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-tree
            [dataSource]="treeData"
            [treeControl]="treeControl"
            class="tree"
          >
            <mat-tree-node
              *matTreeNodeDef="let node"
              matTreeNodePadding
              class="node"
            >
              <div class="categories-area">
                <button
                  mat-icon-button
                  matTreeNodeToggle
                  *ngIf="node.items && node.items.length > 0"
                  (click)="toggleNode(node, $event)"
                  class="button"
                >
                  <div class="node-name-and-button">
                    <p>{{ node.name | titlecase }}</p>
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{
                        treeControl.isExpanded(node)
                          ? 'expand_more'
                          : 'chevron_right'
                      }}
                    </mat-icon>
                  </div>
                </button>
                <mat-chip-selection-list
                  #tagList
                  *ngIf="node.expanded"
                  class="selection"
                >
                  <mat-chip-option
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(item)"
                    *ngFor="let item of node?.items"
                    (matChipInputTokenEnd)="add($event)"
                    (click)="addOrRemoveTag(item)"
                    >{{ item.tag | titlecase }}
                  </mat-chip-option>
                </mat-chip-selection-list>
              </div>
            </mat-tree-node>
          </mat-tree>
        </mat-expansion-panel>
      </div>

      <div class="button-area-wrapper">
        <div class="button-container">
          <button
            id="saveTagsButton"
            class="pinploy_button"
            (click)="handleTagging()"
          >
            Save tags
          </button>
          <button
            class="pinploy_button"
            (click)="instantNotificationsNewTags()"
            [disabled]="
              !tagsSavedToDB ||
              task?.status !== 'pending' ||
              task?.acceptedOffer
            "
            [ngClass]="{
              disabled:
                !tagsSavedToDB ||
                task?.status !== 'pending' ||
                task?.acceptedOffer
            }"
          >
            Send notifications
          </button>
        </div>
        <div class="button-container">
          <button
            class="pinploy_button red_button"
            (click)="dismiss()"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
