import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { TaskService } from "src/app/services/task.service";
import { UserService } from "src/app/services/user.service";
import { Task } from "../../models/task.model";

@Component({
  selector: "app-task-list",
  templateUrl: "./taskList.component.html",
  styleUrls: ["./taskList.component.scss"],
})
export class TaskListComponent implements OnInit {
  tasks: Task[] = [];

  displayTasks: Task[] = [];
  filterKeys: string[] = ["id", "title", "user", "fullName"];
  lastPage: number = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;

  constructor(
    private title: Title,
    private authService: AuthService,
    private taskService: TaskService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setMetaTitle();
  }

  fetchTasks(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      const pageToRequest = this.lastPage + 1;
      this.taskService.getTasks(pageToRequest).subscribe(
        (res) => {
          this.tasks = this.tasks.concat(
            res.tasks.map((val) => ({
              ...val,
              fullName: val.user.firstName + " " + val.user.lastName,
            }))
          );
          this.lastPage = pageToRequest;
          this.hasMore = res.hasMore;
        },
        (err) => this.authService.handleError(err, "Failed to get tasks!"),
        () => (this.isBusy = false)
      );
    }
  }

  setMetaTitle() {
    this.title.setTitle(`Tasks`);
  }

  updateDisplayTasks(tasks: Task[]): void {
    this.displayTasks = tasks;
  }
}
