import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Task } from 'src/app/models/task.model';
import { OfferService } from 'src/app/services/offer.service';
import { UserService } from 'src/app/services/user.service';
import { TaskService } from 'src/app/services/task.service';
@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss', '../cards.scss'],
})
export class TaskCardComponent implements OnInit {
  @Input() title = 'Task';
  @Input() task: Task;
  @Input() taskId: number;
  @Output() closeDialogEvent = new EventEmitter();

  constructor(
    private router: Router,
    private taskService: TaskService,
    private userService: UserService,
    private offerService: OfferService,
  ) {}

  ngOnInit() {
    if (this.task) {
      this.offerService
        .getOfferByTaskIdAndStatus(this.task.id, 'accepted')
        .subscribe((offer) => {
          if (offer) {
            this.task.acceptedOffer = offer;
          }
        });
      this.offerService
        .getOfferByTaskIdAndStatus(this.task.id, 'completed')
        .subscribe((offer) => {
          if (offer) {
            this.task.completedOffer = offer;
          }
        });
      this.userService.getUserProfile(this.task.userId).subscribe((user) => {
        this.task.user = user;
      });
    } else if (!this.task && this.taskId) {
      this.taskService.getTaskById(this.taskId).subscribe((task) => {
        this.task = task;
        this.offerService
          .getOfferByTaskIdAndStatus(this.task.id, 'accepted')
          .subscribe((offer) => {
            if (offer) {
              this.task.acceptedOffer = offer;
            }
          });
        this.offerService
          .getOfferByTaskIdAndStatus(this.task.id, 'completed')
          .subscribe((offer) => {
            if (offer) {
              this.task.completedOffer = offer;
            }
          });
        this.userService.getUserProfile(this.task.userId).subscribe((user) => {
          this.task.user = user;
        });
      });
    }
  }

  goToTaskDetails(id: number, event: MouseEvent) {
    if (event.metaKey || event.ctrlKey) {
      const url = this.router.createUrlTree(['/tasks', id]).toString();
      window.open(url, '_blank');
    } else {
      this.router.navigate(['/tasks', id]);
      this.closeDialogEvent.emit();
    }
  }
}
