import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { logClass } from './logger.decorator';
import { environment } from '../../environments/environment';

@logClass()
@Injectable()
export class ChatbotLogService {
  private API_URL = `${environment.apiUrl}/api/misc/chatbot-logs`;

  constructor(private http: HttpClient) { }

  getChatbotLogs(page: number, limit: number): Observable<any> {
    return this.http.get<any>(`${this.API_URL}?page=${page}&limit=${limit}`);
  }
}
