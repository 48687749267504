<div class="cancel-task-container">

  <h3 class="section_title first_title">Penalty Detail</h3>

  <div>
    <p>OfferId = Offer related to task that has been canceled - Original Source (Penalty calculated)</p>
    <p>Applied OfferId = Offer where Penalty has been applied (Next accepted offer)</p>
    <br/>
  </div>
  <mat-divider></mat-divider>

  <div class="section_body">

    <!-- ID -->
    <mat-form-field>
      <input matInput placeholder="ID" [(ngModel)]="penalty.id" [disabled]="true">
    </mat-form-field>

    <!-- cancelTaskId ID -->
    <mat-form-field>
      <input matInput placeholder="CancelTaskId" [(ngModel)]="penalty.cancelTaskId" [disabled]="true">
    </mat-form-field>

    <!-- Offer ID -->
    <mat-form-field>
      <input matInput placeholder="OfferId" [(ngModel)]="penalty.offerId" [disabled]="true">
    </mat-form-field>

    <!-- priceBid-->
    <mat-form-field>
      <input matInput placeholder="Amount" [(ngModel)]="penalty.amount" [disabled]="true">
    </mat-form-field>

    <!-- isTransfered -->
    <mat-form-field>
      <input matInput placeholder="isTransfered" [(ngModel)]="penalty.isTransfered" [disabled]="true">
    </mat-form-field>

    <!-- userId -->
    <mat-form-field>
      <input matInput placeholder="userId" [(ngModel)]="penalty.userId" [disabled]="true">
    </mat-form-field>

    <!-- Status -->
    <mat-form-field>
      <mat-select placeholder="Status" [value]="penalty.status" [(ngModel)]="penalty.status" required>
        <mat-option value="pending">Pending</mat-option>
        <mat-option value="canceled">Canceled</mat-option>
        <mat-option value="completed">Completed</mat-option>
        <mat-option value="applied">Applied</mat-option>
        <mat-option value="transfered">Transfered</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- chargeId -->
    <mat-form-field>
      <input [disabled]="true" matInput class="textarea-field" placeholder="chargeId" [(ngModel)]="penalty.chargeId">
    </mat-form-field>

    <!-- transactionId -->
    <mat-form-field>
      <input [disabled]="true" matInput class="textarea-field" placeholder="transactionId" [(ngModel)]="penalty.transactionId">
    </mat-form-field>

    <!-- appliedOfferId -->
    <mat-form-field>
      <input [disabled]="true" matInput class="textarea-field" placeholder="appliedOfferId" [(ngModel)]="penalty.appliedOfferId">
    </mat-form-field>

  </div>


  <div style="display: flex; justify-content: space-around;">
    <button class="pinploy_button small_button save-btn" [ngClass]="{ 'disabled': isBtnDisabled() }"
            [disabled]="isBtnDisabled()" (click)="saveChanges()">Save</button>
    <button class="pinploy_button small_button save-btn" [ngClass]="{ 'disabled': isBtnDisabled() }"
            (click)="goToUser(ppUserId)">Visit Pinployee</button>
  </div>


  <mat-divider></mat-divider>

  <!-- Split up - where penalty was Penalized = original source -->
  <div class="section_body">
    <div style="padding-bottom: 10px">
      <h3>Original previously task where cancellation caused penalty </h3>
    </div>
    <!-- Task Title -->
    <mat-form-field>
      <input matInput placeholder="Task Title" value="{{originalTask?.title}}" [disabled]="true">
    </mat-form-field>

    <!-- Task Description -->
    <mat-form-field>
      <input matInput placeholder="Task Description" value="{{originalTask?.description}}" [disabled]="true">
    </mat-form-field>

    <!-- Task budget -->
    <mat-form-field>
      <input matInput placeholder="Task Budget" value="{{originalTask?.budget}}" [disabled]="true">
    </mat-form-field>

    <!-- Task Address -->
    <mat-form-field>
      <input matInput placeholder="Task Address" value="{{originalTask?.address}}" [disabled]="true">
    </mat-form-field>

    <!-- Task City -->
    <mat-form-field>
      <input matInput placeholder="Task City" value="{{originalTask?.city}}" [disabled]="true">
    </mat-form-field>

    <!-- Task budget -->
    <mat-form-field>
      <input matInput placeholder="Task Zipcode" value="{{originalTask?.zipCode}}" [disabled]="true">
    </mat-form-field>

    <ng-container *ngIf="originalTask?.images && originalTask?.images.length > 0">
      <img *ngFor="let img of originalTask?.images" width="100%" height="auto" src="{{img.url}}">
    </ng-container>

  </div>


</div>

