import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { Report } from '../models/report.model';
import { logClass } from './logger.decorator';

import { SocketService } from './socket.service';

@logClass()
@Injectable()
export class ReportService {
  baseHref = `${environment.apiUrl}/api/admin/reports`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private socketService: SocketService,
  ) {}

  getReports(): Observable<any[]> {
    const url = `${this.baseHref}`;
    return this.http.get<any[]>(url);
  }

  getActiveReports(): Observable<any[]> {
    const url = `${this.baseHref}/active`;
    return this.http.get<any[]>(url);
  }

  getActiveReportsCount(): Observable<number> {
    const url = `${this.baseHref}/count-active`;
    return this.http.get<number>(url);
  }

  getRemovedReports(): Observable<any[]> {
    const url = `${this.baseHref}/removed`;
    return this.http.get<any[]>(url);
  }

  editReport(id: number, data: any): Observable<any> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/${id}`;
    return this.http.put<any>(url, data);
  }

  editReportStatus(
    id: number,
    status: 'active' | 'removed',
  ): Observable<Report> {
    const socket = this.socketService.getSocketId();

    const url = `${this.baseHref}/${id}`;
    let data = { status: status, socketId: socket };

    return this.http.put<Report>(url, data);
  }

  closeAllCorrespondingReports(entity: string, id: number): Observable<any> {
    const socket = this.socketService.getSocketId();
    let data = {
      socketId: socket,
      entity: entity,
      id: id,
    };
    const url = `${this.baseHref}/close-all-for`;
    return this.http.put<any>(url, data);
  }

  getOneReport(id: number): Observable<Report> {
    const url = `${this.baseHref}/${id}`;
    return this.http.get<Report>(url);
  }
}
