<mat-card *ngIf="user">
  <div class="card_header">
    <h5>{{ title }}</h5>
    <button
      class="pinploy_button small_button"
      (click)="goToUserProfile(user.id, $event)"
    >
      Open user profile
    </button>
  </div>
  <div class="card_body">
    <!-- USER ID-->
    <div class="card_row">
      <p class="strong">Id</p>
      <p *ngIf="user.id">{{ user.id }}</p>
    </div>

    <!-- USER NAME-->
    <div class="card_row">
      <p class="strong">Name</p>
      <p>{{ user.firstName }} {{ user.lastName }}</p>
    </div>

    <!-- USER EMAIL-->
    <div class="card_row">
      <p class="strong">Email</p>
      <p *ngIf="user.email">{{ user.email }}</p>
      <p *ngIf="!user.email">No email</p>
    </div>

    <!-- USER PHONE-->
    <div class="card_row">
      <p class="strong">Phone</p>
      <p *ngIf="user.phone">{{ user.phone }}</p>
      <p *ngIf="!user.phone">No phone number</p>
    </div>

    <!-- USER STRIPE CUSTOMER ID-->
    <div
      class="card_row"
      *ngIf="inclStripeCustomer && user.stripeCustomerId"
    >
      <p class="strong">Stripe Customer</p>
      <div class="icon-row">
        <p>{{ user.stripeCustomerId }}</p>
        <a
          target="_blank"
          href="https://dashboard.stripe.com/customers/{{
            user.stripeCustomerId
          }}"
          class="open-icon"
        >
          <mat-icon class="open-icon">open_in_new</mat-icon>
        </a>
      </div>
    </div>

    <!-- USER STRIPE CONNECT ID-->
    <div
      class="card_row"
      *ngIf="inclStripeConnect && user.stripeAccountId"
    >
      <p class="strong">Stripe Connect</p>
      <div class="icon-row">
        <p>{{ user.stripeAccountId }}</p>
        <a
          target="_blank"
          href="https://dashboard.stripe.com/connect/accounts/{{
            user.stripeAccountId
          }}"
          class="open-icon"
        >
          <mat-icon class="open-icon">open_in_new</mat-icon>
        </a>
      </div>
    </div>
  </div>
</mat-card>
