import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TaskService } from "../../services/task.service";
import { CancelTaskAutomation } from "../../models/cancelTaskAutomation";
import { AuthService } from "src/app/services/auth.service";
import { format } from "date-fns";
import { OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-cancel-task-list",
  templateUrl: "./cancelTaskAutomationList.component.html",
  styleUrls: ["./cancelTaskAutomationList.component.scss"],
})
export class CancelTaskAutomationListComponent implements OnInit {
  cancelledTaskAutomationList: CancelTaskAutomation[] = [];
  displayCancelledTaskAutomationList: CancelTaskAutomation[] = [];
  filterKeys: string[] = ["cancelTaskId", "status", "id"];
  lastPage: number = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private taskService: TaskService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setMetaData();
  }
  setMetaData(): void {
    this.title.setTitle("Cancelled tasks automation");
  }
  
  fetchCancelledTaskAutomation(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.taskService.getCancelTasksAutomation(this.lastPage + 1).subscribe(
        (res) => {
          this.cancelledTaskAutomationList = this.cancelledTaskAutomationList.concat(res.data);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
        },
        (err) =>
          this.authService.handleError(
            err,
            "Failed to get automated cancelled tasks!"
          ),
        () => (this.isBusy = false)
      );
    }
  }

  updateDisplayCancelledTaskAutomationList(
    automatedCancelledTaskList: CancelTaskAutomation[]
  ): void {
    this.displayCancelledTaskAutomationList = automatedCancelledTaskList;
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), "dd-MM-yyyy");
  }
}
