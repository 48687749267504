import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'src/app/services/user.service';
import { AuthService } from '../../../services/auth.service';
import { OfferService } from '../../../services/offer.service';

import { Offer } from '../../../models/offer.model';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
  offer: Offer;
  offerChanges: Offer;
  loading = true;
  constructor (
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private offerService: OfferService,
  ) { }

  ngOnInit() {
    this.offer = new Offer();
    this.offerChanges = { ...this.offer };

    this.route.params.subscribe(params => {
      this.offerService.getOfferById(params.id).subscribe(
        res => {
          this.offer = res;
          this.offerChanges = { ...this.offer };
          this.userService.getUserProfile(this.offer.userId).subscribe((user) => {
            this.offer.user = user;
            this.loading = false;
          });
        },
        err => { this.authService.handleError(err, 'Failed to get offer'); }
      );
    });
  }

  isBtnDisabled(): boolean {
    for (const property in this.offer) {
      if (this.offer[property] !== this.offerChanges[property]) {
        return false;
      }
    }

    return true;
  }

  removeUnchangedProperties() {
    for (const property in this.offer) {
      if (this.offer[property] === this.offerChanges[property]) {
        delete this.offerChanges[property];
      } else {
        this.offerChanges[property] = this.offer[property];
      }
    }
  }

  goToUserProfile(id: number) {
    this.router.navigate(['/users', id]);
  }

  goToTaskDetails(id: number) {
    this.router.navigate(['/tasks', id]);
  }
}
