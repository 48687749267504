import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { NotificationLog } from "src/app/models/notificationLog.model";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-log",
  templateUrl: "./notificationLog.component.html",
  styleUrls: ["./notificationLog.component.scss"],
})
export class NotificationLogComponent implements OnInit {
  logs: NotificationLog[] = [];
  displayLogs: NotificationLog[] = [];

  statistics$: Observable<{ countToday: number; countError: number }>;

  hasMore: boolean = true;
  isBusy: boolean = false;
  lastPage: number = 0;

  constructor(
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.statistics$ = this.notificationService.getNotificationStatistics();
  }

  updateDisplayLogs(logs: NotificationLog[]): void {
    this.displayLogs = logs;
  }

  fetchMoreNotificationLogs(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.notificationService.getLogs(this.lastPage + 1).subscribe(
        (res) => {
          this.logs = this.logs.concat(res.logs);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
          this.isBusy = false;
        },
        (err) =>
          this.toastr.error(err.message, "Failed to get notification logs!")
      );
    }
  }

  formatDate(date: Date): string {
    return new Date(date).toLocaleString();
  }

  formatUserIds(userIds: string): string {
    return userIds.split(";").filter(Boolean).join(", ");
  }

  navigate(log: NotificationLog): void {
    this.router.navigate(["/notification-logs", log.id]);
  }
}
