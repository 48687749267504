import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { format } from 'date-fns';
import { tap } from 'rxjs/operators';
import { ChatbotLogService } from 'src/app/services/chatbot-log.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-chatbot-log',
  templateUrl: './chatbotLog.component.html',
  styleUrls: ['./chatbotLog.component.scss']
})
export class ChatbotLogComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>([]);
  totalItems = 0;
  pageSize = 10;
  columnsToDisplay = ['userId', 'question', 'answer', 'answeredQuestion', 'createdAt'];
  isLoading = false;


  constructor(
    private chatbotLogService: ChatbotLogService,
    private title: Title
    ) { }

  ngOnInit() {
    this.loadData(1, this.pageSize);
    this.setMetaData();
  }

  setMetaData(): void {
    this.title.setTitle("Chatbot logs");
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadData(this.paginator.pageIndex + 1, this.paginator.pageSize))
      )
      .subscribe();
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), 'dd-MM-yyyy kk:mm:ss');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // if the userId of the row data matches the filter string, display the row
      if (!data.userId) return false;
      return data.userId.toString() === filter; // assuming userId is a number
    };
    this.dataSource.filter = filterValue.trim();

    // reset the paginator after filtering
    if (filterValue) {
      this.paginator.length = this.dataSource.filteredData.length;
    } else {
      this.paginator.length = this.totalItems;
    }
  }
  
  loadData(page: number, limit: number) {
    this.isLoading = true;
    this.chatbotLogService.getChatbotLogs(page, limit).subscribe(response => {
      this.totalItems = response.totalItems;
      this.dataSource.data = response.data;
      this.paginator.length = this.totalItems;
      this.isLoading = false;
    },
    error => {
      this.isLoading = false;
      // Handle your error here
      console.error(error);
    });
  }
}
