<div class="cancel-task-automation-container">
  <div id="controls">
    <h1>Automated cancellation flow</h1>
    <p>
      - Pinployee has the option ["accept", "decline"]. If no response within 72
      hours passive accept assumed.
    </p>
    <p>
      - Running scheduler every 72 hours, if "passive accept" -> process
      cancellation (Cancel Task Completion).
    </p>
    <p>- Process cancellation flow</p>
    <p>
      - Payment completion process for (Refund -> TaskOwner) and (Reverse
      transfer -> Pinployee).
    </p>
    <br />
  </div>
  <paginated-list
    [data]="cancelledTaskAutomationList"
    (displayDataChanged)="updateDisplayCancelledTaskAutomationList($event)"
    (dataRequested)="fetchCancelledTaskAutomation()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
  >
    <div class="list">
      <a
        class="item"
        *ngFor="let task of displayCancelledTaskAutomationList"
        routerLink="{{ '/cancel-task-automation/' + task.cancelTaskId }}"
      >
        <p class="id"><span class="bold">ID: </span>{{ task.id }}</p>
        <p><span class="bold">CancelTaskID: </span>{{ task.cancelTaskId }}</p>
        <p><span class="bold">FeeOwner: </span>{{ task.feeOwner }}</p>
        <p><span class="bold">Status: </span>{{ task.status }}</p>
        <p class="date">
          <span class="bold">Created at: </span
          >{{ convertTimestamp(task.createdAt) }}
        </p>
      </a>
    </div>
  </paginated-list>
</div>
