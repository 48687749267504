<div class="notification_page_container">
  <div class="header">
    <h1>Notification segments</h1>
    <p>
      Create a new notification to a segment or view all notifications. <br />
      Click on a notification to see its details.
    </p>
  </div>

  <div class="top_action">
    <div class="button_container">
      <button class="pinploy_button" routerLink="/notification-segments/new">
        Create
      </button>
    </div>
  </div>

  <mat-tab-group
    mat-stretch-tabs
    [selectedIndex]="selectedIndex"
    (selectedIndexChange)="changeSelectedIndex($event)"
  >
    <mat-tab label="In progress / Finished">
      <ng-template matTabContent>
        <app-notification-segment-list></app-notification-segment-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Scheduled">
      <ng-template matTabContent>
        <app-notification-segment-scheduled-list></app-notification-segment-scheduled-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
