import { Title } from '@angular/platform-browser';
import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { MiscService } from '../../services/misc.service';
import { StateService } from 'src/app/services/state.service';
import { BrowserPushNotificationService } from 'src/app/services/browserPushNotification.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  public searchString: string;
  public results: any;
  private timeOut: ReturnType<typeof setTimeout> | null;
  private searchInputChange = new Subject<string>();
  private searchInputSubscription: Subscription;

  constructor(
    private pushNotifService: BrowserPushNotificationService,
    private stateService: StateService,
    private title: Title,
    private authService: AuthService,
    private route: ActivatedRoute,
    private miscService: MiscService,
  ) {
    this.searchInputSubscription = this.searchInputChange
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.searchString = searchValue;
        this.fetchData();
      });
  }

  ngAfterViewInit(): void {
    this.setMetaTitle();
    this.route.params.subscribe((params) => {
      if (params['phoneNumber']) {
        this.searchString = params['phoneNumber'];
      }
      this.fetchData();
    });
    this.pushNotifService.askPermission();
  }

  setMetaTitle() {
    this.title.setTitle(`Search`);
  }

  onSearchInput(event: any): void {
    setTimeout(() => {
      this.searchInputChange.next(event.target.value);
    }, 0);
  }

  fetchData(): void {
    // Start the waiting indication.
    this.stateService.toggleWaitingPage(true);

    if (this.timeOut !== null) {
      clearTimeout(this.timeOut);
    }

    this.timeOut = setTimeout(() => {
      if (!this.searchString) {
        this.results = {};
        // Hide the waiting indication if searchString is empty.
        this.stateService.toggleWaitingPage(false);
      } else {
        this.miscService.getSearchResults(this.searchString, 'all').subscribe(
          (res) => {
            this.results = res;
          },
          (err) => {
            this.authService.handleError(err, 'Failed to search');
          },
          () => {
            this.stateService.toggleWaitingPage(false);
          },
        );
      }
    }, 250);
  }
  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
    if (this.searchInputSubscription) {
      this.searchInputSubscription.unsubscribe();
    }
  }
}
