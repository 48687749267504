<div class="content_feed_container">
  <div class="table_container">
    <div id="controls">
      <div id="startDate">
        <p>Start: </p>
        <input type="date" matInput #startInput [(ngModel)]="start">
        <button (click)="refetch()">
          Go
        </button>
      </div>
      <div id="filtering">
        <p>Filter: </p>
        <select placeholder="Filter..." [(ngModel)]="filter" (change)="applyFilterAndPagination()">
          <option *ngFor="let option of filterLabels" [value]="option.name">
            {{option.label}}
          </option>
        </select>
      </div>
      <div id="pagination">
        <button (click)="prevPage()" [disabled]="pageNumber <= 1">Prev</button>
        <p>{{getPaginationString()}}</p>
        <button (click)="nextPage()" [disabled]="pageNumber >= getMaxNumPages()">Next</button>
      </div>
    </div>

    <div id="headers">
      <p>Type</p>
      <p>ID</p>
      <p>Content</p>
      <p>Details</p>
      <p>Actions</p>
    </div>

    <div class="row" *ngFor="let row of displayData">
      <div id="type-and-id">
        <div>
          <span class="bold">Type: </span>
          {{formatType(row.type)}}
        </div>
        <div>
          <span class="bold">ID: </span>
          <a class="link" routerLink="{{generateCommentUrl(row.type, row.id)}}">
            {{row.id}}
          </a>
        </div>
      </div>
      <hr>
      <div class="vertical">
        <h3>Content:</h3>
        <p>{{row.text}}</p>
        <div *ngIf="row.images">
          <h3>Images:</h3>
          <div class="images">
            <a *ngFor="let img of row.images" href="{{img}}" target="_blank">
              <img class="image" src="{{img}}">
            </a>
          </div>
        </div>
        <div class="reports" *ngIf="hasReports(row.type, row.id)">
          <h3>Reports:</h3>
          <div class="report" *ngFor="let report of getReports(row.type, row.id)">
            <div>
              <p><span class="bold">ID:</span> {{report.id}}</p>
              <p><span class="bold">Reason:</span> {{report.reason}}</p>
              <p>{{report.message}}</p>
            </div>
            <div>
              <p class="bold">Reported by:</p>
              <p>
                <span class="bold">ID: </span>
                <a class="link" routerLink="{{generateEntityUrl('user', row.userId)}}">{{report.reporter.id}}</a>
              </p>
              <p><span class="bold">Name: </span>{{report.reporter.firstName}} {{report.reporter.lastName}}</p>
              <p><span class="bold">Email: </span>{{report.reporter.email}}</p>
              <p><span class="bold">Phone: </span>{{report.reporter.phone}}</p>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div id="details-and-buttons">
        <ul id="details">
          <li><span class="bold">Censored: </span>{{row.censored ? formatDate(row.censored) : 'false'}}</li>
          <li><span class="bold">Is task owner: </span>{{row.TOUserId === row.userId}}</li>
          <li><span class="bold">OfferId: </span><a class="link"
              routerLink="{{generateEntityUrl('offer', row.type === 'offer' ? row.id : row.offerId)}}">{{row.type ===
              'offer' ? row.id : row.offerId}}</a></li>
          <li><span class="bold">TaskId: </span><a class="link"
              routerLink="{{generateEntityUrl('task', row.taskId)}}">{{row.taskId}}</a></li>
          <li><span class="bold">Created: </span>{{formatDateTime(row.createdAt)}}</li>
          <li><span class="bold">Updated: </span>{{formatDateTime(row.updatedAt)}}</li>
          <li><span class="bold">User Name: </span><a class="link"
              routerLink="{{generateEntityUrl('user', row.userId)}}">{{row.userName}}</a></li>
          <li><span class="bold">User Email: </span><a class="link"
              routerLink="{{generateEntityUrl('offer', row.offerId)}}">{{row.userEmail}}</a></li>
          <li><span class="bold">Total num. of bans: </span>{{row.totalNumBans}}</li>
          <li><span class="bold">User currently banned: </span>{{row.currentlyBanned}}</li>
          <li><span class="bold">Num. censored entries: </span>{{row.numCensoredEntries}}</li>
        </ul>
        <div id="buttons">
          <button class="pinploy_button small_button" (click)="openEdit(row.type, row.id)" mat-button>
            Edit
          </button>
          <button class="pinploy_button small_button" (click)="openBanUser(row.userId)" mat-button>
            {{ row.banned ? 'Unban' : 'Ban' }} user
          </button>
          <button class="pinploy_button small_button" (click)="openMessageUser(row.userId)" mat-button>
            Message user
          </button>
          <button class="pinploy_button small_button" (click)="openRemoveProfilePicture(row.userId)" mat-button>
            Remove profile pic
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="data?.length > 0" class="backToTop">
      <p (click)="scrollToTop()"><span class="link">Back to top</span></p>
    </div>
  </div>
</div>
