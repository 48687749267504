import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { StoredCreditsService } from 'src/app/services/storedCredits.service';
import { OfferService } from 'src/app/services/offer.service';
import { Task } from 'src/app/models/task.model';

@Component({
  selector: 'user-stored-credits-history-dialog',
  templateUrl: 'userStoredCreditsHistory.component.html',
  styleUrls: ['../dialogs.scss', 'userStoredCreditsHistory.component.scss'],
})
export class UserStoredCreditsHistoryComponent implements OnInit {
  user: User;

  remainingCredits: number;
  remainingCreditsCalc: number[] = [];
  runningTotal: number = 0;

  userId: number;
  storedCredits: any;
  creditsToBeWithdrawn: number[] = [];
  creditsToBeWithdrawnTotalAmount: number = 0;
  displayedColumns: string[] = [
    'Credit id',
    'Type',
    'Transaction Type',
    'Offer',
    'Task',
    'Created at',
    'Amount',
    'Remaining',
  ];
  constructor(
    private storedCreditsService: StoredCreditsService,
    private userService: UserService,
    private offerService: OfferService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserStoredCreditsHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error(
        'No user id passed to user stored credits history dialog',
      );
    }
  }

  ngOnInit(): void {
    this.storedCreditsService
      .getAllStoredCreditEntriesForUser(this.data.userId)
      .subscribe((res) => {
        this.storedCredits = res.credits;
        this.storedCredits.forEach((creditEntry) => {
          creditEntry.markedAsExpired = false;

          if (creditEntry.offerId) {
            this.offerService
              .getOfferById(creditEntry.offerId)
              .subscribe((offer) => {
                creditEntry.task = new Task(offer.task);
              });
          }
          if (creditEntry.transactionType === 'credit') {
            const amountUsed = this.storedCredits.reduce(
              (acc, credit) =>
                credit.sourceCreditId === creditEntry.id &&
                credit.transactionType === 'debit' &&
                !credit.deletedAt
                  ? acc + credit.amount
                  : acc,
              0,
            );
            creditEntry.amountUsed = amountUsed;
            if (
              !amountUsed &&
              creditEntry.expiresAt &&
              new Date(creditEntry.expiresAt) < new Date()
            ) {
              creditEntry.markedAsExpired = true;
              this.runningTotal -= creditEntry.amount;
            }
            this.runningTotal += creditEntry.amount - creditEntry.reversed;
            if (creditEntry.deletedAt) {
              this.runningTotal -= creditEntry.amount;
            }
            creditEntry.remaining = this.runningTotal / 100;
          } else if (creditEntry.transactionType === 'debit') {
            this.runningTotal -= creditEntry.amount;
            creditEntry.remaining = this.runningTotal / 100;
            if (creditEntry.deletedAt) {
              this.runningTotal += creditEntry.amount;
            }

            if (creditEntry.reversed && !creditEntry.deletedAt) {
              let reversedCreditEntryRemaining =
                (this.runningTotal + creditEntry.reversed) / 100;

              let reversedCreditEntry = {
                id: creditEntry.id,
                transactionType: 'credit',
                type: 'reversed',
                amount: creditEntry.reversed,
                remaining: reversedCreditEntryRemaining,
              };
              this.runningTotal += creditEntry.reversed;
              this.storedCredits.push(reversedCreditEntry);
            }
          } else if (creditEntry.deletedAt) {
            creditEntry.remaining = this.runningTotal / 100;
          }
        });

        this.storedCredits = this.storedCredits.sort((a, b) => {
          if (a.id === b.id) {
            if (b.type === 'reversed') {
              return -1;
            } else {
              return 1;
            }
          } else {
            return a.id - b.id;
          }
        });
      });
  }
  closeDialogEvent() {
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
