<ng-container *ngIf="definition$ | async as definition">
  <div class="page_container">
    <div class="header">
      <h2>Bucket Definition Details: {{ definition.name }}</h2>
      <button
        class="pinploy_button small_button"
        routerLink="/notification-buckets"
      >
        Back
      </button>
    </div>

    <mat-divider></mat-divider>

    <div class="bucket_definition_actions_container">
      <div class="row">
        <ng-container *ngIf="!isNew; else newTypeTemplate">
          <!-- Readonly type -->
          <div class="type"><em>Type:</em> {{ definition.type }}</div>
        </ng-container>

        <ng-template #newTypeTemplate>
          <!-- update type -->
          <mat-form-field appearance="fill" class="w30">
            <mat-label>Type</mat-label>
            <mat-select
              [(ngModel)]="definition.type"
              required
              (selectionChange)="onSelectType(definition)"
            >
              <mat-option *ngFor="let type of types" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!definition.type"> Type is required </mat-error>
          </mat-form-field>
        </ng-template>

        <div class="row jc-end">
          <button
            *ngIf="canBeLaunched"
            class="pinploy_button small_button"
            (click)="onClickLaunchCampaign(definition)"
          >
            Launch Campaign
          </button>
          <button
            *ngIf="definition.isLaunched"
            class="pinploy_button small_button"
            [disabled]="true"
          >
            Campaign in progress !
          </button>

          <button
            class="pinploy_button small_button"
            (click)="editBucket(definition)"
          >
            {{ isNew ? "Create" : "Save" }}
          </button>
          <button
            *ngIf="!isNew"
            class="pinploy_button red_button small_button"
            (click)="onClickDeleteBucket(definition)"
          >
            Delete
          </button>
        </div>
      </div>

      <div class="row">
        <!-- Edit name -->
        <mat-form-field appearance="fill" class="w100">
          <mat-label>Name</mat-label>
          <input
            matInput
            required
            [(ngModel)]="definition.name"
            (change)="definition.name = definition.name.trim()"
          />
          <mat-error *ngIf="!definition.name"> Name is required </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <!-- Permission Group -->
        <mat-form-field appearance="fill" class="w30">
          <mat-label>Permission Group</mat-label>
          <mat-select [(ngModel)]="definition.permissionGroup">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let group of permissionGroups" [value]="group">
              {{ group }}
            </mat-option>
          </mat-select>
          <mat-hint>Leave empty for ignoring user preferences</mat-hint>
        </mat-form-field>

        <!-- bucketFunction -->
        <ng-container *ngIf="bucketFunctions$ | async">
          <mat-form-field appearance="fill" class="w30">
            <mat-label>Bucket Function</mat-label>
            <mat-select [(ngModel)]="definition.bucketFunction" required>
              <mat-option
                *ngFor="let func of bucketFunctions.get(definition.type)"
                [value]="func"
                >{{ func }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="!definition.bucketFunction">
              Bucket Function is required
            </mat-error>
          </mat-form-field>
        </ng-container>

        <!-- active -->
        <div class="row jc-center w30">
          <mat-slide-toggle [(ngModel)]="definition.isActive" color="primary">
            Is active
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row" *ngIf="definition.type === 'campaign'">
        <!-- LaunchDate -->
        <mat-form-field appearance="fill" class="w30">
          <mat-label>Launch Date</mat-label>
          <input
            matInput
            [matDatepicker]="pickerLaunchDate"
            [(ngModel)]="definition.launchDate"
            (dateChange)="definition.launchDate = $event.value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerLaunchDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerLaunchDate></mat-datepicker>
        </mat-form-field>

        <!-- endDate -->
        <mat-form-field appearance="fill" class="w30">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="pickerEndDate"
            [(ngModel)]="definition.endDate"
            (dateChange)="definition.endDate = $event.value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerEndDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
        </mat-form-field>

        <!-- Repeat -->
        <mat-form-field appearance="fill" class="w30">
          <mat-label>Repeat</mat-label>
          <mat-select [(ngModel)]="definition.repeat">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let repeat of repeats" [value]="repeat">
              {{ repeat }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="bucket_notification_container" *ngIf="!isNew">
      <div class="row jc-center">
        <button
          class="pinploy_button"
          (click)="navigateToNewNotificationBucket(definition)"
        >
          Add new notification
        </button>
      </div>
      <div
        class="bucket_notification_item"
        *ngFor="let notif of definition.bucketNotifications"
        (click)="navigateToNotificationBucket(notif)"
      >
        <div class="row">
          <h4 class="title">{{ notif.description }}</h4>
          <div>
            Sent
            {{ getTime(notif.periodicity) }}
            after
            {{
              definition.type === "campaign"
                ? "campaign was launched"
                : "event was triggered"
            }}
          </div>
        </div>
        <div class="row wanted">
          <div [ngClass]="{ active: notif.emailWanted }">Email</div>
          <div [ngClass]="{ active: notif.smsWanted }">SMS</div>
          <div [ngClass]="{ active: notif.pushWanted }">Push</div>
          <div [ngClass]="{ active: notif.webWanted }">Web</div>
        </div>

        <div class="row date">
          <div>Created: {{ notif.createdAt | date : "dd/MM/yyyy HH:mm" }}</div>
          <div>Updated: {{ notif.updatedAt | date : "dd/MM/yyyy HH:mm" }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
