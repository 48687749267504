import { Title } from '@angular/platform-browser';
import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

import { SupportTicket } from '../../../models/supportTicket.model';
import { SupportTicketAdmin } from '../../../models/supportTicketAdmin.model';

import { Task } from '../../../models/task.model';
import { User } from '../../../models/user.model';

import { FaqService } from '../../../services/faq.service';
import { SupportTicketService } from '../../../services/support.ticket.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { StateService } from '../../../services/state.service';
import { TaskService } from 'src/app/services/task.service';
import { StorageService } from 'src/app/services/storage.service';
import { QuillEditorComponent } from 'ngx-quill';

interface MediaElement {
  fileType: string;
  id: number;
  name: string;
  resource: string;
  resourceId: number;
  url: string;
  isPdf?: boolean;
}

interface EmailTemplate {
  countryCode: string;
  question: string;
  cat: string;
}

@Component({
  selector: 'app-support-ticket',
  templateUrl: './supportTicket.component.html',
  styleUrls: ['./supportTicket.component.scss'],
})
export class SupportTicketComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  prevUserSupportTickets: SupportTicket[] = [];
  supportTicket: SupportTicket = new SupportTicket();
  supportUser: User = new User();
  supportTicketOpenOrClose: boolean;
  task: Task = new Task();
  usersAllTask: Task[] = [];
  supportTicketAdmin: SupportTicketAdmin = new SupportTicketAdmin();
  showAllMedia: boolean = false;

  emailTemplates: any[] = [];
  emailTemplateCategories: any = [
    { name: 'account', enName: 'Account', dkName: 'Konto' },
    { name: 'payment', enName: 'Payment', dkName: 'Betaling' },
    { name: 'cancellation', enName: 'Cancellation', dkName: 'Annullering' },
    { name: 'question', enName: 'Question', dkName: 'Spørgsmål' },
    { name: 'task', enName: 'Task', dkName: 'Opgave' },
    { name: 'conflict', enName: 'Conflict', dkName: 'Konflikt' },
    { name: 'guidelines', enName: 'Guidelines', dkName: 'Retningslinjer' },
    { name: 'document', enName: 'Document', dkName: 'Dokument' },
    { name: 'payout', enName: 'Payout', dkName: 'Udbetaling' },
    { name: 'tech', enName: 'Tech', dkName: 'Tech' },
  ];
  selectedEmailTemplateCategory: any;

  searchEmpty: boolean = true;

  faqTitles: any[] = [];
  mainFaqTitles: any[] = [];
  supportTicketAnswer: any;

  // Filtered
  prioritizedEmailTemplates: any[] = [];
  filteredUsersAllTask: Task[] = [];
  filteredEmailTemplates: any = {
    dk: [],
    eng: [],
  };
  filteredFaqTitles: any[] = [];

  mainCatSelected;
  stepOverTicketIndex;
  selectedFaqTitle;
  isLanguageDK: boolean = true;
  isTicketOpen: boolean = true;

  //Links
  MAX_LINKS_IN_EMAIL: number = 3;
  addedLinks: any[] = new Array();
  linkUrl;
  linkBtnText;
  editorContent = '';

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }],
      ['link', 'image', 'video'],
    ],
  };

  templateCategories = [
    { name: 'account', enName: 'Account', dkName: 'Konto' },
    { name: 'payment', enName: 'Payment', dkName: 'Betaling' },
    { name: 'cancellation', enName: 'Cancellation', dkName: 'Annullering' },
    { name: 'question', enName: 'Question', dkName: 'Spørgsmål' },
    { name: 'task', enName: 'Task', dkName: 'Opgave' },
    { name: 'conflict', enName: 'Conflict', dkName: 'Konflikt' },
    { name: 'guidelines', enName: 'Guidelines', dkName: 'Retningslinjer' },
    { name: 'document', enName: 'Document', dkName: 'Dokument' },
    { name: 'payout', enName: 'Payout', dkName: 'Udbetaling' },
    { name: 'tech', enName: 'Tech', dkName: 'Tech' },
  ];

  @ViewChild(QuillEditorComponent, { static: false })
  editor: QuillEditorComponent;

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private supportTicketService: SupportTicketService,
    private userService: UserService,
    private faqService: FaqService,
    private toastr: ToastrService,
    private stateService: StateService,
    private taskService: TaskService,
    private storage: StorageService,
  ) {
    this.stateService.toggleWaitingPage(true);
    let nav: Navigation = this.router.getCurrentNavigation();

    if (nav.extras && nav.extras.state && nav.extras.state.supportTicket) {
      this.supportTicket = nav.extras.state.supportTicket as SupportTicket;
      this.fetchDetails(this.supportTicket);
    } else {
      // in case Obj is not passed through router or UI is clicked Refresh UI for new ID Param
      this.activatedRoute.params.subscribe((param) => {
        const idParam = param.parameter ? param.parameter : param.id;
        if (idParam) {
          this.supportTicketService
            .getSupportTicket(idParam)
            .subscribe((res) => {
              if (res) {
                this.supportTicket = res;
                this.fetchDetails(res);
                this.setMetaTitle();
              }
            });
        }
      });
    }
  }

  ngOnInit() {
    this.fetchMainFaqs();
  }

  ngAfterViewChecked(): void {
    const textarea = document.getElementsByClassName(
      'inquiry',
    )[0] as HTMLTextAreaElement;
    textarea.style.overflow = 'hidden'; // Hide any overflowed content

    // Set the height to fit the scrollHeight, plus a little padding
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  setMetaTitle() {
    if (this.supportUser.firstName) {
      this.title.setTitle(`${this.supportUser.firstName}'s Support Ticket`);
    } else {
      this.title.setTitle(`${this.supportTicket.email}'s Support Ticket`);
    }
  }

  getSupportTicketAnswer(supportTicketId: number): any {
    if (this.supportTicket && this.supportTicket.status === 'Close') {
      this.supportTicketService
        .getSupportTicketAnswer(supportTicketId)
        .subscribe((res) => {
          if (res && res.message) {
            this.supportTicketAnswer = {
              message: res.message,
              createdAt: res.createdAt,
            };
          }
        });
    } else {
      return;
    }
  }
  // set global state her for (DK|ENG) toogle input button
  changeLanguage = () => {
    this.isLanguageDK = !this.isLanguageDK;
    this.navToCategories();
  };

  fetchDetails(supportTicket: SupportTicket) {
    this.getUserDetail(supportTicket.userId);
    if (supportTicket.taskId) {
      this.taskService.getTaskById(supportTicket.taskId).subscribe((res) => {
        this.task = res;
      });
    }
    this.fetchEmailTemplates();
    this.getPreviousInqueries(supportTicket.userId);
    this.smartMatchForEmailTemplate();
    this.checkMedia();
    this.getSupportTicketAnswer(this.supportTicket.id);
    this.supportTicketOpenOrClose =
      supportTicket.status === 'Open' ? true : false;
  }

  smartMatchForEmailTemplate() {
    const data = {
      cat: this.supportTicket.subTopic,
      subCat: this.supportTicket.subTopic,
    };

    this.supportTicketService
      .getSupportTicketTemplateByCatOrSubCat(data)
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.prioritizedEmailTemplates = res;
        }
      });
  }

  getUserDetail(userId: number) {
    if (userId) {
      this.userService.getUserProfile(userId).subscribe((res) => {
        if (res) {
          this.supportUser = res;
          this.supportTicketAdmin.email = this.supportUser.email;
          this.stateService.toggleWaitingPage(false);
          this.setMetaTitle();
        }
      });
    } else {
      this.supportTicketAdmin.email = this.supportTicket.email;
      this.stateService.toggleWaitingPage(false);
    }
    this.supportTicketAdmin.subject = `Re: ${this.supportTicket.mainConcern} | Handyhand Support`;
    this.supportTicketAdmin.replyInitialContent = `Hej ${
      this.supportUser?.firstName
        ? this.supportUser.firstName
        : this.supportTicket.email
    }
        \nTak for din henvendelse angående:\n${
          this.supportTicket.mainConcern
        }.`;
    this.supportTicketAdmin.supportTicketId = this.supportTicket.id;
    this.supportTicketAdmin.originalMessage = `Din besked: ${this.supportTicket.detailConcern}.`;
    this.supportTicketAdmin.links = this.addedLinks;
  }

  checkMedia() {
    if (this.supportTicket.media) {
      this.supportTicket.media.forEach((mediaElement: MediaElement) => {
        const url = mediaElement?.url;
        const pattern = /\.pdf$/i; // Regular expression pattern to check if the URL ends with .pdf
        if (pattern.test(url)) {
          mediaElement.isPdf = true;
        } else {
          mediaElement.isPdf = false;
        }
      });
    }
  }
  // Get ongoing or previosuly Support Inqueries
  getPreviousInqueries(userId: number) {
    if (userId) {
      this.supportTicketService
        .getAllSupportTicketsFromUserId(userId)
        .subscribe((res) => {
          if (res) {
            this.prevUserSupportTickets = res;
            this.stepOverTicketIndex = this.prevUserSupportTickets
              .map((obj) => obj.id)
              .indexOf(this.supportTicket.id);
          }
        });
    }
  }

  clearNotificationsOnSupportTicket() {
    this.stateService.readActiveSupportTickets(this.supportTicket);
  }
  addNotificationBackOnSupportTicket() {
    this.stateService.updateTotalActiveSupportTickets(this.supportTicket);
  }

  changeTicketStatus(status: string) {
    if (status == 'open') {
      this.supportTicketOpenOrClose = true;
      this.supportTicket.status = 'Open';
      this.addNotificationBackOnSupportTicket();
    } else {
      this.supportTicketOpenOrClose = false;
      this.supportTicket.status = 'Close';
      this.clearNotificationsOnSupportTicket();
    }

    this.supportTicketService
      .updateSupportTicketStatus(
        { status: this.supportTicket.status },
        this.supportTicket.id,
      )
      .subscribe((res) => {
        if (res) {
          this.toastr.success(
            `Status changed to: ${this.supportTicket.status}`,
          );
          this.getPreviousInqueries(this.supportUser.id);
        }
      });
  }

  fetchEmailTemplates() {
    this.supportTicketService.getSupportEmailTemplates().subscribe((res) => {
      if (res) {
        this.emailTemplates = res;
      }
    });
  }

  fetchFaqTitles(faqMainCat: string) {
    // ensure the mapping for MAIN CATS OR add new attribute all places required
    // is equal on all dashboard - FAQ
    this.mainFaqTitles = [];
    this.faqService
      .getFAQTitles(this.isLanguageDK ? 'DK' : 'ENG', faqMainCat)
      .subscribe((res) => {
        if (res) {
          this.faqTitles = res;
          this.filteredFaqTitles = res;
        }
      });
  }

  selectFaqTitle(faqTitle) {
    this.addLink(faqTitle.title, faqTitle.slug, faqTitle.category);
  }

  selectTaskTitle(title: string) {
    const taskTxt = `\n\n I forbindelse med opgave: ${title}`;
    this.supportTicketAdmin.message =
      this.supportTicketAdmin.message.concat(taskTxt);
  }

  selectEmailTempCategory(emailTempCat: any) {
    this.selectedEmailTemplateCategory = emailTempCat;
    this.searchEmpty = false;
    if (this.isLanguageDK) {
      this.filteredEmailTemplates.dk = this.emailTemplates.filter(
        (template) =>
          template.cat === emailTempCat.name &&
          template.countryCode.toLowerCase() === 'dk',
      );
    } else {
      this.filteredEmailTemplates.eng = this.emailTemplates.filter(
        (template) =>
          template.cat === emailTempCat.name &&
          template.countryCode.toLowerCase() === 'eng',
      );
    }
  }

  selectAndInsertEmailTemp(emailTemplate) {
    this.supportTicketAdmin.htmlMessage = this.supportTicketAdmin.message.concat(
      ...emailTemplate?.response,
    );
    this.supportTicketAdmin.supportTicketTemplateId = emailTemplate._id;
  }

  fetchMainFaqs() {
    this.faqService.getMainFaqs().subscribe((res) => {
      if (res) {
        this.mainFaqTitles = res.DK;
      }
    });
  }

  clearSmartMatch(type: string) {
    switch (type) {
      case 'email':
        this.prioritizedEmailTemplates = [];
        break;
      case 'faq-titles':
        this.fetchMainFaqs();
        this.filteredFaqTitles = [];
        break;
      case 'task-titles':
        this.filteredUsersAllTask = [];
        break;
      default:
        return;
    }
  }

  filterEmailTemplates(emailTempQuery: string) {
    if (emailTempQuery === '' || emailTempQuery === null) {
      this.searchEmpty = true;
      switch (this.isLanguageDK) {
        case true:
          if (this.prioritizedEmailTemplates.length >= 0) {
            this.selectedEmailTemplateCategory = null;
            this.filteredEmailTemplates.dk = this.emailTemplates.filter(
              (x) => x.countryCode.toLowerCase() === 'dk',
            );
          }
          break;
        case false:
          if (this.prioritizedEmailTemplates.length >= 0) {
            this.selectedEmailTemplateCategory = null;
            this.filteredEmailTemplates.eng = this.emailTemplates.filter(
              (x) => x.countryCode.toLowerCase() === 'eng',
            );
          }
          break;
      }
      return;
    } else {
      this.searchEmpty = false;
      this.filteredEmailTemplates = {
        dk: this.emailTemplates.filter(
          (x: EmailTemplate) =>
            x.countryCode.toLowerCase() === 'dk' &&
            (x.question.toLowerCase().includes(emailTempQuery.toLowerCase()) ||
              x.cat.toLowerCase().includes(emailTempQuery.toLowerCase())),
        ),
        eng: this.emailTemplates.filter(
          (x: EmailTemplate) =>
            x.countryCode.toLowerCase() === 'eng' &&
            (x.question.toLowerCase().includes(emailTempQuery.toLowerCase()) ||
              x.cat.toLowerCase().includes(emailTempQuery.toLowerCase())),
        ),
      };
      return;
    }
  }

  // have a flag for main or titles to filter on
  filterFaqTitles(faqTitleQuery: string) {
    this.filteredFaqTitles = this.faqTitles.filter((x) =>
      x.title.toLowerCase().includes(faqTitleQuery.toLowerCase()),
    );
  }

  filterTaskTitles(taskTitleQuery: string) {
    this.usersAllTask = this.usersAllTask.filter(
      (t) =>
        t.title.toLowerCase().includes(taskTitleQuery.toLowerCase()) ||
        t.description.includes(taskTitleQuery.toLowerCase()),
    );
  }

  sendSupportTicketAdmin() {
    // ENSURE save _ID from mongoDB
    this.stateService.toggleWaitingPage(true);
    this.supportTicketAdmin.links = this.addedLinks;

    const editorElement = this.editor.quillEditor.root;
    const plainText = editorElement.textContent.trim();

    this.supportTicketAdmin.message = plainText;

    this.supportTicketService
      .createSupportTicketAdmin(this.supportTicketAdmin)
      .subscribe((res) => {
        if (res) {
          this.toastr.success('Email sent and Support Ticket admin created');
          this.isTicketOpen = false;
          this.navigateTo('returnToList', '');
          this.supportTicket.status = 'Close';
          this.clearNotificationsOnSupportTicket();
          this.stateService.toggleWaitingPage(false);
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

  getStatusColor(statusColor: string) {
    switch (statusColor) {
      case 'Open':
        return 'rgb(60,201,7)';
      case 'Close':
        return 'rgb(255,1,40)';
    }
  }

  navigateTo(type: string, id: any) {
    switch (type) {
      case 'stripeCustomerId':
        window.open(`https://dashboard.stripe.com/customers/${id}`, '_blank');
        break;
      case 'stripeAccountId':
        window.open(
          `https://dashboard.stripe.com/connect/accounts/${id}/activity`,
          '_blank',
        );
        break;
      case 'payouts':
        window.open(`https://dashboard.stripe.com/payouts/${id}`, '_blank');
        break;
      case 'charge':
        window.open(`https://dashboard.stripe.com/payments/${id}`, '_blank');
        break;
      case 'adminUser':
        this.router.navigate([]).then((result) => {
          window.open(`https://admin.handyhand.dk/users/${id}`, '_blank');
        });
        break;
      case 'task':
        this.router.navigate([]).then((result) => {
          window.open(`https://admin.handyhand.dk/tasks/${id}`, '_blank');
        });
        break;
      case 'previously-inqueries':
        this.router.navigate([]).then((result) => {
          window.open(
            `https://admin.handyhand.dk/support-ticket/${id}`,
            '_blank',
          );
        });
        break;
      case 'acceptedOfferUser':
        this.router.navigate([]).then((result) => {
          window.open(`https://admin.handyhand.dk/users/${id}`, '_blank');
        });
        break;
      case 'returnToList':
        this.router.navigate(['/support-tickets']);
        break;
      default:
        return console.log('none found');
    }
  }

  goToSupporTicket(supportTicketId: number) {
    this.router.navigate([`/support-ticket/${supportTicketId}`]);
  }

  disableSubmitBtn = () =>
    !this.supportTicketAdmin.subject || !this.supportTicketAdmin.htmlMessage;

  addLink(buttonTxt?: string, url?: string, mainCat?: string) {
    // Regular expression for URL validation
    const urlRegex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    if (buttonTxt && url && mainCat) {
      this.linkBtnText = buttonTxt;
      this.linkUrl = 'https://handyhand.dk/hjaelp/' + mainCat + '/' + url;
    }
    if (this.linkUrl && !urlRegex.test(this.linkUrl)) {
      this.toastr.error('Invalid URL');
      return;
    }
    if (!this.linkBtnText || !this.linkUrl) {
      this.toastr.error('Missing link text or link url');
      return;
    }
    if (this.addedLinks.length == this.MAX_LINKS_IN_EMAIL) {
      this.toastr.error(
        `You can only have up to ${this.MAX_LINKS_IN_EMAIL} links`,
      );
      return;
    }

    const link = { btnText: this.linkBtnText, url: this.linkUrl };

    if (link) {
      this.addedLinks.push(link);
      this.toastr.success('Link added');
      this.linkBtnText = '';
      this.linkUrl = '';
    }
  }
  deleteLink(index) {
    this.addedLinks.splice(index, 1);
  }

  navToCategories() {
    this.searchEmpty = true;
    this.selectedEmailTemplateCategory = null;
  }

  navToSmartMatch() {}

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }
}
