import { Injectable } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { BucketNotification } from "../models/BucketNotification.model";
import { EmailTemplate } from "../models/EmailTemplate.model";

@Injectable()
export class BucketNotificationFormService {
  private languages = ["en", "dk"];

  constructor(private formBuilder: UntypedFormBuilder) {}

  getNewBucketNotificationForm(): UntypedFormGroup {
    const fg = this.formBuilder.group({
      description: ["", Validators.required],
      periodicity: [86400000, [Validators.required, Validators.min(0)]],
      hour: [43200000, [Validators.required, Validators.min(0)]],
      emailWanted: [false, Validators.required],
      pushWanted: [false, Validators.required],
      smsWanted: [false, Validators.required],
      webWanted: [false, Validators.required],
      language: ["userSettings", Validators.required],
      repeatEnabled: [false, Validators.required],
      repeat: [31536000000, [Validators.required, Validators.min(1)]],
      emailContext: this.getEmailForm(),
      webContext: this.formBuilder.group({
        en: this.getFormGroupForWeb(),
        dk: this.getFormGroupForWeb(),
        otherParams: ["", this.isValidJsonValidator()],
      }),
      pushContext: this.formBuilder.group({
        en: this.getFormGroupForPush(),
        dk: this.getFormGroupForPush(),
        pushData: ["", this.isValidJsonValidator()],
      }),
      smsContext: this.formBuilder.group({
        en: this.getFormGroupForSms(),
        dk: this.getFormGroupForSms(),
      }),
    });
    fg.get("repeat").disable();

    fg.get("repeatEnabled").valueChanges.subscribe((value) => {
      if (value) {
        fg.get("repeat").enable();
      } else {
        fg.get("repeat").disable();
      }
    });
    return fg;
  }

  private getEmailForm(): UntypedFormGroup {
    return this.formBuilder.group({
      template: ["", Validators.required],
      en: this.formBuilder.group({
        plainText: ["", Validators.required],
      }),
      dk: this.formBuilder.group({
        plainText: ["", Validators.required],
      }),
    });
  }

  private getFormGroupForWeb(): UntypedFormGroup {
    return this.formBuilder.group({
      title: ["", Validators.required],
      message: ["", Validators.required],
    });
  }

  private getFormGroupForPush(): UntypedFormGroup {
    return this.formBuilder.group({
      title: ["", Validators.required],
      body: ["", Validators.required],
    });
  }

  private getFormGroupForSms(): UntypedFormGroup {
    const f = this.formBuilder.group({
      message: ["", Validators.required],
    });
    return f;
  }

  private isValidJsonValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (!control.value) return null;

      try {
        if (control.value[0] !== "{") return { invalidJson: true };
        if (control.value[control.value.length - 1] !== "}")
          return { invalidJson: true };

        JSON.parse(control.value);
        return null;
      } catch (e) {
        return { invalidJson: true };
      }
    };
  }

  getBucketNotificationForm(bucketNotification: BucketNotification): UntypedFormGroup {
    const fg = this.getNewBucketNotificationForm();
    fg.patchValue(bucketNotification);
    const context = bucketNotification.context;

    if (context.contextWeb) {
      fg.get("webContext").patchValue(context.contextWeb);

      // extract otherParams
      let otherParams: any = {};
      if (context.contextWeb.dk) {
        otherParams = { ...context.contextWeb.dk };
      } else if (context.contextWeb.en) {
        otherParams = { ...context.contextWeb.en };
      }

      delete otherParams.title;
      delete otherParams.message;
      if (Object.keys(otherParams).length !== 0)
        fg.get("webContext.otherParams").patchValue(
          JSON.stringify(otherParams)
        );
    }
    if (context.contextPush) {
      fg.get("pushContext").patchValue(context.contextPush);

      // extract pushData
      let pushData;
      if (context.contextPush.dk) {
        pushData = { ...context.contextPush.dk.pushData };
      } else if (context.contextPush.en) {
        pushData = { ...context.contextPush.en.pushData };
      }

      if (pushData)
        fg.get("pushContext.pushData").patchValue(JSON.stringify(pushData));
    }
    if (context.contextSms) {
      fg.get("smsContext").patchValue(context.contextSms);
    }

    if (bucketNotification.repeat) {
      fg.get("repeatEnabled").patchValue(true);
      fg.get("repeat").enable();
    } else {
      fg.get("repeat").patchValue(null);
      fg.get("repeat").disable();
    }

    return fg;
  }

  fillEmailTemplate(
    fg: UntypedFormGroup,
    bucketNotification: BucketNotification,
    templates: EmailTemplate[]
  ) {
    const context = bucketNotification.context;
    if (context.contextEmail) {
      const templateString = context.contextEmail.template;
      const template = templates.find((t) => t.templatePath === templateString);
      fg.get("emailContext.template").patchValue(template);
      return template;
    }
  }

  fillEmailForm(fg: UntypedFormGroup, bucketNotification: BucketNotification) {
    const context = bucketNotification.context;
    if (context.contextEmail) {
      fg.get("emailContext.en").patchValue(context.contextEmail.en);
      fg.get("emailContext.dk").patchValue(context.contextEmail.dk);
    }
  }
}
