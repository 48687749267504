<ng-container *ngIf="segments$ | async as segments">
  <div class="header">
    <h1>Send notification to a segment</h1>
    <button class="pinploy_button" routerLink="/notification-segments">
      Back
    </button>
  </div>
  <div class="top_container">
    <div>
      <mat-form-field appearance="fill" class="title-input">
        <mat-label>Title</mat-label>
        <mat-error *ngIf="form.get('title').invalid">
          Title is required
        </mat-error>
        <input matInput [formControl]="form.get('title')" required />
      </mat-form-field>
      <button class="pinploy_button" (click)="onClickSend()">
        Send notification
      </button>
    </div>
    <div>
      <div class="dropdown_container">
        <mat-form-field appearance="fill">
          <mat-label>Segment</mat-label>
          <mat-select [formControl]="form.get('segment')" required>
            <mat-option *ngFor="let segment of segments" [value]="segment">{{
              segment
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('segment').invalid">
            Segment is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Language</mat-label>
          <mat-select [formControl]="form.get('language')" required>
            <mat-option selected value="userSettings">User settings</mat-option>
            <mat-option value="dk">Danish</mat-option>
            <mat-option value="en">English</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('language').invalid">
            Language is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Schedule date</mat-label>
          <input
            matInput
            [formControl]="form.get('scheduledAt')"
            type="datetime-local"
          />
          <mat-hint>Empty for immediate send</mat-hint>
          <mat-error *ngIf="form.get('scheduledAt').hasError('notADate')">
            Schedule date must be a date
          </mat-error>
          <mat-error *ngIf="form.get('scheduledAt').hasError('dateInPast')">
            Schedule date must be in the future
          </mat-error>
        </mat-form-field>
      </div>

      <div class="toggle_container">
        <mat-slide-toggle
          [(ngModel)]="isEmailWanted"
          (change)="onChangeWanted('email')"
          >Email</mat-slide-toggle
        >
        <mat-slide-toggle
          [(ngModel)]="isWebWanted"
          (change)="onChangeWanted('web')"
          >Web</mat-slide-toggle
        >
        <mat-slide-toggle
          [(ngModel)]="isPushWanted"
          (change)="onChangeWanted('push')"
          >Push</mat-slide-toggle
        >
        <mat-slide-toggle
          [(ngModel)]="isSmsWanted"
          (change)="onChangeWanted('sms')"
          >SMS</mat-slide-toggle
        >
      </div>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
    <mat-tab
      label="Email"
      [labelClass]="
        tabsIsInvalid(
          form.get('emailContext.template'),
          form.get('emailContext.dk'),
          form.get('emailContext.en')
        )
          ? 'tabs_error'
          : ''
      "
    >
      <ng-container *ngIf="templates$ | async as templates; else loadingEmail">
        <app-notification-email-section
          [templates]="templates"
          [disabled]="!isEmailWanted"
          [contextForm]="form.get('emailContext')"
          [choosedLanguage]="form.get('language').value"
        ></app-notification-email-section>
      </ng-container>
      <ng-template #loadingEmail>
        <mat-spinner></mat-spinner>
      </ng-template>
    </mat-tab>
    <mat-tab
      label="Web"
      [labelClass]="tabsIsInvalid(form.get('webContext')) ? 'tabs_error' : ''"
    >
      <app-notification-web-section
        [contextForm]="form.get('webContext')"
        [choosedLanguage]="form.get('language').value"
        [disabled]="!isWebWanted"
      ></app-notification-web-section>
    </mat-tab>
    <mat-tab
      label="Push"
      [labelClass]="tabsIsInvalid(form.get('pushContext')) ? 'tabs_error' : ''"
    >
      <app-notification-push-section
        [contextForm]="form.get('pushContext')"
        [choosedLanguage]="form.get('language').value"
        [disabled]="!isPushWanted"
      ></app-notification-push-section>
    </mat-tab>
    <mat-tab
      label="SMS"
      [labelClass]="tabsIsInvalid(form.get('smsContext')) ? 'tabs_error' : ''"
    >
      <app-notification-sms-section
        [contextForm]="form.get('smsContext')"
        [choosedLanguage]="form.get('language').value"
        [disabled]="!isSmsWanted"
      ></app-notification-sms-section>
    </mat-tab>
  </mat-tab-group>
</ng-container>
