import { Component, Input, OnInit } from '@angular/core';
import { InformationDialogComponent } from '../../dialogs/informationDialog/informationDialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss', '../cards.scss'],
})
export class CompanyCardComponent implements OnInit {
  @Input() title: string;
  @Input() company: any;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
  }

  openJSONview() {
    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '80%',
      data: { title: 'Company Information', object: this.company },
    });
  }
}
