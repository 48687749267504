import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PenaltyModel } from 'src/app/models/penalty.model';
import { UserPenaltiesDialogComponent } from '../../dialogs/userPenaltiesDialog/userPenaltiesDialog.component';
@Component({
  selector: 'app-penalty-card',
  templateUrl: './penalty-card.component.html',
  styleUrls: ['./penalty-card.component.scss', '../cards.scss'],
})
export class PenaltyCardComponent implements OnInit {
  @Input() penalty: PenaltyModel;
  @Output() closeDialogEvent = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit() {}
}
