export class CancelTaskAutomation {
  id: number;
  cancelTaskId: number;
  feeOwner: string;
  status: string;
  reasonComment: string;
  adminComment: string;
  requestInitiator: string;
  receiverReason: string;
  receiverFeeOwner: string;
  receiverReasonCommen: string;
  createdAt: string;
  updatedAt: string;
  customFee: number;
  hhReport: string;

  constructor(data: any = null) {
    Object.assign(this, data);
  }
}
