<paginated-list
  [data]="notifications"
  [filterKeys]="['id', 'title', 'segmentName', 'status']"
  (displayDataChanged)="updateDisplayNotification($event)"
  (dataRequested)="fetchMoreNotifications()"
  [moreDataAvailable]="hasMore"
>
  <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>Title</th>
        <th>Segment</th>
        <th>Scheduled At</th>
        <th>Status</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let notif of displayNotifications" (click)="navigate(notif)">
        <td>{{ notif.id }}</td>
        <td>{{ notif.title }}</td>
        <td>{{ notif.segmentName }}</td>
        <td>{{ notif.scheduledAt | date : "dd/MM/yy HH:mm:ss" }}</td>
        <td [class]="notif.status">{{ notif.status }}</td>
      </tr>
    </tbody>
  </table>
</paginated-list>
