<mat-card *ngIf="task">
  <div class="card_header">
    <h5>{{ title }}</h5>
    <button
      class="pinploy_button small_button"
      (click)="goToTaskDetails(task.id, $event)"
    >
      Open task details
    </button>
  </div>
  <div class="card_body">
    <div class="card_row">
      <p class="strong">Id</p>
      <p>{{ task.id }}</p>
    </div>
    <div class="card_row">
      <p class="strong">Title</p>
      <p>{{ task.title }}</p>
    </div>
    <div class="card_row">
      <p class="strong">Budget</p>
      <p>{{ task.budget }}</p>
    </div>
    <div
      class="card_row"
      *ngIf="task.acceptedOffer"
    >
      <p class="strong">Accepted offer</p>
      <p>{{ task.acceptedOffer.priceBid }}</p>
    </div>
    <div
      class="card_row"
      *ngIf="task.completedOffer"
    >
      <p class="strong">Completed offer</p>
      <p>{{ task.completedOffer.priceBid }}</p>
    </div>
    <div
      class="card_row"
      *ngIf="task.status != undefined"
    >
      <p class="strong">Status</p>
      <p>{{ task.status }}</p>
    </div>
    <div class="card_row">
      <p class="strong">Owner</p>
      <p>{{ task.user?.firstName }} {{ task.user?.lastName }}</p>
    </div>
    <div
      *ngIf="task.city"
      class="card_row"
    >
      <p class="strong">City</p>
      <p>{{ task?.city }}</p>
    </div>
  </div>
</mat-card>
