import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Task } from '../models/task.model';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { StateService } from './state.service';

import { CancelTask } from '../models/cancelTask.model';
import { CancelTaskAutomation } from '../models/cancelTaskAutomation';
import { PenaltyModel } from '../models/penalty.model';
import { logClass } from './logger.decorator';

type PaginatedResult<T> = {
  data: T[];
  hasMore: boolean;
};

type GetTasksResult = {
  tasks: Task[];
  hasMore: boolean;
};

@logClass()
@Injectable()
export class TaskService {
  baseHref = `${environment.apiUrl}/api/admin/tasks`;
  baseRegHref = `${environment.apiUrl}/api/tasks`;

  dataEnrichmentHref = `${environment.apiUrl}/api/data-enrichment`;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private stateService: StateService,
  ) {}

  getTaskByOfferId(offerId: number): Observable<Task> {
    const url = `${this.baseHref}/get-task-by-offer-id/${offerId}`;
    return this.http.get<Task>(url);
  }

  getTaskByOfferCommentId(offerCommentId: number): Observable<Task> {
    const url = `${this.baseHref}/get-task-by-offer-comment-id/${offerCommentId}`;
    return this.http.get<Task>(url);
  }

  getTaskByTaskCommentId(taskCommentId: number): Observable<Task> {
    const url = `${this.baseHref}/get-task-by-task-comment-id/${taskCommentId}`;
    return this.http.get<Task>(url);
  }

  getUsersOwnTasks(): Observable<Task[]> {
    const url = `${this.baseRegHref}/own-and-associated`;
    return this.http.get<Task[]>(url);
  }

  getTasks(page: number, includeTags?: boolean): Observable<GetTasksResult> {
    const url = `${this.baseHref}`;
    return this.http.get<GetTasksResult>(url, {
      params: includeTags ? { page, ['include-tags']: true } : { page },
    });
  }

  getTaskWithComments(id: number, userId: number): Observable<any> {
    const url = `${this.baseRegHref}/details/${id}/${userId}`;
    return this.http.get<any>(url);
  }

  getTaskById(id: number): Observable<Task> {
    const url = `${this.baseHref}/task-detail/${id}`;
    return this.http.get<Task>(url);
  }

  editTask(id: number, data: any): Observable<Task> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/edit/${id}`;
    return this.http.post<Task>(url, data);
  }

  editPenalty(id: number, data: any): Observable<PenaltyModel> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/edit/penalty/${id}`;
    return this.http.post<PenaltyModel>(url, data);
  }

  editCancelTaskAuto(id: number, data: any): Observable<CancelTaskAutomation> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/edit/cancel-task-auto/${id}`;
    return this.http.post<CancelTaskAutomation>(url, data);
  }

  editCancelTask(id: number, data: any): Observable<CancelTask> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/edit/cancel-task/${id}`;
    return this.http.post<CancelTask>(url, data);
  }

  cancelAcceptedTask(taskId: number, feeOwner?: 'TO' | 'PP'): Observable<any> {
    const url = `${this.baseHref}/cancel-accepted-task/${taskId}`;
    return this.http.get<any>(url, { params: { feeOwner } });
  }

  completeTask(taskId: number): Observable<any> {
    const url = `${this.baseHref}/close-task/${taskId}`;
    return this.http.get<any>(url);
  }

  getCancelTasks(page: number) {
    const url = `${this.baseHref}/get-canceled-task`;
    return this.http.get<PaginatedResult<CancelTask>>(url, {
      params: { page },
    });
  }

  getCancelTasksAutomation(
    page: number,
  ): Observable<PaginatedResult<CancelTaskAutomation>> {
    const url = `${this.baseHref}/all-automated-cancel-tasks`;
    return this.http.get<PaginatedResult<CancelTaskAutomation>>(url, {
      params: { page },
    });
  }

  getCancelTask(cancelTaskId: number): Observable<CancelTask> {
    cancelTaskId = +cancelTaskId;
    const url = `${this.baseHref}/get-cancel-task/${cancelTaskId}`;
    return this.http.get<CancelTask>(url);
  }

  getCancelTaskByTaskId(taskId: number): Observable<{
    cancelTask: CancelTask;
    cancelTaskAuto: CancelTaskAutomation;
  }> {
    if (taskId && Number.isInteger(taskId)) {
      const url = `${this.baseHref}/fetch-cancel-task/${taskId}`;
      return this.http.get<any>(url);
    }
  }

  getCancelTaskAuto(cancelTaskId: number): Observable<CancelTaskAutomation> {
    const url = `${this.baseHref}/get-cancel-task/automation/${cancelTaskId}`;
    return this.http.get<CancelTaskAutomation>(url);
  }

  getAllPenalties(): Observable<PenaltyModel[]> {
    const url = `${this.baseHref}/get-all-penalties`;
    return this.http.get<PenaltyModel[]>(url);
  }

  getPenalty(id: number): Observable<PenaltyModel> {
    const url = `${this.baseHref}/get-penalty/${id}`;
    return this.http.get<PenaltyModel>(url);
  }

  getPenaltiesForUser(userId: number): Observable<PenaltyModel[]> {
    const url = `${this.baseHref}/get-user-penalties/${userId}`;
    return this.http.get<PenaltyModel[]>(url);
  }

  editCancelTaskAutomated(
    id: number,
    data: any,
  ): Observable<CancelTaskAutomation> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/edit-cancel-task/automation/${id}`;
    return this.http.put<CancelTaskAutomation>(url, data);
  }

  instantNotificationsNewTags(taskId: number): Observable<any> {
    if (taskId) {
      const options = {
        headers: {
          'Content-Type': 'text-plain',
        },
      };
      const url = `${this.baseHref}/new-task-instant-notifications/${taskId}`;
      return this.http.get<any>(url, options);
    }
  }

  handleError(err: any, msg: string) {
    if (err && err.error !== 'Token does not exist') {
      this.toastr.error(msg);
      this.stateService.toggleWaitingPage(false);
    }
  }

  refundAndReversalTransferPriceInc(data) {
    if (data) {
      const url = `${this.baseHref}/refund-reversal-offer-price-increase`;
      return this.http.post(url, data);
    }
  }

  resolveConflict(data) {
    if (data) {
      const url = `${this.baseHref}/resolve-conflict`;
      return this.http.post(url, data);
    }
  }

  requestCancellationAsUser(data): Observable<any> {
    if (data) {
      const url = `${this.baseRegHref}/cancel-task`;
      return this.http.post(url, data);
    }
  }

  resolveCompromise(taskId: number, newBid: number) {
    if (taskId && newBid) {
      const data = {
        taskId: taskId,
        newBid: newBid,
      };
      const url = `${this.baseHref}/price-decrease`;
      return this.http.post(url, data);
    }
  }

  updatePayoutCalculations(taskId: number) {
    if (taskId) {
      const url = `${this.baseHref}/recalculate-payout/`;
      const body = {
        taskId: taskId,
      };
      return this.http.post(url, body);
    }
  }

  getTaskViews(taskId: number) {
    if (taskId) {
      const url = `${this.dataEnrichmentHref}/task-view-count/${taskId}`;
      return this.http.get(url);
    }
  }
}
