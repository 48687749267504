import { Component, OnDestroy, OnInit } from "@angular/core";
import { Offer } from "src/app/models/offer.model";
import { OfferService } from "src/app/services/offer.service";
import { MiscService } from "src/app/services/misc.service";
import { Router } from "@angular/router";
import { TaskService } from "src/app/services/task.service";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";


@Component({
  selector: "app-comment-list",
  templateUrl: "./commentList.component.html",
  styleUrls: ["./commentList.component.scss"],
})
export class CommentListComponent implements OnInit, OnDestroy {
  public types: { [key: string]: string }[] = [
    { name: 'Offer', value: 'offer' },
    { name: 'Offer comment', value: 'offer-comm' },
    { name: 'Task', value: 'task' },
    { name: 'Task comment', value: 'task-comm' },
  ];
  public id: number;
  public type: string = 'offer-comm';


  constructor(
    private title: Title,
    private taskService: TaskService,
    private toastr: ToastrService,
    private offerService: OfferService,
    private router: Router,
    private miscService: MiscService,
  ) { }

  ngOnInit(): void {
    this.setMetaTitle();
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }

  getData() {
    switch (this.type) {
      case 'offer-comm':
        this.miscService.getTaskAndOffersAndComments('offer-comm', this.id.toString()).subscribe(
          (comments: any) => {
            this.router.navigate(["/tasks", comments.task.id, "offer-comm", this.id]);
          },
          (error) => {
            this.toastr.error('Offer comment not found');
          }
        );
        break;
      case 'offer':
        this.offerService.getOfferById(this.id).subscribe(
          (offer: Offer) => {
            this.router.navigate(["/tasks", offer.taskId, "offer", offer.id]);
          }, (error) => {
            this.toastr.error('Offer not found');
          });
        break;
      case 'task-comm':
        this.miscService.getTaskAndOffersAndComments('task-comm', this.id.toString()).subscribe(
          (comments: any) => {
          this.router.navigate(["/tasks", comments.task.id, "task-comm", this.id]);
        }, (error) => {
          this.toastr.error('Task comment not found');
        });
        break;
      case 'task':
        this.taskService.getTaskById(this.id).subscribe( (task) => {        
          this.router.navigate(["/tasks", this.id]);
        }, (error) => {
          this.toastr.error('Task not found');
        });
        break;

      default:
        break;
    }
  }

  setMetaTitle() {
    this.title.setTitle(`Entity lookup`);
  }
}
