import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { Coupon } from "../models/coupon.model";
import { CouponUse } from "../models/couponUse.model";

import { environment } from "../../environments/environment";
import { logClass } from "./logger.decorator";

type ListCouponsResponse = {
  coupons: Coupon[];
  hasMore: boolean;
};

type ListCouponUsesResponse = {
  couponUses: CouponUse[];
  hasMore: boolean;
};

@logClass()
@Injectable()
export class CouponService {
  baseHref = `${environment.apiUrl}/api/payments`;

  constructor(private http: HttpClient) {}

  getAllCoupons(page: number): Observable<ListCouponsResponse> {
    const url = `${this.baseHref}/coupons`;
    return this.http.get<ListCouponsResponse>(url, { params: { page } });
  }

  getCouponById(id: number): Observable<Coupon> {
    const url = `${this.baseHref}/coupons/${id}`;
    return this.http.get<Coupon>(url);
  }

  getCouponUses(id: number, page: number): Observable<ListCouponUsesResponse> {
    const url = `${this.baseHref}/coupons/${id}/uses`;
    return this.http.get<ListCouponUsesResponse>(url, { params: { page } });
  }

  // ///////////////////////////////// POST /////////////////////////////////

  //     /**
  //      * POST
  //      * @param {{data.cuponCampaign}, {data.coupon}}
  //      * @returns {data.campaign, data.coupons[]}
  //      */
  //     createCouponsWithCampaign(data: any): Observable<any> {
  //         const url = `${this.baseHref}/createCoupon`;
  //         return this.http.post<any>(url, data);
  //     }

  //     /**
  //      * POST
  //      * @param {Array<string>} couponCodesArray
  //      * @param {number} campaignId
  //      * @returns {Coupon[]} coupons[]
  //      */
  //     addCouponsToCampaign(couponCodesArray: Array<string>, campaignId: number): Observable<Coupon[]>{
  //         const url = `${this.baseHref}/campaign/addCouponCodes/${campaignId}`;
  //         return this.http.post<Coupon[]>(url, {couponCodesArray});
  //     }

  // ///////////////////////////////// GET /////////////////////////////////

  //     /**
  //      * GET
  //      * @returns CouponCampaign[]
  //      */
  //     getAllCampaigns(): Observable<CouponCampaign[]> {
  //         const url = `${this.baseHref}/list-coupons`;
  //         return this.http.get<CouponCampaign[]>(url);
  //     }

  //     /**
  //      * GET
  //      * @param campaignId
  //      * @returns Coupon[]
  //      */
  //     getCouponsByCampaignId(campaignId: number): Observable<Coupon[]> {
  //         const url = `${this.baseHref}/getCoupons/${campaignId}`;
  //         return this.http.get<Coupon[]>(url);
  //     }

  //     /**
  //      * GET
  //      * @param couponId
  //      * @returns {Coupon, Coupon.task, Coupon.toUser, Coupon.offer, Coupon.ppUser}
  //      */
  //     getCouponWithForeignEntities(couponId: number): Observable<any> {
  //         const url = `${this.baseHref}/getCouponWithExtra/${couponId}`;
  //         return this.http.get<any>(url);
  //     }

  //     /**
  //      * GET
  //      * @param campaignId
  //      * @returns CouponCampaign
  //      */
  //     getCampaignById(campaignId: number): Observable<CouponCampaign> {
  //         const url = `${this.baseHref}/getCampaignById/${campaignId}`;
  //         return this.http.get<CouponCampaign>(url);
  //     }

  //     /**
  //      * GET
  //      * @param codes[]
  //      * @returns boolean[]
  //      */
  //     isCodeAvailable(codes: any[]): Observable<boolean[]> {
  //         const url = `${this.baseHref}/couponCodeAvailableCheck`;
  //         return this.http.post<boolean[]>(url, {codes});
  //     }

  //         // not used yet
  //     resetCoupon(couponId: number): Observable<Coupon> {
  //         const url = `${this.baseHref}/resetCoupon`;
  //         return this.http.put<Coupon>(url, couponId);
  //     }

  // ///////////////////////////////// PUT /////////////////////////////////

  //     changeStatusOfActiveCouponsInCampaign(couponCampaignId: number, newStatus: string): Observable<Coupon[]> {
  //         const url = `${this.baseHref}/campaign/editStatusOfCoupons/${couponCampaignId}`;
  //         return this.http.put<Coupon[]>(url, {newStatus});
  //     }

  // ///////////////////////////////// DELETE /////////////////////////////////

  //     /**
  //      * Implement:
  //      *  get coupon / s
  //      *  get campaign / s
  //      *  get single campaign and it's coupons
  //      *  ? coupons depending on coupon status
  //      *  ? coupons based  stuff
  //      *
  //      * list front end
  //      * coupon card
  //      * campagn card
  //      * filters
  //      */
}
