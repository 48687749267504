import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TaskService } from "../../services/task.service";
import { CancelTask } from "../../models/cancelTask.model";
import { AuthService } from "src/app/services/auth.service";
import { Title } from "@angular/platform-browser";
import { OnInit } from "@angular/core";

@Component({
  selector: "app-cancel-task-list",
  templateUrl: "./cancelTaskList.component.html",
  styleUrls: ["./cancelTaskList.component.scss"],
})
export class CancelTaskListComponent implements OnInit{
  cancelledTasks: CancelTask[] = [];
  displayCancelledTasks: CancelTask[] = [];
  filterKeys: string[] = ["offerId", "taskOwnerId", "taskId"];
  lastPage = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private taskService: TaskService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setMetaData();
  }
  setMetaData(): void {
    this.title.setTitle("Cancelled tasks");
  }

  fetchCancelledTasks(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.taskService.getCancelTasks(this.lastPage + 1).subscribe(
        (res) => {
          this.cancelledTasks = this.cancelledTasks.concat(res.data);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
        },
        (err) =>
          this.authService.handleError(err, "Failed to get cancelled tasks!"),
        () => (this.isBusy = false)
      );
    }
  }

  updateDisplayCancelledTasks(cancelledTasks: CancelTask[]): void {
    this.displayCancelledTasks = cancelledTasks;
  }
}
