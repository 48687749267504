<div>
  <h3 class="header">Users media portfolio</h3>
</div>

<div class="dialog_container">
  <div
    class="dialog_row_container"
    *ngFor="let img of userPortfolio; let i = index"
  >
    <div class="dialog_column_container">
      <div class="image_card_container">
        <!-- <app-image-card
          (reloadDialogData)="reloadDialogData()"
          [url]="url.url"
          [urlId]="url.id"
        ></app-image-card> -->
        <app-image-preview
          *ngIf="img.fileType == 'image'"
          [imageUrl]="img.url"
          [fullImageObject]="img"
          [medium]="true"
          class="support-img"
          (deleteImageEvent)="deleteImage(img.id)"
        ></app-image-preview>
      </div>
    </div>
  </div>
</div>
