<div>
  <h1>Notification logs</h1>

  <table class="statistics" *ngIf="statistics$ | async as stats">
    <thead>
      <tr>
        <th>Statistic for today</th>
        <th>Count</th>
      </tr>
    </thead>
    <tbody>
      <tr class="statistic">
        <td>Sent</td>
        <td>{{ stats.countToday }}</td>
      </tr>
      <tr class="statistic">
        <td>Error</td>
        <td>{{ stats.countError }}</td>
      </tr>
    </tbody>
  </table>

  <paginated-list
    [data]="logs"
    [filterKeys]="['id', 'eventName', 'type', 'userIds', 'success']"
    (displayDataChanged)="updateDisplayLogs($event)"
    (dataRequested)="fetchMoreNotificationLogs()"
    [moreDataAvailable]="hasMore"
  >
    <table class="table_notif_log">
      <thead>
        <tr>
          <th>Id</th>
          <th>Event</th>
          <th>Type</th>
          <th>User Ids</th>
          <th>Success</th>
          <th>Date</th>
          <th>Language</th>
        </tr>
      </thead>

      <tbody>
        <tr
          [ngClass]="{ error: !log.success }"
          *ngFor="let log of displayLogs"
          (click)="navigate(log)"
        >
          <td>{{ log.id }}</td>
          <td>{{ log.eventName }}</td>
          <td>{{ log.type }}</td>
          <td>
            <div class="userIds">{{ formatUserIds(log.userIds) }}</div>
          </td>
          <td>{{ log.success }}</td>
          <td>{{ formatDate(log.date) }}</td>
          <td>{{ log.language }}</td>
        </tr>
      </tbody>
    </table>
  </paginated-list>
</div>
