export class SupportTicketAdmin {
  id: number;
  createdAt: string;
  existingUser: boolean;
  supportTicketId: number;
  email: string;
  subject: string;
  replyInitialContent: string = '';
  originalMessage: string = '';
  links: any[];
  status: string; // (open, answered, resolved)
  supportTicketTemplateId: string;
  simpleTemplate?: boolean;

  message: string = '';
  htmlMessage: string = '';

  constructor(data = null) {
    Object.assign(this, data);
  }
}
