import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "confirm-notification-dialog",
  templateUrl: "confirm-notification-dialog.component.html",
  styleUrls: ["../dialogs.scss", "confirm-notification-dialog.component.scss"],
})
export class ConfirmNotificationDialogComponent implements OnInit {
  formValues: any;
  wanted: { email: boolean; push: boolean; web: boolean; sms: boolean };

  nbUsers$: Observable<number>;
  totalUsers: number = 0;
  displayedUsers$: Observable<any[]>;

  users: any[] = [];
  displayedUsers: any[] = [];

  private currentPage = 1;
  private isBusy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private notificationService: NotificationService
  ) {
    console.log("data", data);

    if (!data.values) {
      throw new Error("No form values passed to confirm notification dialog.");
    }

    if (!data.wanted) {
      throw new Error("No wanted value passed to confirm notification dialog.");
    }

    this.formValues = data.values;
    this.wanted = data.wanted;
  }

  ngOnInit() {
    this.nbUsers$ = this.notificationService
      .countUsersForSegment(this.formValues.segment)
      .pipe(tap((nb) => (this.totalUsers = nb)));
    this.isBusy = true;
    this.displayedUsers$ = this.notificationService
      .getUsersForSegment(this.formValues.segment, 1, 20)
      .pipe(
        tap((u) => {
          this.users = u;
          this.displayedUsers = u;
          this.isBusy = false;
        })
      );
  }

  getWantedString() {
    const wanted = [];
    if (this.wanted.email) wanted.push("email");
    if (this.wanted.push) wanted.push("push");
    if (this.wanted.web) wanted.push("web");
    if (this.wanted.sms) wanted.push("sms");
    return wanted.join(" • ");
  }

  getScheduleTime() {
    if (
      !this.formValues.scheduledAt ||
      this.formValues.scheduledAt.trim() === ""
    ) {
      return "now";
    }
    return new Date(this.formValues.scheduledAt).toLocaleString();
  }

  hasMoreUsers(): boolean {
    return this.users.length < this.totalUsers;
  }
  fetchUsers() {
    if (!this.hasMoreUsers() || this.isBusy) return;
    this.isBusy = true;
    this.notificationService
      .getUsersForSegment(this.formValues.segment, ++this.currentPage, 20)
      .subscribe((u) => {
        this.users = this.users.concat(u);
        this.isBusy = false;
      });
  }

  updateDisplayedUsers(users: any[]) {
    this.displayedUsers = users;
  }

  onClickSend() {
    this.dialogRef.close(true);
  }

  onClickCancel() {
    this.dialogRef.close(false);
  }
}
