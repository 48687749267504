<div class="header">
  <h3>Review profile picture</h3>
</div>
<div class="dialog_container">
  <div class="row">
    <div>
      <p><span class="bold">User: </span>{{userData?.firstName}} {{userData?.lastName}}</p>
      <p><span class="bold">Email: </span>{{userData?.email}}</p>
    </div>
    <div>
      <button class="pinploy_button small_button" (click)="showOrHideImage()" *ngIf="!showOrHideImageBoolean">
        Show profile image
      </button>
      <button class="pinploy_button small_button" (click)="showOrHideImage()" *ngIf="showOrHideImageBoolean">
        Hide profile image
      </button>
    </div>
  </div>
  <div class="profile_pic_container" *ngIf="showOrHideImageBoolean">
    <img src="{{userData?.profileImg}}" alt="Profile picture" class="profile_pic">
  </div>
  <div class="row">
    <button class="pinploy_button red_button" (click)="confirmProfilePicRemoval()">Remove profile picture and notify user</button>
  </div>
</div>
