<!-- <p *ngIf="coupon === null; else editing">Create coupon</p>
<ng-template #editing>Edit coupon {{ coupon.id }}</ng-template> -->
<div class="inputs-container">
  <div class="row">
    <div class="column">
      <mat-form-field *ngIf="coupon !== null">
        <input
          matInput
          disabled
          type="number"
          placeholder="ID"
          value="{{ coupon?.id }}"
        />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Code" [(ngModel)]="inputs.code" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Name" [(ngModel)]="inputs.name" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Comment" [(ngModel)]="inputs.comment" />
      </mat-form-field>
      <div class="row">
        <div class="large-column">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select
              [ngModel]="handleTypeModelChange()"
              (ngModelChange)="handleTypeInputSelect($event)"
            >
              <mat-option value="fixed">Fixed</mat-option>
              <mat-option value="percentage">Percentage</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="number"
              placeholder="Value"
              [ngModel]="handleValueModelChange()"
              (ngModelChange)="handleValueInputChange($event)"
            />
          </mat-form-field>
        </div>
        <div class="small-column">
          <p>{{ displayValue() }}</p>
        </div>
      </div>
    </div>
    <div class="column">
      <p style="width: 100%; height: 100%">More stuff here</p>
    </div>
  </div>
  <div class="buttons">
    <button (click)="handleClose()">Cancel</button>
    <button (click)="test()">Print</button>
  </div>
</div>
