<div class="page_container">
  <div class="header">
    <h1>Notification logs détails: {{ notificationId ?? "loading... " }}</h1>
    <button class="pinploy_button" routerLink="/notification-logs">Back</button>
  </div>

  <ng-container *ngIf="notificationLog$ | async as notificationLog; else loading">
    <div class="notification_log_container">
      <div>Id: {{ notificationLog.id }}</div>
      <div>Event: {{ notificationLog.eventName }}</div>
      <div>Type: {{ notificationLog.type }}</div>
      <br />
      <div>
        User Ids: <br />
        {{ formatUserIds(notificationLog.userIds) }}
      </div>
      <br />
      <div>Date: {{ formatDate(notificationLog.date) }}</div>
      <div>Language: {{ notificationLog.language }}</div>
      <br />
      <p>Data:</p>
      <div class="data-container">
        <pre class="data" (click)="copyToClipboard(notificationLog.data)">{{
          notificationLog.data ? (parseJson(notificationLog.data) | json) : ""
        }}</pre>
      </div>
      <br />
      <p>Error:</p>
      <div class="data-container">
        <pre class="data" (click)="copyToClipboard(notificationLog.stackError)">{{
          notificationLog.stackError
            ? (parseJson(notificationLog.stackError) | json)
            : ""
        }}</pre>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="loading_container">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>