import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { User } from '../models/user.model';

const keys = {
  token: 'token'
};

@Injectable()
export class StorageService {

  constructor( @Inject(LocalStorageService) private localStorage: LocalStorageService ) { }

  clear() { this.localStorage.clear(); }

  private save(key: string, object: any): void { this.localStorage.store(key, object); }
  private get(key: string): any { return this.localStorage.retrieve(key); }

  get token(): any { return this.get(keys.token); }
  set token(token: any) { this.save(keys.token, token); }

  set user(user: User) { this.save('user', user); }
  get user(): any { return this.get('user'); }
}
