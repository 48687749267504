export class PenaltyModel {
  id: number;
  cancelTaskId: number;
  taskId: number;
  offerId: number;
  userId: number;
  status: string;
  amount: number;
  isTransfered: boolean;
  transactionId?: number;
  chargeId: string;
  appliedOfferId: number;
  createdAt: string;
  updatedAt: string;

  cancelTask: any;

  constructor(data: any = null) {
    Object.assign(this, data);
  }
}
