<div class="tab_content_container">
  <!-- Display both language -->
  <ng-container *ngIf="choosedLanguage === 'userSettings'">
    <div class="vertical">
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
      ></ng-container>
      <mat-divider [vertical]="true"></mat-divider>
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
      ></ng-container>
    </div>

    <ng-container>
      <ng-container *ngTemplateOutlet="optParams"></ng-container>
    </ng-container>
  </ng-container>

  <!-- Force dk language -->
  <ng-container *ngIf="choosedLanguage === 'dk'">
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
    ></ng-container>
  </ng-container>

  <!-- Force en language -->
  <ng-container *ngIf="choosedLanguage === 'en'">
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
    ></ng-container>
  </ng-container>
  <ng-template #completeForm let-lang="lang">
    <div class="lang_container">
      <h2>{{ lang === "dk" ? "Danish" : "English" }}</h2>

      <div class="form_container" *ngIf="contextForm.get(lang) as fg">
        <div>
          <!-- TITLE -->
          <ng-container *ngIf="fg.get('title') as inputForm">
            <mat-form-field appearance="fill">
              <mat-label> Title </mat-label>
              <input matInput [formControl]="inputForm" type="text" required />
              <mat-error
                *ngIf="inputForm.invalid && inputForm.hasError('required')"
              >
                Title is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="choosedLanguage !== 'userSettings'">
            <ng-container *ngTemplateOutlet="optParams"></ng-container>
          </ng-container>
        </div>

        <!-- BODY -->
        <ng-container *ngIf="fg.get('body') as inputForm">
          <mat-form-field appearance="fill">
            <mat-label> Body </mat-label>
            <textarea
              matInput
              [formControl]="inputForm"
              rows="5"
              required
            ></textarea>
            <mat-error
              *ngIf="inputForm.invalid && inputForm.hasError('required')"
            >
              Body is required
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

<!-- PUSH DATA -->
<ng-template #optParams>
  <ng-container *ngIf="contextForm.get('pushData') as inputForm">
    <mat-form-field appearance="fill">
      <mat-label> Push data </mat-label>
      <mat-hint align="end">
        Must be a valid JSON object or empty (eg: {{ "{" }} "taskId": 1
        {{ "}" }})
      </mat-hint>
      <input matInput [formControl]="inputForm" type="text" />
      <mat-error *ngIf="inputForm.invalid && inputForm.hasError('invalidJson')">
        pushData must be a valid JSON object or empty
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-template>
