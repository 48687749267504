import { Title } from '@angular/platform-browser';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { TaskService } from '../../../services/task.service';
import { UserService } from 'src/app/services/user.service';
import { OfferService } from 'src/app/services/offer.service';
import { StateService } from 'src/app/services/state.service';
import { Tag } from '../../../models/tag.model';
import { TagService } from '../../../services/tag.service';
import { Observable } from 'rxjs';
import { Task } from '../../../models/task.model';
import { ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { map } from 'rxjs/operators';

import { MatChipInputEvent } from '@angular/material/chips';

import { MatDialog } from '@angular/material/dialog';
import { RequestCancellationDialog } from 'src/app/components/dialogs/cancellation/request-cancellation/requestCancellationDialog.component';
import { ConflictCase } from 'src/app/models/conflictCase.model';

import { ConflictService } from 'src/app/services/conflict.service';
import { QuestionDialogComponent } from '../../../components/dialogs/questionDialog/questionDialog.component';
import { CancelTask } from 'src/app/models/cancelTask.model';
import { CancelTaskAutomation } from 'src/app/models/cancelTaskAutomation';
import { ChangeCancellationDialog } from 'src/app/components/dialogs/cancellation/change-cancellation/changeCancellationDialog.component';
import { MiscService } from 'src/app/services/misc.service';
import { InformationDialogComponent } from 'src/app/components/dialogs/informationDialog/informationDialog.component';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() offerId: number;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  // Tag related
  @ViewChild('andetTagCat', { read: ElementRef, static: true })
  tagCatSecOfAndet!: ElementRef<HTMLDivElement>;
  @ViewChild('digitalTagCat', { read: ElementRef, static: true })
  tagCatSecOfDigital!: ElementRef<HTMLDivElement>;
  @ViewChild('haveTagCat', { read: ElementRef, static: true })
  tagCatSecOfHave!: ElementRef<HTMLDivElement>;
  @ViewChild('husTagCat', { read: ElementRef, static: true })
  tagCatSecOfHus!: ElementRef<HTMLDivElement>;
  @ViewChild('transportTagCat', { read: ElementRef, static: true })
  tagCatSecOTransport!: ElementRef<HTMLDivElement>;

  mainCatTagsSection = [];
  chunkedCategoryArray = [];

  //Basic task related
  userId: number;
  taskId: number;
  task: Task;
  taskChanges: Task;
  createdAtFormatted: string;
  taskViews: any;

  reviews: any;

  reviewOfHandyhander: any;
  reviewOfTaskOwner: any;

  //Tag related
  Tags: string[] = [];
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<Tag[]>;
  allTags = [];
  tagChanged = new UntypedFormControl();
  tempTagArr = [];
  allTagsWithAssociatedTagCat;
  isTaskTaggingEnabled: boolean = false;
  isHighlightedTagCatActive = false;

  // notification related
  tagNotificationsSent: boolean = false;

  // conflict related
  conflictCase: ConflictCase;
  cancelTask: {
    cancelTask: CancelTask;
    cancelTaskAuto: CancelTaskAutomation;
  };

  // conversation related
  conversationType: string;
  conversationTypeId: number;

  cancellationChanged: boolean = false;

  constructor(
    private title: Title,
    private miscService: MiscService,
    private stateService: StateService,
    private router: Router,
    private userService: UserService,
    private offerService: OfferService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private tagService: TagService,
    private taskService: TaskService,
    private conflictService: ConflictService,
    private dialogRef: MatDialog,
  ) {}

  ngOnInit(): void {
    this.stateService.toggleWaitingPage(true);
    this.task = new Task();
    this.taskChanges = { ...this.task };
    this.filteredTags = this.tagChanged.valueChanges.pipe(
      map((tag: string) => (tag ? this._filter(tag) : this.allTags.slice())),
    );
    this.getTaskData();
    this.populateAllTagsFromDb();
    this.getAllTagsAssociatedToTagCat();
    this.setConversationType();
  }

  setMetaData() {
    this.title.setTitle(`Task: "${this.task.title}"`);
  }

  get isTitleGenerated(): string {
    return this.task.aiGeneratedTitle ? 'True' : 'False';
  }

  getTaskData() {
    this.route.params.subscribe((params) => {
      this.taskService.getTaskById(params.id).subscribe(
        (res) => {
          this.task = res;
          this.setMetaData();
          this.formatDate(this.task.createdAt);
          this.taskChanges = { ...this.task };
          this.taskId = this.task.id;
          this.taskService.getTaskViews(this.task.id).subscribe((res) => {
            this.taskViews = res;
          });

          //Check for conflict case on task
          this.conflictService.findConflictCaseByTaskId(this.taskId).subscribe(
            (res) => {
              if (res) {
                this.conflictCase = res;
              }
            },
            (_) => {
              this.conflictCase = null;
            },
          );
          this.userId = this.task.userId;
          this.getTasksTagsForTask(params.id);

          this.taskService.getCancelTaskByTaskId(this.task.id).subscribe({
            next: (res) => {
              this.cancelTask = res;
            },
            error: (err) => {
              this.cancelTask = null;
              this.toastr.error('Failed to get cancel task');
            },
          });

          if (this.cancelTask) {
            this.offerService
              .getReviewsForOffer(this.cancelTask.cancelTask.offerId)
              .subscribe({
                next: (res) => {
                  this.reviewOfTaskOwner = res.createdReview;
                  this.reviewOfHandyhander = res.receivedReview;
                },
                error: (err) => {
                  this.toastr.error('Failed to get reviews for offer');
                },
              });
          } else if (this.task.status === 'completed') {
            this.offerService
              .getReviewsForOffer(this.task.acceptedOffer.id)
              .subscribe({
                next: (res) => {
                  this.reviewOfTaskOwner = res.createdReview;
                  this.reviewOfHandyhander = res.receivedReview;
                },
                error: (err) => {
                  this.toastr.error('Failed to get reviews for offer');
                },
              });
          }
          this.stateService.toggleWaitingPage(false);
        },
        (err) => {
          this.authService.handleError(err, 'Failed to get task');
        },
      );
    });
  }
  formatDate(date: string) {
    const dateObj = new Date(date);

    // format dateObj to a readable date like DD/MM/YYYY HH:MM
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }

  openTaskInformationDetailsDialog() {
    const dialogRef = this.dialogRef.open(InformationDialogComponent, {
      width: '80%',
      data: { title: 'Task information', object: this.task },
    });
  }

  setConversationType() {
    this.route.params.subscribe((params) => {
      switch (params.convType) {
        case 'offer':
          this.conversationType = 'offer';
          this.conversationTypeId = params.convId;
          break;
        case 'offer-comm':
          this.conversationType = 'offer-comment';
          this.conversationTypeId = params.convId;
          break;
        case 'task-comm':
          this.conversationType = 'task-comment';
          this.conversationTypeId = params.convId;
          break;
        default:
          this.conversationType = 'task';
          this.conversationTypeId = params.id;
          break;
      }
    });
  }

  ngAfterViewInit() {
    this.mainCatTagsSection = [
      {
        name: 'andet',
        viewChild: this.tagCatSecOfAndet?.nativeElement,
      },
      {
        name: 'digital',
        viewChild: this.tagCatSecOfDigital?.nativeElement,
      },
      {
        name: 'have',
        viewChild: this.tagCatSecOfHave?.nativeElement,
      },
      {
        name: 'hus',
        viewChild: this.tagCatSecOfHus?.nativeElement,
      },
      {
        name: 'transport',
        viewChild: this.tagCatSecOTransport?.nativeElement,
      },
    ];
  }

  getTasksTagsForTask(taskId) {
    this.tagService.getTaskTagsFromTaskId(taskId).subscribe((result) => {
      this.tempTagArr = result.slice();
      for (let i = 0; i < result.length; i++) {
        this.Tags.push(result[i].tag);
      }
    });
  }

  populateAllTagsFromDb() {
    this.tagService.getAllTags().subscribe((res) => {
      if (res) {
        this.allTags = res;
      }
    });
  }

  completeTask() {
    const dialogTitle = 'Confirm to complete task';
    const dialogDescription = `Are you sure you want to complete task: <b><i>taskID: ${this.task.id}, "${this.task.title}"</i><b/>?`;
    const confirmComplete = this.dialogRef.open(QuestionDialogComponent, {
      width: '500px',
      data: { title: dialogTitle, description: dialogDescription },
    });
    confirmComplete.afterClosed().subscribe(
      (data) => {
        this.stateService.toggleWaitingPage(true);
        if (data === 'yes') {
          this.taskService.completeTask(this.task.id).subscribe((res) => {
            if (res) {
              this.task.status = res.status;
              this.stateService.toggleWaitingPage(false);
              this.toastr.success(`Task Completed`);
            }
          });
          this.userService
            .sendAdminCompletedTaskEmail(this.userId, this.taskId)
            .subscribe();
        }
      },
      (err) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error(err.message, 'Error');
      },
    );
  }

  openChangeCancellationDialog() {
    const dialog = this.dialogRef.open(ChangeCancellationDialog, {
      width: '33vw',
      data: { title: 'Change Cancellation', cancelTask: this.cancelTask },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.cancellationChanged = true;
        const tempConversationType = this.conversationType;
        this.conversationType = null;
        setTimeout(() => {
          this.conversationType = tempConversationType;
        }, 1);
      }
    });
  }

  deactivateTask() {
    const dialogTitle = 'Confirm to deactivate task';
    const dialogDescription = `Are you sure you want to deactivate task: <b><i>taskID: ${this.task.id}, "${this.task.title}"</i><b/>?`;
    const confirmCancel = this.dialogRef.open(QuestionDialogComponent, {
      width: '500px',
      data: { title: dialogTitle, description: dialogDescription },
    });

    confirmCancel.afterClosed().subscribe((data) => {
      if (data === 'yes') {
        this.stateService.toggleWaitingPage(true);
        this.taskService
          .editTask(this.task.id, { status: 'canceled' })
          .subscribe(
            (res) => {
              if (res) {
                this.task.status = res.status;
                this.stateService.toggleWaitingPage(false);
                this.toastr.success(
                  `Task Cancelled, Offer & Task status modified`,
                );
              }
            },
            (err) => {
              this.stateService.toggleWaitingPage(false);
              this.toastr.error(err.message, 'Error');
            },
          );
      }
    });
  }

  deleteTask() {
    const dialogTitle = 'Confirm to delete task';
    const dialogDescription = `Are you sure you want to delete task: <b><i>taskID: ${this.task.id}, "${this.task.title}"</i><b/>?`;
    const confirmDelete = this.dialogRef.open(QuestionDialogComponent, {
      width: '500px',
      data: { title: dialogTitle, description: dialogDescription },
    });
    confirmDelete.afterClosed().subscribe((data) => {
      if (data === 'yes') {
        this.stateService.toggleWaitingPage(true);
        this.taskService
          .editTask(this.task.id, { status: 'erased' })
          .subscribe((res) => {
            if (res) {
              this.task.status = res.status;
              this.stateService.toggleWaitingPage(false);
              this.toastr.success(`Task Deleted`);
            } else {
              this.stateService.toggleWaitingPage(false);
              this.toastr.error(`Failed to delete task`);
            }
          });
      }
    });
  }

  deleteImage(id: any) {
    this.miscService.deleteMediaById({ id: id, resource: 'task' }).subscribe({
      next: (res) => {
        this.toastr.success('Image deleted');
        this.ngOnInit();
      },
      error: (err) => {
        this.toastr.error('Failed to delete image');
      },
    });
  }

  restoreTask() {
    const dialogTitle = 'Confirm to restore task';
    const dialogDescription = `Are you sure you want to restore task: <b><i>taskID: ${this.task.id}, "${this.task.title}"</i><b/>?`;
    const confirmRestore = this.dialogRef.open(QuestionDialogComponent, {
      width: '500px',
      data: { title: dialogTitle, description: dialogDescription },
    });
    confirmRestore.afterClosed().subscribe((data) => {
      if (data === 'yes') {
        this.stateService.toggleWaitingPage(true);
        this.taskService
          .editTask(this.task.id, { status: 'pending' })
          .subscribe((res) => {
            if (res) {
              this.task.status = res.status;
              this.stateService.toggleWaitingPage(false);
              this.toastr.success(`Task Restored`);
            } else {
              this.stateService.toggleWaitingPage(false);
              this.toastr.error(`Failed to restore task`);
            }
          });
      }
    });
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter((tagObject) =>
      tagObject.tag.toLowerCase().includes(filterValue),
    );
  }

  /*our custom add method which will take
  matChipInputTokenEnd event as input.*/
  add(event: MatChipInputEvent): void {
    /*we will store the input and value in local variables.*/
    const input = event.input;
    const value = event.value;
    const newTag = this.allTags.filter((x) => x.tag === value.toLowerCase());
    if ((value || '').trim()) {
      this.Tags.push(newTag[0]);
    }
    if (input) {
      input.value = '';
    }
    this.tagChanged.setValue(null);
  }

  remove(tag: string) {
    const index = this.Tags.indexOf(tag);
    if (index >= 0) {
      this.Tags.splice(index, 1);
    }
  }

  addOrRemoveTag(tag: any) {
    const selectedTag = this.allTags.filter(
      (x) => x.tag.toLowerCase() === tag.tag.toLowerCase(),
    );
    if (selectedTag) {
      this.Tags.push(selectedTag[0].tag);
    } else {
      this.toastr.error('There was a problem adding that Tag');
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedTag = this.allTags.filter(
      (x) => x.tag.toLowerCase() === event.option.viewValue.toLowerCase(),
    );
    this.Tags.push(selectedTag[0].tag);
    this.tagInput.nativeElement.value = '';
    this.tagChanged.setValue(null);
  }

  isBtnDisabled() {
    for (const property in this.task) {
      if (this.task[property] !== this.taskChanges[property]) {
        return false;
      }
    }
    return true;
  }

  removeUnchangedProperties() {
    for (const property in this.task) {
      if (this.task[property] === this.taskChanges[property]) {
        delete this.taskChanges[property];
      } else {
        this.taskChanges[property] = this.task[property];
      }
    }
  }

  saveChanges() {
    this.stateService.toggleWaitingPage(true);
    this.removeUnchangedProperties();
    this.taskService.editTask(this.task.id, this.taskChanges).subscribe(
      (res) => {
        const user = { ...this.task.user };
        this.task = res;
        this.task.user = { ...user };
        this.taskChanges = { ...this.task };
        this.toastr.success('Task has been updated');
        this.stateService.toggleWaitingPage(false);
      },
      (err) => {
        this.authService.handleError(err, 'Failed to update task');
        this.toastr.error('Failed to update task');
        this.stateService.toggleWaitingPage(false);
      },
    );
  }

  getAllTagsAssociatedToTagCat() {
    this.tagService.getTagsAssociatedByTagCategory().subscribe((res) => {
      if (res) {
        this.allTagsWithAssociatedTagCat = res;
        this.allTagsWithAssociatedTagCat.andet.tags =
          this.allTagsWithAssociatedTagCat.andet.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.digital.tags =
          this.allTagsWithAssociatedTagCat.digital.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.transport.tags =
          this.allTagsWithAssociatedTagCat.transport.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.hus.tags =
          this.allTagsWithAssociatedTagCat.hus.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.have.tags =
          this.allTagsWithAssociatedTagCat.have.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
      }
    });
  }

  instantNotificationsNewTags() {
    this.stateService.toggleWaitingPage(true);
    this.taskService
      .instantNotificationsNewTags(this.task.id)
      .subscribe((res) => {
        if (res) {
          this.toastr.success(`${res.totalUsersNotified} users notified!`);
          this.toastr.success(`Instant Notification Succesfull!`);
          this.stateService.toggleWaitingPage(false);
        }
      });
  }

  isTagBtnDisabled(type: string) {
    switch (type) {
      case 'instant':
        return (
          this.Tags.length === 0 ||
          this.task.status !== 'pending' ||
          !!this.task.acceptedOffer
        );
      case 'save':
        return !this.allTags || !this.Tags.length;
      default:
        return true;
    }
  }

  isConflictCaseBtnDisabled() {
    if (
      this.task.status === 'canceled' ||
      this.task.status === 'completed' ||
      !this.task.acceptedOffer
    ) {
      return true;
    }
  }

  getConflictCastBtnText() {
    if (this.conflictCase?.status === 'cancelled') {
      return 'Reopen conflict case';
    } else if (
      this.task.status !== 'canceled' &&
      this.task.status !== 'completed' &&
      this.task.acceptedOffer &&
      !!this.conflictCase
    ) {
      return 'Navigate to conflict case';
    } else {
      return 'Start conflict case';
    }
  }

  saveTagChanges() {
    const tagsToSave = {
      taskId: this.task.id,
      tags: [],
    };

    for (let j = 0; j < this.allTags.length; j++) {
      for (let i = 0; i < this.Tags.length; i++) {
        if (this.Tags[i] === this.allTags[j].tag) {
          tagsToSave.tags.push(this.allTags[j]);
        }
      }

      this.tagService.updateTagFromTask(tagsToSave).subscribe((res) => {
        if (res) {
          this.toastr.success('Tags updated ');
        }
      });
    }
  }

  requestCancellationAsUser() {
    const dialog = this.dialogRef.open(RequestCancellationDialog, {
      width: '500px',
      data: { title: 'Request cancellation on behalf of user' },
    });

    dialog.afterClosed().subscribe((res) => {
      this.stateService.toggleWaitingPage(true);
      if (res?.res === 'yes') {
        const cancelTaskObj = {
          cancellationReason: res.cancellationReason,
          desc: res.reasonComment,
          taskId: this.task.id,
        };
        const options = res.cancellationOptions;
        if (
          cancelTaskObj.desc === null ||
          cancelTaskObj.desc === '' ||
          cancelTaskObj.desc === undefined
        ) {
          cancelTaskObj.desc = 'Requested by admin - no reason given';
        }
        if (options === 'As user') {
          this.taskService.requestCancellationAsUser(cancelTaskObj).subscribe(
            (res) => {
              if (res) {
                this.toastr.success(
                  'Task has entered cancellation flow',
                  'Success',
                );
                this.ngOnInit();
                this.stateService.toggleWaitingPage(false);
              }
            },
            (err) => {
              this.stateService.toggleWaitingPage(false);
              this.toastr.error(err.message, 'Error');
            },
          );
        } else if (options === 'Force') {
          this.taskService
            .cancelAcceptedTask(
              cancelTaskObj.taskId,
              cancelTaskObj.cancellationReason.feeOwner,
            )
            .subscribe((res) => {
              if (res) {
                this.stateService.toggleWaitingPage(false);
                this.toastr.success(
                  'Task has forcefully been cancelled',
                  'Success',
                );
                this.ngOnInit();
              }
            }),
            (err) => {
              this.stateService.toggleWaitingPage(false);
              this.toastr.error(err.message, 'Error');
            };
        }
      } else {
        this.stateService.toggleWaitingPage(false);
      }
    });
  }

  selectedHighlightedCategory(categoryName: string) {
    this.isHighlightedTagCatActive = true;
    const indexOfHighlightedSec = this.mainCatTagsSection
      .map((tagCat) => tagCat.name)
      .indexOf(categoryName);

    this.mainCatTagsSection.forEach((cat, index) => {
      if (indexOfHighlightedSec !== index) {
        cat.viewChild.hidden = true;
      }
    });
  }

  chunkArray(array: [], chunkSize: number) {
    this.chunkedCategoryArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      this.chunkedCategoryArray.push(array.slice(i, i + chunkSize));
    }
  }

  //toggleTaskTagging = () => this.isTaskTaggingEnabled = !this.isTaskTaggingEnabled;
  toggleTaskTagging() {
    if (this.isTaskTaggingEnabled) {
      this.isTaskTaggingEnabled = false;
    } else if (!this.isTaskTaggingEnabled) {
      this.isTaskTaggingEnabled = true;
    }
  }

  clearHighlightedCategory() {
    this.chunkedCategoryArray = [];
    this.isHighlightedTagCatActive = false;
    this.mainCatTagsSection.forEach((cat) => {
      cat.viewChild.hidden = false;
    });
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }

  startConflictCase() {
    // conflict exists navigate to conflict case
    if (!!this.conflictCase && this.conflictCase.status !== 'cancelled') {
      this.router.navigate(['/conflict-cases', this.conflictCase.id]);
      return;
    }

    const title =
      this.conflictCase?.status === 'cancelled'
        ? 'Reopen conflict case'
        : 'Start conflict case';

    let description =
      this.conflictCase?.status === 'cancelled'
        ? 'Are you sure you want to reopen a conflict case ?'
        : 'Are you sure you want to start a conflict case ?';

    if (!this.cancelTask.cancelTask || !this.cancelTask.cancelTaskAuto) {
      description += '<br><br> <b>This task is not in cancellation!</b>';
    }

    const dialog = this.dialogRef.open(QuestionDialogComponent, {
      width: '500px',
      data: {
        title: title,
        description: description,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res === 'yes') {
        if (
          this.conflictCase === null ||
          this.conflictCase?.status !== 'cancelled'
        ) {
          this.stateService.toggleWaitingPage(true);
          this.conflictService.startConflictCase(this.task.id).subscribe(
            (res) => {
              this.stateService.toggleWaitingPage(false);
              if (res) {
                this.toastr.success('Conflict case started', 'Success');
                this.conflictCase = res;
              }
            },
            (err) => {
              this.stateService.toggleWaitingPage(false);
              this.toastr.error(err.message, 'Error');
            },
          );
        } else {
          this.stateService.toggleWaitingPage(true);
          this.conflictService
            .reopenConflictCase(this.conflictCase.id)
            .subscribe(
              (res) => {
                this.stateService.toggleWaitingPage(false);
                if (res) {
                  this.toastr.success('Conflict case reopened', 'Success');
                  this.conflictCase = res;
                }
              },
              (err) => {
                this.stateService.toggleWaitingPage(false);
                this.toastr.error(err.message, 'Error');
              },
            );
        }
      }
    });
  }

  toolsNeeded() {
    if (this.task.toolsNeeded === true) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  capitalizeFirstLetter(input: string) {
    const firstLetter = input[0].toUpperCase();
    const restOfString = input.slice(1).toLowerCase();

    return firstLetter + restOfString;
  }

  calculateTimeDifference(dateString: string): number {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = inputDate.getTime() - currentDate.getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    const timeFrom48 = 48 + hoursDifference;
    const answer = Math.ceil(timeFrom48);
    return answer;
  }
  goToManualCancellation() {
    this.router.navigate([`/cancel-task/${this.cancelTask.cancelTask.id}`]);
  }

  updatePayoutCalculations() {
    this.taskService.updatePayoutCalculations(this.task.id).subscribe(
      (res) => {
        if (res) {
          this.toastr.success('Payout calculations updated', 'Success');
        }
      },
      (err) => {
        this.toastr.error(err.message, 'Error');
      },
    );
  }

  checkServiceType(serviceTypeArray: any[], serviceType: string) {
    return serviceTypeArray.some((service) => service.type === serviceType);
  }
}
