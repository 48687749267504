<paginated-list
  [data]="notifications"
  [filterKeys]="['id', 'title', 'segment', 'success']"
  (displayDataChanged)="updateDisplayNotification($event)"
  (dataRequested)="fetchMoreNotifications()"
  [moreDataAvailable]="hasMore"
>
  <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>Title</th>
        <th>Segment</th>
        <th>Started At</th>
        <th>Ended At</th>
        <th>Ellapsed</th>
        <th>Success</th>
        <th>Failed</th>
        <th>Status</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let notif of displayNotifications" (click)="navigate(notif)">
        <td>{{ notif.id }}</td>
        <td>{{ notif.title }}</td>
        <td>{{ notif.segment }}</td>
        <td>{{ notif.started_at | date : "dd/MM/yy HH:mm:ss" }}</td>
        <td>{{ notif.ended_at | date : "dd/MM/yy HH:mm:ss" }}</td>
        <td>{{ getEllapsedTime(notif) }}</td>
        <td>{{ notif.nb_success }}</td>
        <td>{{ notif.nb_failed }}</td>
        <td [class]="notif.status">{{ notif.status }}</td>
      </tr>
    </tbody>
  </table>
</paginated-list>
