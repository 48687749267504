import { Component, Input, Output, EventEmitter } from "@angular/core";
import { CommonFunctions } from "../common";
import { Coupon } from "src/app/models/coupon.model";
import { CouponUse } from "src/app/models/couponUse.model";
import { AuthService } from "src/app/services/auth.service";
import { CouponService } from "src/app/services/coupon.service";

@Component({
  selector: "app-coupon-uses",
  templateUrl: "./couponUses.component.html",
  styleUrls: ["../common.scss", "./couponUses.component.scss"],
})
export class CouponUsesComponent extends CommonFunctions {
  @Input() coupon: Coupon;
  @Output() close = new EventEmitter<void>();

  filterKeys = ["id", "taskId", "offerId", "taskOwnerId", "handyhanderId"];

  couponUses: CouponUse[] = [];
  displayCouponUses: CouponUse[] = [];
  lastPage: number = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;

  constructor(
    private couponService: CouponService,
    private authService: AuthService
  ) {
    super();
  }

  fetchMoreCouponUses(): void {
    if (this.coupon && this.hasMore) {
      this.isBusy = true;
      this.couponService
        .getCouponUses(this.coupon.id, this.lastPage + 1)
        .subscribe(
          (res) => {
            this.couponUses = this.couponUses.concat(res.couponUses);
            this.hasMore = res.hasMore;
            this.lastPage += 1;
          },
          (err) => this.authService.handleError(err, "Failed to get coupons!"),
          () => (this.isBusy = false)
        );
    }
  }

  updateDisplayCouponUses(couponUses: CouponUse[]): void {
    this.displayCouponUses = couponUses;
  }

  resetCouponUses(): void {
    this.couponUses = [];
    this.displayCouponUses = [];
    this.lastPage = 0;
    this.hasMore = true;
    this.isBusy = false;
  }
}
