<div>
    <h3 class="header">Users media header</h3>
</div>

<div class="dialog_container">
        <div class="dialog_column_container">
            <div *ngIf="userHeaderImage?.url" class="image_card_container">
                <img class="headerImage" src="{{userHeaderImage.url}}"/>
            </div>
        </div>
        <button class="pinploy_button small_button red_button" (click)="deleteHeaderImage()">Delete header image</button>
</div>