import { Task } from './task.model';
import { Offer } from './offer.model';
import { Comment } from './comment.model';
import { Message } from './message.model';
import { Conversation } from './conversation.model';
import { Review } from './review.model';
import { type UserLimitations } from './userLimitations.model'

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  emailToken: string;
  password: string;
  phone: string;
  profileImg: string;
  dob: string;
  gender: string;
  address: string;
  city: string;
  zipCode: string;
  description: string;
  education: string;
  isActive: boolean;
  stripeCustomerId: string;
  stripeAccountId: string;

  profileRating: number;
  tasksCreated: number;
  tasksCompleted: number;
  offersMade: number;
  lastOnline: string;
  userRoleId: number;
  role: any[];

  tasks: Task[];
  offers: Offer[];
  comments: Comment[];

  targets: User[];
  reporters: User[];

  sendMessages: Message[];
  receivedMessages: Message[];

  createdConversations: Conversation[];
  receivedConversations: Conversation[];

  sendReviews: Review[];
  receivedReviews: Review[];

  activeTasks: number;
  inProgressTasks: number;
  completedTasks: number;
  canceledTasks: number;
  expiredTasks: number;
  erasedTasks: number;
  completedOffers: number;
  inProgressOffers: number;
  activeTaskComments: number;
  removedTaskComments: number;
  activeUserReports: number;
  removedUserReports: number;
  storedCredits: any;
  storedCreditsBalance: any;
  
  completionRate: number;
  totalCompletionRate: number;
  cancellationRate: number;

  userLimitations: UserLimitations;

  createdAt: string;
  updatedAt: string;

  constructor(data: any = null) {
    Object.assign(this, data);
  }
}
