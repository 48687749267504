<div class="dialog_container">
  <h3>Manage user bans</h3>
  <div
    *ngIf="userData"
    class="userData"
  >
    <p>
      <span class="bold">User: </span>{{ userData.firstName }}
      {{ userData.lastName }}
    </p>
    <p><span class="bold">ID: </span>{{ userData.id }}</p>
    <p><span class="bold">Email: </span>{{ userData.email }}</p>
  </div>
  <div
    #banList
    class="banList"
  >
    <div *ngIf="banHistory.length > 0">
      <p class="bold">History:</p>
    </div>
    <ng-container *ngFor="let ban of banHistory; let i = index">
      <div
        class="ban-history-entry"
        [ngClass]="{ activeBan: ban.isActive, dark: i % 2 === 1 }"
      >
        <span class="flex_half">ID: {{ ban.id }}, </span>
        <span class="flex_1">Banned: {{ getDate(ban.createdAt) }}, </span>
        <span class="flex_1">Until: {{ getDate(ban.bannedUntil) }}, </span>
        <span class="flex_1"
          >Cancelled:
          {{ ban.cancelled ? getDate(ban.updatedAt) : 'False' }}</span
        >
        <span class="flex_2">Reason: {{ ban.reason }}</span>
        <span class="flex_2">Admin notes: {{ ban.adminNotes }}</span>
      </div>
    </ng-container>
    <div *ngIf="banHistory.length === 0">
      <p>The user has never been banned</p>
    </div>
  </div>
  <ng-container *ngIf="getActiveBan() === undefined; else already_banned">
    <div class="horizontal">
      <mat-form-field class="input">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Ban until"
          [formControl]="banDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        class="input"
        id="banReason"
      >
        <!-- Dropdown for choosing reason -->
        <ng-container *ngIf="!otherBanReasonSelected">
          <mat-select
            name="myControl"
            placeholder="Reason"
            [value]="banReason"
            [(ngModel)]="banReason"
            (selectionChange)="onReasonSelectionChange($event)"
          >
            <mat-option value="Deling af kontaktinformationer"
              >Deling af kontaktinformationer</mat-option
            >
            <mat-option value="Brud på retningslinjerne"
              >Brud på retningslinjerne</mat-option
            >
            <mat-option
              value="true"
              [(ngModel)]="otherBanReasonSelected"
              >Andet</mat-option
            >
          </mat-select>
        </ng-container>

        <div
          id="banReasonCss"
          *ngIf="otherBanReasonSelected"
        >
          <input
            id="banReasonInput"
            matInput
            placeholder="Custom reason"
            [(ngModel)]="banReason"
          />
          <mat-icon (click)="resetBanReason()"> arrow_drop_down </mat-icon>
        </div>
      </mat-form-field>

      <mat-form-field class="input">
        <input
          matInput
          placeholder="Admin Notes"
          [(ngModel)]="adminNotes"
        />
      </mat-form-field>
    </div>
    <div
      class="error"
      *ngIf="banError"
    >
      {{ banError }}
    </div>

    <button
      class="pinploy_button small_button"
      (click)="banUser()"
    >
      Ban
    </button>
  </ng-container>
  <ng-template #already_banned>
    <p>The user is currently banned</p>
    <button
      class="pinploy_button small_button"
      (click)="cancelBan()"
    >
      Cancel Ban
    </button>
  </ng-template>
</div>
