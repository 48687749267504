import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";

@Component({
  selector: "app-notification-segment",
  templateUrl: "notificationSegment.component.html",
  styleUrls: ["notificationSegment.component.scss"],
})
export class NotificationSegmentComponent implements OnInit {
  selectedIndex: number | null = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      if ("tabs" in params) {
        if (params.tabs === "1") {
          this.selectedIndex = 1;
        } else {
          this.selectedIndex = 0;
        }
      }
    });
  }

  changeSelectedIndex(index: number): void {
    this.selectedIndex = index;
    this.router.navigate([], {
      queryParams: { tabs: index },
    });
  }
}
