<div [ngClass]="cards ? 'card-list' : ''">
  <div class="list">
    <div *ngIf="filterKeys">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="filterString"
          placeholder="Filter"
          (ngModelChange)="applyFilter()"
        />
      </mat-form-field>
    </div>

    <div
      class="pagination"
      *ngIf="data?.length > 0"
    >
      <button
        (click)="prevPage()"
        [disabled]="pageNumber <= 1"
      >
        Prev
      </button>
      <p>{{ getPaginationString() }}</p>
      <button
        (click)="nextPage()"
        [disabled]="pageNumber >= getMaxNumPages()"
      >
        Next
      </button>
    </div>

    <div [ngClass]="cards ? 'cards' : ''">
      <ng-content></ng-content>
    </div>

    <div
      *ngIf="data?.length > 0"
      class="back-to-top"
    >
      <p (click)="scrollToTop()">Back to top</p>
    </div>
  </div>
</div>
