import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { OfferService } from '../../../services/offer.service';
import { PenaltyModel } from '../../../models/penalty.model';
import { TaskService } from '../../../services/task.service';

@Component({
  selector: 'app-cancel-task',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.scss']
})

export class PenaltyComponent implements OnInit {
  penalty: PenaltyModel;
  penaltyChanges: PenaltyModel;
  ppUserId;
  originalTask;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private offerService: OfferService) {
  }

  ngOnInit() {
    this.penalty = new PenaltyModel();
    this.penaltyChanges = {...this.penalty };
    this.fetchPenaltyDataSource();
  }

  fetchPenaltyDataSource() {
    this.route.params.subscribe( params => {
      this.taskService.getPenalty(params.id).subscribe(
        res => {
          this.penalty = res;
          this.penaltyChanges = { ...this.penalty };
          this.fetchOfferAndTaskDetail(this.penalty.offerId);
          this.getPreviousTaskById(res.cancelTaskId);
          },
        err => { this.taskService.handleError(err, 'Error fetching Cancel single task'); }
      );
    });
  }

  fetchOfferAndTaskDetail(offerId: number) {
    this.offerService.getOfferById(offerId).subscribe(
      res => {
        this.penalty.chargeId = res.stripeChargeId;
        this.ppUserId = res.userId;
      }
    );
  }

  getPreviousTaskById(taskId: number) {
    this.taskService.getTaskById(taskId).subscribe(
      res => {
        this.originalTask = res;
      }
    );
  }

  isBtnDisabled(): boolean {
    for (const property in this.penalty) {
      if (this.penalty[property] !== this.penaltyChanges[property]) {
        return false;
      }
    }
    return true;
  }

  goToUser(userId) {
    this.router.navigate([`/users/${userId}`]);
  }

  removeUnchangesProperties() {
    for (const property in this.penalty) {
      if (this.penalty[property] === this.penaltyChanges[property]) {
        delete this.penalty[property];
      } else {
        this.penaltyChanges[property] = this.penalty[property];
      }
    }
  }

  saveChanges() {
    this.removeUnchangesProperties();
      this.taskService.editPenalty(this.penaltyChanges.id, this.penaltyChanges).subscribe(
        res => {
          if (res) {
            this.toastr.success(`Penalty ${res.id} modified`);
          }
        },
        err => { this.taskService.handleError(err, 'Can´t save CancelTask'); }
      );
  }
}
