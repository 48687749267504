import { Component } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';
import { AuthService } from 'src/app/services/auth.service';
import { Task } from '../../models/task.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from 'src/app/components/dialogs/questionDialog/questionDialog.component';
import { StateService } from 'src/app/services/state.service';
import { Router } from '@angular/router';
import { StoredCreditsService } from 'src/app/services/storedCredits.service';
import { OfferService } from 'src/app/services/offer.service';
import { Title } from '@angular/platform-browser';
import { OnInit } from '@angular/core';
@Component({
  selector: 'app-compromise-calculator-page',
  templateUrl: './compromiseCalculator.component.html',
  styleUrls: ['./compromiseCalculator.component.scss'],
})
export class CompromiseCalculatorComponent implements OnInit {
  task: Task;
  taskOwner: User;
  handyhander: User;
  enteredValue: number;
  submitBtnActivated: boolean = false;
  totalPrice: number;
  constructor(
    private title: Title,
    private offerService: OfferService,
    private storedCreditsService: StoredCreditsService,
    private router: Router,
    private matDialog: MatDialog,
    private stateService: StateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private taskService: TaskService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.setMetaData();
  }

  setMetaData(): void {
    this.title.setTitle('Compromise calculator');
  }

  onBlur() {
    if (this.enteredValue < 205) {
      this.enteredValue = 205;
    } else if (this.enteredValue > this.totalPrice) {
      this.enteredValue = this.totalPrice;
    }
    if (this.enteredValue !== undefined) {
      this.submitBtnActivated = true;
    }
  }

  confirmCompromise() {
    const dialog = this.matDialog.open(QuestionDialogComponent, {
      width: '500px',
      data: {
        title: `Change task: ${this.task.id}'s price from: ${this.totalPrice}.00 kr. to ${this.enteredValue}.00 kr.?`,
        description: `Are you sure? This will change the price on the offer, and refund ${
          this.totalPrice - this.enteredValue
        } to the taskowner!`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res === 'yes') {
        this.stateService.toggleWaitingPage(true);
        this.taskService
          .resolveCompromise(this.task.id, this.enteredValue)
          .subscribe(
            (res) => {
              if (res) {
                this.toastr.success('Compromise resolved and price changed!');
                this.stateService.toggleWaitingPage(false);
                this.router.navigate(['/tasks/', this.task.id]);
              }
            },
            () => {
              this.toastr.error(
                'Something went wrong, please try again later.',
              );
              this.stateService.toggleWaitingPage(false);
            },
          );
      }
    });
  }

  fetchTask(taskId: number) {
    //
    this.taskService.getTaskById(taskId).subscribe(
      (task: Task) => {
        this.task = task;
        this.taskOwner = this.task.user;
        this.totalPrice = this.task.acceptedOffer.priceBid;
        if (this.task.status == 'completed') {
          this.toastr.error('This task has already been completed.');
          this.resetTask();
          return;
        }
        if (this.task.acceptedOffer) {
          this.userService
            .getUserProfile(this.task.acceptedOffer.userId)
            .subscribe((res) => {
              this.handyhander = res;
            });
          this.offerService
            .getPriceIncOffer(this.task.acceptedOffer.id)
            .subscribe((res) => {
              res.forEach((element) => {
                if (element.status == 'accepted') {
                  this.totalPrice += element.priceBid;
                }
              });
            });
          this.storedCreditsService
            .getPriceReduction(this.task.acceptedOffer.id)
            .subscribe((res) => {
              if (res) {
                this.resetTask();
                this.toastr.error(
                  `This task has already been reduced once, and can't be reduced again.`,
                );
              }
            });
        } else {
          // throw error, does not have accepted offer
          this.toastr.error("Task doesn't have an accepted offer.");
          this.resetTask();
        }
      },
      () => {
        this.resetTask();
        this.toastr.error('Task not found.');
      },
    );
  }

  resetTask() {
    this.task = null;
    this.taskOwner = null;
    this.handyhander = null;
  }
}
