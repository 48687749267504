import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Offer } from 'src/app/models/offer.model';
import { Review } from 'src/app/models/review.model';

@Component({
  selector: 'app-reported-review-card',
  templateUrl: './reported-review-card.component.html',
  styleUrls: ['./reported-review-card.component.scss', '../cards.scss']
})
export class ReportedReviewCardComponent implements OnInit {

  @Input() title = "Review";
  @Input() review: Review;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }
}
