import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NotificationScheduled } from "src/app/models/notificationSchedule";
import { NotificationSegment } from "src/app/models/notificationSegment";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-segment-scheduled-list",
  templateUrl: "notification-segment-scheduled-list.component.html",
  styleUrls: ["notification-segment-scheduled-list.component.scss"],
})
export class NotificationSegmentScheduledListComponent implements OnInit {
  notifications: NotificationScheduled[] = [];
  displayNotifications: NotificationScheduled[] = [];

  hasMore: boolean = true;
  isBusy: boolean = false;
  lastPage: number = 0;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  updateDisplayNotification(notifications: NotificationScheduled[]): void {
    this.displayNotifications = notifications;
  }

  fetchMoreNotifications(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.notificationService
        .getScheduledNotifications(this.lastPage + 1)
        .subscribe(
          (res) => {
            this.notifications = this.notifications.concat(res.notifications);
            this.hasMore = res.hasMore;
            this.lastPage += 1;
          },
          (err) =>
            this.toastr.error(err.message, "Failed to get notifications!"),
          () => (this.isBusy = false)
        );
    }
  }
  navigate(notification: NotificationSegment): void {
    this.router.navigate([
      "/notification-segments",
      "scheduled",
      notification.id,
    ]);
  }
}
