import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewChecked,
} from '@angular/core';
import { Router } from '@angular/router';
import { Review } from 'src/app/models/review.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { StateService } from 'src/app/services/state.service';

const reviewPropsToCompare = [
  'id',
  'rating',
  'message',
  'communication',
  'efficiency',
  'eyeForDetail',
  'punctuality',
];

@Component({
  selector: 'app-user-review-card',
  templateUrl: './user-review-card.component.html',
  styleUrls: ['./user-review-card.component.scss', '../cards.scss'],
})
export class UserReviewCardComponent implements OnInit, AfterViewChecked {
  @ViewChild('reviewMessage') reviewMessage: ElementRef;
  @Input() review: Review;

  tempReview: Review;
  focusChanged: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.tempReview = { ...this.review };
  }

  ngAfterViewChecked(): void {
    if (!this.areObjectsEqual(this.review, this.tempReview)) {
      if (!this.focusChanged) {
        const x = window.scrollX;
        const y = window.scrollY;
        this.reviewMessage.nativeElement.focus();
        window.scrollTo(x, y);
        this.focusChanged = true;
      }
    }
  }

  areObjectsEqual(obj1: any, obj2: any): boolean {
    for (let prop of reviewPropsToCompare) {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }
    return true;
  }

  updateReview() {
    this.stateService.toggleWaitingPage(true);
    this.userService.updateReview(this.tempReview).subscribe({
      next: (data) => {
        this.toastr.success('Review updated successfully');
        this.review = { ...this.tempReview };
        this.focusChanged = false;
        this.stateService.toggleWaitingPage(false);
      },
      error: (error) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error('Error updating review');
      },
    });
  }

  deleteReview() {}

  resetReview() {
    this.tempReview = { ...this.review };
  }

  changeStarRating(type: string, rating: number) {
    let displayType: string;
    this.toastr.clear();
    switch (type) {
      case 'rating':
        this.tempReview.rating = rating;
        displayType = 'Rating';
        break;
      case 'communication':
        this.tempReview.communication = rating;
        displayType = 'Communication';
        break;
      case 'efficiency':
        this.tempReview.efficiency = rating;
        displayType = 'Efficiency';
        break;
      case 'eyeForDetail':
        this.tempReview.eyeForDetail = rating;
        displayType = 'Eye for Detail';
        break;
      case 'punctuality':
        this.tempReview.punctuality = rating;
        displayType = 'Punctuality';
        break;
    }
    this.toastr.success(displayType + ' changed to ' + rating);
  }
}
