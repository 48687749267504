import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ReportService } from '../../services/report.service';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { Report } from 'src/app/models/report.model';
import { User } from 'src/app/models/user.model';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-list',
  templateUrl: './reportList.component.html',
  styleUrls: ['./reportList.component.scss'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  reports: Report[] = [];
  removedReports: Report[] = [];
  removedReportsRequested: boolean = false;
  activeReports: Report[] = [];
  activeReportsRequested: boolean = false;
  hasMore: boolean = true;
  updatedActiveReports: Report[] = [];
  updatedRemovedReports: Report[] = [];
  users: User[] = [];
  pagination: number = 0;
  filterKeys: string[] = [
    'id',
    'reporterId',
    'targetId',
    'message',
    'reporter',
    'target',
    'itemId',
  ];

  private totalSubscription: Subscription;
  private readSubscription: Subscription;

  constructor(
    private title: Title,
    private authService: AuthService,
    private reportService: ReportService,
    private userService: UserService,
    private stateService: StateService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.fetchReports();
    this.setMetaTitle();
    this.subscribeToSocketEvents();
  }

  fetchReports(): void {
    if (!this.activeReportsRequested) {
      this.activeReportsRequested = true;
      this.reportService.getActiveReports().subscribe(
        (res) => {
          this.activeReports = res;
        },
        (err) => {
          this.activeReportsRequested = false;
          this.authService.handleError(err, 'Failed to get open reports!');
        },
      );
    }

    if (!this.removedReportsRequested) {
      this.removedReportsRequested = true;
      this.reportService.getRemovedReports().subscribe(
        (res) => {
          this.removedReports = res;
        },
        (err) => {
          this.removedReportsRequested = false;
          this.authService.handleError(err, 'Failed to get closed reports!');
        },
      );
    }

    this.hasMore =
      !this.activeReportsRequested || !this.removedReportsRequested;
  }

  setMetaTitle() {
    this.title.setTitle(`Reports`);
  }

  subscribeToSocketEvents(): void {
    this.readSubscription =
      this.stateService.readActiveReportsComponentSource.subscribe(
        (changedReports) => {
          if (changedReports.length) {
            const targetReports = this.activeReports.filter((x) =>
              changedReports.includes(x.id),
            );
            targetReports.forEach((report) => {
              report.status = 'removed';
            });
            this.activeReports = this.activeReports.filter(
              (x) => !changedReports.includes(x.id),
            );
            this.updateActiveDisplayReports(this.activeReports);
            if (changedReports.ownEvent) return;
            this.toastr.info(
              `Reports [${changedReports.join(
                ', ',
              )}] has been closed by an admin`,
            );
          } else if (!changedReports.ids) {
            const targetReport = this.activeReports.filter(
              (x) => x.id === changedReports.id,
            )[0];
            targetReport.status = 'removed';
            this.activeReports = this.activeReports.filter(
              (x) => x.id !== changedReports.id,
            );
            this.updateActiveDisplayReports(this.activeReports);
            if (changedReports.ownEvent) return;
            this.toastr.info(
              `Report ${targetReport.id} has been closed by an admin`,
            );
          }
        },
      );

    this.totalSubscription =
      this.stateService.totalActiveReportsComponentSource.subscribe((event) => {
        if (event.report && event.report.id) {
          const report = this.removedReports.find(
            (x) => x.id === event.id || x.id === event.report.id,
          );
          this.activeReports.push(report);
          this.updateActiveDisplayReports(this.activeReports);
          this.toastr.info(`Report [${report.id}] has been re-activated`);
        }
      });
  }

  updateActiveDisplayReports(reports: Report[]): void {
    this.updatedActiveReports = reports;
    const reporterUserIds = reports.map((report) => report.reporterId);
    const targetUserIds = reports.map((report) => report.targetId);
    const userIds: number[] = Array.from(
      new Set(reporterUserIds.concat(targetUserIds)),
    );
    if (this.users.length > 0) {
      this.userService.getMultipleUsers(userIds).subscribe(
        (res) => (this.users = res),
        (err) => this.authService.handleError(err, 'Failed to get users!'),
      );
    }
  }
  updateRemovedDisplayReports(reports: Report[]): void {
    this.updatedRemovedReports = reports;
    const reporterUserIds = reports.map((report) => report.reporterId);
    const targetUserIds = reports.map((report) => report.targetId);
    const userIds: number[] = Array.from(
      new Set(reporterUserIds.concat(targetUserIds)),
    );
    if (this.users.length > 0) {
      this.userService.getMultipleUsers(userIds).subscribe(
        (res) => (this.users = res),
        (err) => this.authService.handleError(err, 'Failed to get users!'),
      );
    }
  }

  getUserName(userId: number): string {
    if (this.users.length > 0) {
      const user = this.users?.find((user) => user.id === userId);
      return user ? `${user.firstName} ${user.lastName}` : 'Loading...';
    }
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), 'dd-MM-yyyy kk:mm:ss');
  }

  ngOnDestroy(): void {
    this.readSubscription.unsubscribe();
    this.totalSubscription.unsubscribe();
    this.title.setTitle(`Admin Dashboard`);
  }
}
