<div class="calculator_page_container">
  <div class="header">
    <h1>Compromise Calculator</h1>
    <p>- Find a task to do compromise for</p>
    <p>- Type in the new price given on offer</p>
  </div>

  <div class="body">
    <!-- Create a mat input field that can only take numbers that does "function" on enter -->
    <mat-form-field style="width: 100% !important; min-width: 100%">
      <input
        matInput
        #input1
        placeholder="Enter task id"
        (keyup.enter)="fetchTask(input1.value)"
      />
    </mat-form-field>
    <div
      *ngIf="this.task && this.task.status == 'pending'"
      class="response"
    >
      <!-- Title and information about the task in-->
      <div class="task_title">
        <app-task-card [task]="task"></app-task-card>
      </div>

      <mat-divider></mat-divider>
      <div class="compromise_area">
        <!-- Information about the task owner included in the compromise-->
        <div class="task_owner_side">
          <app-user-card
            *ngIf="taskOwner"
            [title]="'Task Owner'"
            [user]="taskOwner"
          ></app-user-card>
        </div>

        <mat-divider
          style="margin-right: 15px"
          [vertical]="true"
        ></mat-divider>

        <!-- Information about the Handyhander included in the compromise-->
        <div class="handyhander_side">
          <app-user-card
            *ngIf="handyhander"
            [title]="'Handyhander'"
            [user]="handyhander"
          ></app-user-card>
        </div>
      </div>
    </div>
    <mat-divider
      *ngIf="this.task && this.task.status == 'pending'"
    ></mat-divider>
    <div *ngIf="this.task && this.task.status == 'pending'">
      <span
        *ngIf="this.totalPrice - this.task.acceptedOffer.priceBid != 0"
        style="text-align: center"
      >
        <p>Original price: {{ this.task.acceptedOffer.priceBid }}</p>
        <p>
          Offer Price Increase:
          {{ this.totalPrice - this.task.acceptedOffer.priceBid }}
        </p>
        <p>Real price: {{ this.totalPrice }}</p>
      </span>
    </div>
    <div
      *ngIf="this.task && this.task.status == 'pending'"
      class="submit_area"
    >
      <mat-form-field>
        <mat-label
          >Enter a new price for the task between 205 and
          {{ totalPrice }}:</mat-label
        >
        <input
          matInput
          type="number"
          name="numberInput"
          min="205"
          max="{{ totalPrice }}"
          [(ngModel)]="enteredValue"
          (blur)="onBlur()"
          (keyup.enter)="confirmCompromise()"
        />
      </mat-form-field>
      <button
        class="pinploy_button"
        [disabled]="!submitBtnActivated"
        [ngClass]="{ disabled: !submitBtnActivated }"
        (click)="confirmCompromise()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
