import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {

    constructor(
      private router: Router,
      private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.authService.isAuthenticated()) {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
    }
}

@Injectable()
export class PreventLoginGuard  {

  constructor(private router: Router,
              private authService: AuthService) {}


  canActivate() {
    if (!this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/users']);
    return false;
  }
}
