<mat-card>
    <div class="card_body">
      <!-- Task title-->
      <div class="card_row">
        <span class="strong">Task title:</span>
        <span>{{storedCreditEntry?.offer.task.title}}</span>
      </div>
      <mat-divider [inset]="false" style="margin-bottom: 15%;"></mat-divider>

      <!-- Task ID -->
      <div class="card_row" style="padding-top: 5%">
        <span class="strong">Task / Refund ID:</span>
        <span>{{storedCreditEntry?.offer.taskId}}</span>
      </div>

      <!-- Amount -->
      <div class="card_row">
        <span class="strong">Amount:</span>
        <span>{{storedCreditEntry?.remaining / 100}}.00 DKK</span>
      </div>



      <!-- Assigned Handyhander -->
      <div class="card_row">
        <span class="strong">Assigned Handyhander:</span>
        <span>{{storedCreditEntry.offer.user.firstName}}</span>
      </div>

      <!-- Type-->
      <div class="card_row">
        <span class="strong">Type:</span>
        <span *ngIf="storedCreditEntry.type == 'expiredTaskCredit'">Expired Task</span>
        <span *ngIf="storedCreditEntry.type == 'cancellationCredit'">Cancelled Task</span>
        <span *ngIf="storedCreditEntry.type == 'priceDecreaseCredit'">Price Reduction amount</span>
      </div>
      
      <!-- Charge Id -->
      <div class="card_row">
        <span class="strong">Charge Id:</span>
        <span>{{storedCreditEntry.chargeId}}</span>
      </div>

      <!-- is it a offer price increase?-->
      <div class="card_row" *ngIf="storedCreditEntry.opiId">
        <span class="strong">Price Increase Id:</span>
        <span>{{storedCreditEntry.opiId}}</span>
      </div>
    </div>
</mat-card>