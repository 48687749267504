import { Component, Input, OnInit } from '@angular/core';
import { StoredCredit } from 'src/app/models/storedCredit.model';

@Component({
  selector: 'app-storedCredits-card',
  templateUrl: './storedCredits-card.component.html',
  styleUrls: ['./storedCredits-card.component.scss', '../cards.scss']
})
export class StoredCreditsCard implements OnInit {
    
  @Input() storedCreditEntry: StoredCredit & { remaining: number };

  constructor (
  ) { }

  ngOnInit() {
  }

  
}
