import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { TagService } from 'src/app/services/tag.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-request-handling-page',
  templateUrl: './requestHandlingPage.component.html',
  styleUrls: ['./requestHandlingPage.component.scss'],
})
export class RequestHandlingPageComponent implements OnInit, OnDestroy {
  requests: any[] = [];
  shownRequests: any[] = [];
  allTags: any[] = [];
  allTagsWithAssociatedTagCat;
  removingTag = false;

  // For the filter
  types = [
    { slug: 'all', name: 'All' },
    { slug: 'task-tagging-failed', name: 'Task tagging failed' },
    { slug: 'reject-automatic-censorring', name: 'Censoring rejection' },
    {
      slug: 'user-mentioned-mobile-pay-in-message',
      name: 'Mobile pay in message',
    },
    { slug: 'pending-company-approval', name: 'CVR Approval' },
  ];
  selectedType: string = '';

  private sub: Subscription;
  constructor(
    private toastr: ToastrService,
    private title: Title,
    private stateService: StateService,
    private adminActionRequestService: AdminActionRequestService,
    private tagService: TagService,
  ) {}

  ngOnInit() {
    this.stateService.toggleWaitingPage(true);
    this.setMetaTitle();
    this.getUnhandledRequests();
    this.populateAllTagsFromDb();
    this.getAllTagsAssociatedToTagCat();
    this.readActiveRequests();
  }

  filterRequests(event: Event) {
    if (this.selectedType === 'all') {
      this.shownRequests = this.requests;
      return;
    }
    this.shownRequests = this.requests.filter(
      (r) => r.entityType === this.selectedType,
    );
  }

  getUnhandledRequests(page = 1) {
    this.adminActionRequestService.getUnhandledRequests(page).subscribe(
      (res) => {
        if (res.length !== 0) {
          this.requests = res;
          this.shownRequests = this.requests;

          this.stateService.toggleWaitingPage(false);
        } else {
          this.stateService.toggleWaitingPage(false);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  // Tag fetching and sorting area
  populateAllTagsFromDb() {
    this.tagService.getAllTags().subscribe((res) => {
      if (res) {
        this.allTags = res;
      }
    });
  }

  readActiveRequests() {
    this.sub =
      this.stateService.readActiveAdminActionRequestsComponentSource.subscribe(
        (res) => {
          if (res) {
            this.requests = this.requests.filter((r) => r.id !== res.id);
            this.toastr.success('Request handled by another admin', 'Success');
          }
        },
        (err) => {
          console.log(err);
        },
      );
  }

  getAllTagsAssociatedToTagCat() {
    this.tagService.getTagsAssociatedByTagCategory().subscribe((res) => {
      if (res) {
        this.allTagsWithAssociatedTagCat = res;
        this.allTagsWithAssociatedTagCat.andet.tags =
          this.allTagsWithAssociatedTagCat.andet.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.digital.tags =
          this.allTagsWithAssociatedTagCat.digital.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.transport.tags =
          this.allTagsWithAssociatedTagCat.transport.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.hus.tags =
          this.allTagsWithAssociatedTagCat.hus.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.have.tags =
          this.allTagsWithAssociatedTagCat.have.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
      }
    });
  }
  setMetaTitle() {
    this.title.setTitle(`Requests`);
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.title.setTitle(`Admin Dashboard`);
  }

  requestHandled(request: Event) {
    const index = this.requests.indexOf(request);
    if (this.selectedType === 'all') {
      if (index !== -1) {
        this.requests.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        this.requests.splice(index, 1);
      }
      this.shownRequests = this.requests.filter(
        (r) => r.entityType === this.selectedType,
      );
      if (this.shownRequests.length === 0) {
        this.selectedType = 'all';
        this.getUnhandledRequests();
      }
    }

    if (this.requests.length === 0) {
      this.stateService.toggleWaitingPage(true);
      this.selectedType = 'all';
      this.getUnhandledRequests();
    }
  }
}
