import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { logClass } from "./logger.decorator";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ConflictCase } from "../models/conflictCase.model";
import { map } from "rxjs/operators";

@logClass()
@Injectable()
export class ConflictService {
  baseHref = `${environment.apiUrl}/api/admin/conflicts`;

  constructor(private http: HttpClient) {}

  startConflictCase(taskId: number): Observable<any> {
    if (taskId) {
      const url = `${this.baseHref}/${taskId}`;
      return this.http.post(url, {});
    }
  }

  findConflictCaseByTaskId(taskId: number): Observable<ConflictCase> {
    if (taskId) {
      const url = `${this.baseHref}/by-task-id/${taskId}`;
      return this.http.get<ConflictCase>(url).pipe(
        map((res) => {
          res.createdAt = new Date(res.createdAt);
          res.deadline = new Date(res.deadline);
          res.updatedAt = new Date(res.updatedAt);
          return res;
        })
      );
    }
  }

  getConflictCase(id: number) {
    const url = `${this.baseHref}/${id}`;
    return this.http.get<ConflictCase>(url);
  }

  getAllConflictCases(page: number) {
    const url = `${this.baseHref}/`;
    return this.http.get<{ data: ConflictCase[]; hasMore: boolean }>(url, {
      params: { page },
    });
  }

  setThirdPartyComment(caseId: number, comment: string): Observable<any> {
    const url = `${this.baseHref}/set-third-party-comment`;
    return this.http.post(url, {
      caseId: caseId,
      comment: comment || "",
    });
  }

  setAdminComment(caseId: number, comment: string): Observable<any> {
    const url = `${this.baseHref}/set-admin-comment`;
    return this.http.post(url, {
      caseId: caseId,
      comment: comment || "",
    });
  }

  setToHHpercentage(caseId: number, percentage: number): Observable<any> {
    const url = `${this.baseHref}/set-hh-percentage`;
    return this.http.post(url, {
      caseId: caseId,
      percentage: percentage || 0,
    });
  }

  setRefundAmount(
    caseId: number,
    amountTO: number,
    amountHH: number
  ): Observable<any> {
    const url = `${this.baseHref}/set-refund-amount`;
    return this.http.post(url, {
      caseId: caseId,
      amountTO: amountTO,
      amountHH: amountHH,
    });
  }

  setDeadline(caseId: number, deadline: Date): Observable<any> {
    const url = `${this.baseHref}/set-deadline`;
    if (deadline) {
      return this.http.post(url, {
        caseId: caseId,
        deadline: deadline,
      });
    }
  }

  closeConflictCase(caseId: number): Observable<ConflictCase> {
    const url = `${this.baseHref}/close-conflict-case/${caseId}`;
    return this.http.post<ConflictCase>(url, {
      caseId: caseId,
    });
  }

  cancelConflictCase(caseId: number): Observable<any> {
    const url = `${this.baseHref}/cancel-conflict-case/${caseId}`;
    return this.http.post(url, {
      caseId: caseId,
    });
  }

  reopenConflictCase(caseId: number): Observable<any> {
    const url = `${this.baseHref}/reopen-conflict-case/${caseId}`;
    return this.http.post(url, {
      caseId: caseId,
    });
  }

  createConflictCaseEvidence(accessId: number, userId: number){
    const url = `${this.baseHref}/create-conflict-case-evidence`;
    return this.http.post(url, {
      accessId,
      userId,
    });
  }
}
