import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Offer } from 'src/app/models/offer.model';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss', '../cards.scss']
})
export class OfferCardComponent implements OnInit {

  @Input() title = "Offer";
  @Input() offer: Offer;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goToOfferDetails(id: number) {
    this.router.navigate(['/offers', id]);
  }

}
