import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class PaymentService {

  baseHref = `${environment.apiUrl}/api/payments`;

  constructor(
    private http: HttpClient) { }

  updateAccountCapabilities(stripeAccountId: string): Observable<any> {
    const url = `${this.baseHref}/update-connect-capabilities/${stripeAccountId}`;
    return this.http.get<any>(url);
  }
}

