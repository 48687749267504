import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Offer } from "src/app/models/offer.model";
import { Task } from "src/app/models/task.model";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";
import { OfferService } from "src/app/services/offer.service";

@Component({
    selector: 'user-active-offers-dialog',
    templateUrl: 'userActiveOffers.component.html',
    styleUrls:['../dialogs.scss', 'userActiveOffers.component.scss']
})

export class UserActiveOffersDialogComponent implements OnInit {
    
    
    constructor(
        public userService: UserService,
        public offerService: OfferService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<UserActiveOffersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any){
            if (data.userId === undefined){
                throw new Error('No user id passed to user active offers dialog')
            }
    }

    userData: User;
    userActiveOffers = [];
    userAcceptedOffers = [];
    userCompletedOffers = [];
    userCancelledOffers = [];

    ngOnInit(): void{
        this.fetchUserData();
        this.userActiveOffers = this.data.userActiveOffers;
        this.userAcceptedOffers = this.data.userAcceptedOffers;
        this.userCompletedOffers = this.data.userCompletedOffers;
        this.userCancelledOffers = this.data.userCancelledOffers;
    }

    closeDialogEvent() {
        this.close();
    }

    fetchUserData(): void {
        this.userService.getUserProfile(this.data.userId)
        .subscribe(result => this.userData = result);
    }

    close(res: Task = null): void{
        this.dialogRef.close(res);
    }
    
    
}