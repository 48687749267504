<!-- Header -->
<div *ngIf="showComponent()">
  <app-header></app-header>
</div>

<!-- Pages -->
<div class="container">
  <div *ngIf="showComponent()">
    <app-navbar></app-navbar>
  </div>

  <div
    class="page_container"
    [ngClass]="{ 'full-screen': !showComponent() }"
  >
    <router-outlet></router-outlet>
  </div>
</div>

<app-waiting></app-waiting>
