import {
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { OfferService } from '../../../services/offer.service';
import { CommentService } from '../../../services/comment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'censor-entry-dialog',
  templateUrl: 'censorEntryDialog.component.html',
  styleUrls: ['../dialogs.scss', 'censorEntryDialog.component.scss'],
})
export class CensorEntryDialogComponent implements OnInit {
  public entryBeingEdited: any;
  public text: string;
  public censorChecked: boolean;
  public fetchError: any;
  public submitError: any;
  public sendCensorEmailChecked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CensorEntryDialogComponent>,
    public offerService: OfferService,
    public commentService: CommentService,
    public toastr: ToastrService,
    public cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (!data.type || data.id === undefined) {
      throw new Error('No type and/or id passed to censor entry dialog.');
    }
  }

  ngOnInit(): void {
    this.fetchEntry();
  }

  fetchEntry(): void {
    switch (this.data.type) {
      case 'offer':
        this.offerService.getOfferById(this.data.id).subscribe(
          (res: any) => {
            this.entryBeingEdited = res;
            this.text = res.message;
            this.censorChecked = !!res.censored;
          },
          (err) => {
            this.fetchError = err.message;
          },
        );
        break;
      case 'offer-comment':
        this.commentService.getOfferCommentById(this.data.id).subscribe(
          (res: any) => {
            this.entryBeingEdited = res;
            this.text = res.text;
            this.censorChecked = !!res.censored;
          },
          (err) => {
            this.fetchError = err.message;
          },
        );
        break;
      case 'task-comment':
        this.commentService.getTaskCommentById(this.data.id).subscribe(
          (res: any) => {
            this.entryBeingEdited = res;
            this.text = res.text;
            this.censorChecked = !!res.censored;
          },
          (err) => {
            this.fetchError = err.message;
          },
        );
        break;
      default:
        throw new Error('Unknown entry type');
    }
  }

  showData() {
    return JSON.stringify({ data: this.data, entry: this.entryBeingEdited });
  }
  updateSendCensorEmail() {
    if (!this.censorChecked) {
      this.sendCensorEmailChecked = false;
    }
  }

  submit() {
    let data: any;
    switch (this.data.type) {
      case 'offer':
        data = {};
        data.sendEmail = this.sendCensorEmailChecked;
        if (this.entryBeingEdited.message !== this.text) {
          data.message = this.text;
        }
        if (
          (!this.entryBeingEdited.censored && this.censorChecked) ||
          (this.entryBeingEdited.censored && !this.censorChecked)
        ) {
          data.censored = this.censorChecked ? new Date() : null;
          if (data.censored) {
            this.toastr.success('Offer has been censored');
          } else {
            this.toastr.success('Offer has been uncensored');
          }
        }
        if (data.message === undefined && data.censored === undefined) {
          console.log('No new data. Aborting.');
          return;
        }
        data.offerId = this.data.id;
        this.offerService.realEditOffer(this.data.id, data).subscribe(
          () => this.close(data),
          (err) => {
            this.submitError = err.error;
            this.toastr.error(err.error);
          },
        );
        break;
      case 'offer-comment':
        data = {};
        data.sendEmail = this.sendCensorEmailChecked;
        if (this.entryBeingEdited.text !== this.text) {
          data.text = this.text;
        }
        if (
          (!this.entryBeingEdited.censored && this.censorChecked) ||
          (this.entryBeingEdited.censored && !this.censorChecked)
        ) {
          data.censored = this.censorChecked ? new Date() : null;
          if (data.censored) {
            this.toastr.success('Offer comment censored successfully');
          } else {
            this.toastr.success('Offer comment uncensored successfully');
          }
        }
        if (data.text === undefined && data.censored === undefined) {
          console.log('No new data. Aborting.');
          return;
        }
        this.commentService.editOfferComment(this.data.id, data).subscribe(
          () => this.close(data),
          (err) => {
            this.submitError = err.error;
            this.toastr.error(err.error);
          },
        );
        break;
      case 'task-comment':
        data = {};
        data.sendEmail = this.sendCensorEmailChecked;
        if (this.entryBeingEdited.text !== this.text) {
          data.text = this.text;
        }
        if (
          (!this.entryBeingEdited.censored && this.censorChecked) ||
          (this.entryBeingEdited.censored && !this.censorChecked)
        ) {
          data.censored = this.censorChecked ? new Date() : null;
          if (data.censored) {
            this.toastr.success('Comment censored successfully');
          } else {
            this.toastr.success('Comment uncensored successfully');
          }
        }
        if (data.text === undefined && data.censored === undefined) {
          console.log('No new data. Aborting.');
          return;
        }
        if (!data.censored) {
          data.censored = null;
        }
        this.commentService.editTaskComment(this.data.id, data).subscribe(
          () => {
            this.close(data);
          },
          (err) => {
            this.submitError = err.error;
            this.toastr.error(err.error);
          },
        );
        break;
      default:
        throw new Error('Unknown entity type');
    }
  }

  reset(): void {
    this.text =
      this.data.type === 'offer'
        ? this.entryBeingEdited.message
        : this.entryBeingEdited.text;
    this.censorChecked = !!this.entryBeingEdited.censored;
    this.sendCensorEmailChecked =
      !!this.entryBeingEdited.sendCensorEmailChecked;
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }
}
