<mat-card>
  <div class="card_header">
    <h5>{{ title }}</h5>
    <button
      class="pinploy_button small_button"
      (click)="openJSONview()"
    >
      Open JSON
    </button>
  </div>
  <div class="card_body">
    <!-- COMPANY NAME-->
    <div class="card_row">
      <p class="strong">Company name</p>
      <p>{{ company.name }}</p>
    </div>

    <!-- COMPANY CVR-->
    <div class="card_row">
      <p class="strong">CVR</p>
      <p>{{ company.vat }}</p>
    </div>

    <!-- COMPANY LOCATION-->
    <div class="card_row">
      <p class="strong">Registered location</p>
      <p>{{ company.city }} ({{ company.zipcode }})</p>
    </div>

    <!-- COMPANY PHONE-->
    <div class="card_row">
      <p class="strong">Registered phone</p>
      <p>{{ company.phone }}</p>
    </div>
  </div>
</mat-card>
