<div class="search-container">
  <!-- Search -->
  <div id="controls">
    <mat-form-field>
      <input
        matInput
        #eventNameInput
        placeholder="Event Name"
        [(ngModel)]="eventName"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        #userIdInput
        type="number"
        placeholder="User ID"
        [(ngModel)]="userId"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        #taskIdInput
        type="number"
        placeholder="Task ID"
        [(ngModel)]="taskId"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        #offerIdInput
        type="number"
        placeholder="Offer ID"
        [(ngModel)]="offerId"
      />
    </mat-form-field>
    <button mat-button (click)="applyFilters()">Go</button>
  </div>

  <!-- Results -->
  <paginated-list
    [data]="data"
    (displayDataChanged)="updateDisplayData($event)"
    (dataRequested)="fetchData()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
  >
    <div id="list">
      <div
        class="event"
        *ngFor="let event of displayData; let i = index"
        (click)="onClick(i)"
      >
        <p class="metadata">
          <span class="timestamp">{{ getTimestamp(event.createdAt) }}</span>
          <span>{{ event.eventName }}</span>
          <span>UserId: {{ event.userId }}</span>
          <span>TaskId: {{ event.taskId }}</span>
          <span>OfferId: {{ event.offerId }}</span>
        </p>
        <div class="data hidden">
          <p>{{ event.id }}: {{ event.eventId }}</p>
          <pre>{{ event.data | json }}</pre>
        </div>
      </div>
    </div>
  </paginated-list>
</div>
