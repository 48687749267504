import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { BucketDefinition } from "../models/BucketDefinition.model";
import { BucketNotification } from "../models/BucketNotification.model";
import { BucketNotificationSchedule } from "../models/BucketNotificationSchedule.model";
import { EmailTemplate } from "../models/EmailTemplate.model";

type DefinitionListResponse = {
  bucketDefinitions: BucketDefinition[];
  hasMore: boolean;
};

type ScheduleListResponse = {
  bucketNotificationSchedules: BucketNotificationSchedule[];
  hasMore: boolean;
};

type BucketNotificationSave = {
  language: string;
  description: string;
  periodicity: number;
  hour: number;
  repeat: number;
  dynamicData: string[];
  emailWanted: boolean;
  pushWanted: boolean;
  smsWanted: boolean;
  webWanted: boolean;
  emailContext: {
    template: string | EmailTemplate;
    en: any;
    dk: any;
  };
  webContext: {
    en: any;
    dk: any;
    otherParams: string;
  };
  pushContext: {
    en: any;
    dk: any;
    pushData: string;
  };
  smsContext: {
    en: any;
    dk: any;
  };
};

@Injectable()
export class BucketNotificationService {
  private readonly rootUrl = `${environment.apiUrl}/api/admin/bucket-notifications`;
  private readonly definitionUrl = `${this.rootUrl}/bucket-definitions`;
  private readonly notificationUrl = `${this.rootUrl}/bucket-notifications`;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  listBucketDefinitions(page: number): Observable<DefinitionListResponse> {
    const url = this.definitionUrl;
    return this.http.get<DefinitionListResponse>(url, { params: { page } });
  }

  getBucketNotification(
    definitionId: number,
    id: number
  ): Observable<BucketNotification> {
    const url = `${this.definitionUrl}/${definitionId}/${id}`;
    return this.http.get<BucketNotification>(url).pipe(
      map((notif) => {
        notif.language ??= "userSettings";
        return notif;
      })
    );
  }

  getBucketDefinition(id: number): Observable<BucketDefinition> {
    const url = `${this.definitionUrl}/${id}`;
    return this.http.get<BucketDefinition>(url);
  }

  deleteBucketDefinition(id: number): Observable<any> {
    const url = `${this.definitionUrl}/${id}`;
    return this.http.delete(url);
  }

  createOrUpdateBucketDefinition(
    bucketDefinition: BucketDefinition,
    isNew: boolean
  ): Observable<BucketDefinition> {
    if (!bucketDefinition.name?.trim()) {
      this.toastr.error("Bucket definition name is required");
      return;
    }

    if (!bucketDefinition.type) {
      this.toastr.error("Bucket definition type is required");
      return;
    }

    if (!bucketDefinition.bucketFunction) {
      this.toastr.error("Bucket definition function name is required");
      return;
    }
    const body = {
      name: bucketDefinition.name,
      type: bucketDefinition.type,
      bucketFunction: bucketDefinition.bucketFunction,
      permissionGroup: bucketDefinition.permissionGroup ?? null,
      isActive: bucketDefinition.isActive ?? null,
      launchDate: bucketDefinition.launchDate ?? null,
      endDate: bucketDefinition.endDate ?? null,
      repeat: bucketDefinition.repeat ?? null,
    };
    if (isNew) {
      return this.createBucketDefinition(body);
    }
    return this.updateBucketDefinition(body, bucketDefinition.id);
  }

  private createBucketDefinition(body: any): Observable<BucketDefinition> {
    const url = `${this.definitionUrl}`;

    return this.http.post<BucketDefinition>(url, body);
  }

  private updateBucketDefinition(
    body: any,
    defId: number
  ): Observable<BucketDefinition> {
    const url = `${this.definitionUrl}/${defId}`;

    return this.http.put<BucketDefinition>(url, body);
  }

  createOrUpdateBucketNotification(
    values: BucketNotificationSave,
    isNew: boolean,
    defId: number,
    notifId?: number
  ): Observable<BucketNotification> {
    if (
      !values.emailWanted &&
      !values.webWanted &&
      !values.pushWanted &&
      !values.smsWanted
    ) {
      return throwError("Select at least one notification channel 🙂");
    }

    values.dynamicData = [];

    const context: any = {};
    if (values.emailWanted) {
      const template = values.emailContext?.template as EmailTemplate;
      if (!template) {
        return throwError("Email template is required");
      }
      const dynamicData = template.fields
        .filter((f) => f.isDynamic)
        .map((f) => f.label);
      context.contextEmail = {
        ...values.emailContext,
        template: template.templatePath,
      };
      values.dynamicData = dynamicData;
    }

    if (values.webWanted) {
      if (!values.webContext) {
        return throwError("Web context is required");
      }
      const otherParams = values.webContext.otherParams?.trim()
        ? JSON.parse(values.webContext.otherParams)
        : {};
      context.contextWeb = values.webContext;
      if (context.contextWeb.dk) {
        context.contextWeb.dk = { ...context.contextWeb.dk, ...otherParams };
      }
      if (context.contextWeb.en) {
        context.contextWeb.en = { ...context.contextWeb.en, ...otherParams };
      }
      delete context.contextWeb.otherParams;
    }

    if (values.pushWanted) {
      if (!values.pushContext) {
        return throwError("Push context is required");
      }
      const pushData = values.pushContext.pushData?.trim()
        ? JSON.parse(values.pushContext.pushData)
        : {};
      context.contextPush = values.pushContext;
      if (context.contextPush.dk) {
        context.contextPush.dk = { ...context.contextPush.dk, pushData };
      }
      if (context.contextPush.en) {
        context.contextPush.en = { ...context.contextPush.en, pushData };
      }
      delete context.contextPush.pushData;
    }

    if (values.smsWanted) {
      if (!values.smsContext) {
        return throwError("SMS context is required");
      }
      context.contextSms = values.smsContext;
    }

    const body = {
      language: values.language === "userSettings" ? null : values.language,
      description: values.description,
      dynamicData: values.dynamicData,
      periodicity: values.periodicity,
      hour: values.hour,
      emailWanted: values.emailWanted,
      pushWanted: values.pushWanted,
      smsWanted: values.smsWanted,
      webWanted: values.webWanted,
      repeat: values.repeat,
      context,
    };

    if (isNew) {
      return this.createBucketNotification(defId, body);
    }
    return this.updateBucketNotification(notifId, body);
  }

  private createBucketNotification(
    defId: number,
    body: any
  ): Observable<BucketNotification> {
    const url = `${this.notificationUrl}/${defId}`;
    return this.http.post<BucketNotification>(url, body);
  }

  private updateBucketNotification(
    id: number,
    body: any
  ): Observable<BucketNotification> {
    const url = `${this.notificationUrl}/${id}`;
    return this.http.put<BucketNotification>(url, body);
  }

  listNotificationSchedule(
    definitionId: number,
    id: number,
    page: number
  ): Observable<ScheduleListResponse> {
    const url = `${this.definitionUrl}/${definitionId}/${id}/schedules`;
    return this.http.get<ScheduleListResponse>(url, { params: { page } });
  }

  deleteNotification(id: number): Observable<any> {
    const url = `${this.notificationUrl}/${id}`;
    return this.http.delete(url);
  }

  listBucketFunctions(type: string): Observable<string[]> {
    const url = `${this.rootUrl}/list-functions/${type}`;
    return this.http.get<string[]>(url);
  }

  countUsersForCampaign(campaignName: string) {
    const url = `${this.rootUrl}/count-users/${campaignName}`;
    return this.http.get<number>(url);
  }

  launchCampaign(id: number): Observable<any> {
    const url = `${this.definitionUrl}/${id}/launch`;
    return this.http.get(url);
  }
}
