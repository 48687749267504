import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { TaskService } from '../../../services/task.service';
import { CancelTaskAutomation } from '../../../models/cancelTaskAutomation';

@Component({
  selector: 'app-cancel-task',
  templateUrl: './cancelTaskAutomation.component.html',
  styleUrls: ['./cancelTaskAutomation.component.scss']
})

export class CancelTaskAutomationComponent implements OnInit {
  cancelTaskAuto: CancelTaskAutomation;
  cancelTaskAutoChanges: CancelTaskAutomation;
  ppUserId;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    this.cancelTaskAuto = new CancelTaskAutomation();
    this.cancelTaskAutoChanges = {...this.cancelTaskAuto};
    this.fetchcancelTaskAutoDataSource();
  }

  fetchcancelTaskAutoDataSource() {
    this.route.params.subscribe(params => {
      this.taskService.getCancelTaskAuto(params.id).subscribe(
        res => {
          this.cancelTaskAuto = res;
          this.cancelTaskAutoChanges = {...this.cancelTaskAuto};
        },
        err => {
          this.taskService.handleError(err, 'Error fetching Cancel single task');
        }
      );
    });
  }


  isBtnDisabledStatus(): boolean {
    return this.cancelTaskAuto.status !== 'manual';
  }


  isBtnDisabled(): boolean {
    for (const property in this.cancelTaskAuto) {
      if (this.cancelTaskAuto[property] !== this.cancelTaskAutoChanges[property]) {
        return false;
      }
    }
    return true;
  }

  goToPinployee() {
    this.router.navigate([`/users/${this.ppUserId}`]);
  }

  gotToHandleCanelTask(cancelTaskId: number) {
    this.router.navigate([`/cancel-task/${cancelTaskId}`]);
  }

  removeUnchangesProperties() {
    for (const property in this.cancelTaskAuto) {
      if (this.cancelTaskAuto[property] === this.cancelTaskAutoChanges[property]) {
        delete this.cancelTaskAuto[property];
      } else {
        this.cancelTaskAutoChanges[property] = this.cancelTaskAuto[property];
      }
    }
  }

  saveChanges() {
    this.removeUnchangesProperties();
      this.taskService.editCancelTaskAuto(this.cancelTaskAutoChanges.id, this.cancelTaskAutoChanges).subscribe(
        res => {
          if (res) {
            this.toastr.success(`Updated Automated Cancel Task ${res.id}`);
          }
        },
        err => {
          this.taskService.handleError(err, 'Can´t save cancelTaskAuto');
        }
      );
  }

}
