<ng-container *ngIf="nbUsers$ | async as nbUsers; else loadingSpinner">
  <ng-container *ngIf="displayedUsers$ | async; else loadingSpinner">
    <div class="confirm_notification_dialog">
      <h1>Confirm</h1>
      <p>
        You are about to send a notification to the segment
        <span> "{{ formValues.segment }}"</span>. This represents
        <span> {{ nbUsers }} </span> users.
      </p>
      <p>
        Wanted notification: <span>{{ getWantedString() }}</span>
      </p>
      <p>
        Schedule time: <span>{{ getScheduleTime() }}</span>
      </p>
      <paginated-list
        [data]="users"
        (displayDataChanged)="updateDisplayedUsers($event)"
        (dataRequested)="fetchUsers()"
        [moreDataAvailable]="hasMoreUsers()"
        [isDefaultSort]="false"
      >
        <div class="userList">
          <div *ngFor="let u of displayedUsers">
            <div>{{ u.firstName }} {{ u.lastName }}</div>
            <div>{{ u.email }}</div>
          </div>
        </div>
      </paginated-list>

      <div class="question">
        Are you sure you want to send this notification?
      </div>
      <div class="actions">
        <button
          class="pinploy_button small_button red_button"
          (click)="onClickCancel()"
        >
          Cancel
        </button>
        <button class="pinploy_button small_button" (click)="onClickSend()">
          Send
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingSpinner>
  <div class="loading_spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
