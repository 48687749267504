import { Offer } from './offer.model';

export class CancelTask {
  id: number;
  reason: string;
  adminComment: string;
  taskId: number;
  taskOwnerId: number;
  offerId: number;
  userId: number;
  canceler: string;
  refundType: string;
  status: string;
  customFee: number;
  ppConsent: boolean;
  taskTitle: string;
  chargeId: string;
  priceBid: number;
  ppUserId?: number;
  offer?: Offer;
  reasonComment: string;

  createdAt: string;
  updatedAt: string;

  constructor(data: any = null) {
    Object.assign(this, data);
  }
}
