import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../environments/environment";
import { logClass } from "./logger.decorator";

type AtLeastOne<T, U extends keyof T = keyof T> = {
  [K in U]-?: Required<Pick<T, K>> & Partial<Omit<T, K>>;
}[U];

interface SystemEventsQuery {
  eventName: string;
  userId: number;
  taskId: number;
  offerId: number;
}

type RequestArguments =
  | AtLeastOne<SystemEventsQuery>
  | {
      page?: number;
    };

@logClass()
@Injectable()
export class SystemEventsService {
  private baseUrl = `${environment.apiUrl}/api/system-events`;

  constructor(private http: HttpClient) {}

  getSystemEvents(query: RequestArguments) {
    let url = `${this.baseUrl}/`;

    const params = {};

    ["eventName", "userId", "taskId", "offerId", "page"].forEach((param) => {
      if (query[param]) {
        params[param] = query[param];
      }
    });

    return this.http.get<{ events: any[]; hasMore: boolean }>(url, { params });
  }
}
