import {
  Component,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { BanService } from '../../../services/ban.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { isBefore, format } from 'date-fns';
import { combineLatest } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ban-user-dialog',
  templateUrl: 'banUserDialog.component.html',
  styleUrls: ['../dialogs.scss', 'banUserDialog.component.scss'],
})
export class BanUserDialogComponent implements AfterViewInit, OnInit {
  public userData: User;
  public banHistory: any = [];
  public banDate = new UntypedFormControl(null);
  public banReason: string;
  public banError: string | undefined = undefined;
  public adminNotes: string;
  public otherBanReasonSelected = false;

  constructor(
    public dialogRef: MatDialogRef<BanUserDialogComponent>,
    private banService: BanService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to ban user dialog.');
    }
  }

  ngOnInit(): void {
    switch (this.data.type) {
      case 'offer':
        this.adminNotes = `Offer: ${this.data.typeId}`;
        break;
      case 'offer_comment':
        this.adminNotes = `Offer comment: ${this.data.typeId}`;
        break;
      case 'task_comment':
        this.adminNotes = `Task comment: ${this.data.typeId}`;
        break;
      default:
        this.adminNotes = '';
    }
  }

  ngAfterViewInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    combineLatest([
      this.userService.getUserProfile(this.data.userId),
      this.banService.getBansForUser(this.data.userId),
    ]).subscribe(
      (result) => {
        this.userData = result[0];
        this.banHistory = result[1]
          .map((b) => ({
            ...b,
            isActive:
              !b.cancelled && isBefore(new Date(), new Date(b.bannedUntil)),
          }))
          .sort((a, b) => b.id - a.id);
      },
      (err) => console.error(err),
      () => this.cdRef.detectChanges(),
    );
  }

  getActiveBan(): any {
    return this.banHistory.find(
      (entry: any) =>
        !entry.cancelled && isBefore(new Date(), new Date(entry.bannedUntil)),
    );
  }

  getDate(timestamp: string) {
    return format(new Date(timestamp), 'dd-MM-yyyy');
  }

  onReasonSelectionChange(reason: any) {
    if (reason.value === 'true') {
      this.otherBanReasonSelected = true;
      this.banReason = null;
      const elem = document.getElementById('banReasonInput');
      if (elem) {
        const options = { focusVisible: true } as FocusOptions;
        elem.focus(options);
      }
    } else {
      this.banReason = reason.value;
    }
  }

  resetBanReason() {
    this.banReason = null;
    this.otherBanReasonSelected = false;
  }

  banUser() {
    // Check if a ban date has been selected
    const banDateSelected =
      this.banDate.value && !isBefore(this.banDate.value, new Date());
    if (!banDateSelected) {
      this.banError = 'The ban date must be in the future';
    } else {
      this.banError = undefined;
      // Call the banUser() method of the ban service
      this.banService
        .banUser(
          this.userData.id,
          this.banDate.value,
          this.banReason,
          this.adminNotes,
        )
        .subscribe(
          (res) => {
            this.banDate = new UntypedFormControl(null);
            this.banReason = undefined;
            this.fetchData();
            this.toastr.success('User has been banned');
          },
          (err) => {
            this.banError = err;
            this.toastr.error('Failed banning user');
          },
        );
    }
  }

  cancelBan() {
    const ban = this.getActiveBan();
    this.banService.cancelBan(ban.id).subscribe((_) => this.fetchData());
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
    this.adminNotes = '';
  }
}
