import {
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { TagService } from 'src/app/services/tag.service';
import { TaskService } from 'src/app/services/task.service';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Tag } from 'src/app/models/tag.model';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ENTER, COMMA, C } from '@angular/cdk/keycodes';
import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';

interface TreeNode {
  name: string;
  items?: TreeNode[];
  expandable?: boolean;
  expanded?: boolean;
  level?: number;
}

@Component({
  selector: 'app-request-tagging-failed',
  templateUrl: './requestTaggingFailed.component.html',
  styleUrls: [
    '../requestHandlingPage.component.scss',
    './requestTaggingFailed.component.scss',
  ],
  animations: [
    trigger('fadeUpAnimation', [
      transition(':leave', [
        animate(
          '1000ms ease-out',
          keyframes([
            style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }), // Initial state
            style({ opacity: 1, transform: 'translateY(-2%)', offset: 0.4 }), // Start slow
            style({ opacity: 0, transform: 'translateY(-5%)', offset: 1 }), // Dissapear
          ]),
        ),
      ]),
    ]),
  ],
})
export class RequestTaggingFailedComponent implements OnInit {
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() allTags: any[] = [];
  @Input() allTagsWithAssociatedTagCat: any[] = [];
  @Input() task: any;
  @Input() request: any;

  @Output() tagAddedEvent = new EventEmitter<any>();

  tagAdded: boolean = false;
  tagsSavedToDB: boolean = false;

  Tags: string[] = [];
  tempTagArr: any[] = [];
  filteredTags: Observable<Tag[]>;
  tagChanged = new UntypedFormControl();
  removable = true;

  isLoading: boolean = false;

  treeControl: FlatTreeControl<TreeNode>; // Replace 'any' with your actual data type
  treeFlattener: MatTreeFlattener<TreeNode, TreeNode>; // Replace 'any' with your actual data type
  dataSource: MatTreeFlatDataSource<TreeNode, TreeNode>; // Replace 'any' with your actual data type
  treeData: any[] = [];

  requests: any[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  hasChild = (_: number, node: TreeNode) => node.expandable!;

  constructor(
    private toastr: ToastrService,
    private stateService: StateService,
    private tagService: TagService,
    private adminActionRequestService: AdminActionRequestService,
    private cdr: ChangeDetectorRef,
    private taskService: TaskService,
  ) {
    this.treeFlattener = new MatTreeFlattener(
      (node: TreeNode, level: number) => ({
        expandable: this.hasChild(level, node),
        name: node.name,
        items: node.items,
        level: level,
      }),
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.items,
    );
    this.treeControl = new FlatTreeControl<TreeNode>(
      (node) => node.level!,
      (node) => node.expandable!,
    );

    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener,
    );
  }

  ngOnInit() {
    this.filteredTags = this.tagChanged.valueChanges.pipe(
      map((tag: string) => (tag ? this._filter(tag) : this.allTags.slice())),
    );
    this.tagService.getTaskTagsFromTaskId(this.task.id).subscribe((result) => {
      this.tempTagArr = result.slice();
      for (let i = 0; i < result.length; i++) {
        this.Tags.push(result[i].tag);
      }
    });

    this.assignAllTagsAndAssociatedTagsToTree();
  }

  toggleNode(node: TreeNode, event: Event): void {
    node.expanded = !node.expanded;
  }

  private assignAllTagsAndAssociatedTagsToTree() {
    if (this.allTagsWithAssociatedTagCat || this.treeData) {
      this.treeData = Object.keys(this.allTagsWithAssociatedTagCat).map(
        (category) => ({
          name: category,
          expandable:
            this.allTagsWithAssociatedTagCat[category].tags &&
            this.allTagsWithAssociatedTagCat[category].tags.length > 0,
          items: this.allTagsWithAssociatedTagCat[category].tags,
        }),
      );
      this.dataSource.data = this.treeData;
    }
  }

  transformer(node: any, level: number): any {
    return {
      expandable: !!node.items && node.items.length > 0,
      name: node.name,
      level: level,
      // Add other properties as needed
    };
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter((tagObject) =>
      tagObject.tag.toLowerCase().includes(filterValue),
    );
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedTag = this.allTags.filter(
      (x) => x.tag.toLowerCase() === event.option.viewValue.toLowerCase(),
    );
    this.tagChanged.setValue(null);
    if (this.Tags.includes(selectedTag[0].tag)) {
      this.tagInput.nativeElement.value = '';
      return;
    }
    this.Tags.push(selectedTag[0].tag);
    this.tagInput.nativeElement.value = '';
  }

  add(event: MatChipInputEvent): void {
    /*we will store the input and value in local variables.*/
    const input = event.input;
    const value = event.value;
    const newTag = this.allTags.filter(
      (x) => x.tag === value.trim().toLowerCase(),
    );
    if ((value || '').trim() && newTag.length > 0) {
      this.Tags.push(newTag[0].tag);
      input.value = null;
    }

    this.Tags = this.Tags.filter((x) =>
      this.allTags.filter((y) => y.tag.includes(x)),
    );

    // remove douplicates from this.Tags
    this.Tags = this.Tags.filter((v, i, a) => a.indexOf(v) === i);
    this.tagChanged.setValue(null);
  }

  remove(tag: string): void {
    const index = this.Tags.indexOf(tag);
    if (index >= 0) {
      this.Tags.splice(index, 1);
    }
  }

  addOrRemoveTag(tag: any) {
    const selectedTag = this.allTags.filter(
      (x) => x.tag.toLowerCase() === tag.tag.toLowerCase(),
    );
    if (this.Tags.includes(selectedTag[0].tag)) {
      return;
    }

    if (selectedTag) {
      this.Tags.push(selectedTag[0].tag);
    } else {
      this.toastr.error('There was a problem adding that Tag');
    }
  }

  instantNotificationsNewTags() {
    this.isLoading = true;
    this.taskService.instantNotificationsNewTags(this.task.id).subscribe({
      next: (res) => {
        this.tagAdded = true;
        this.adminActionRequestService
          .markRequestAsHandled(this.request)
          .subscribe();
        this.stateService.readActiveAdminActionRequestsComponentSource.next(null);
        this.toastr.success(`${res.totalUsersNotified} users notified!`);
        this.toastr.success(`Instant Notification Succesfull!`);

        this.isLoading = false;
        setTimeout(() => {
          this.tagAddedEvent.emit(this.request);
        }, 999);
      },
      error: (err) => {
        this.isLoading = false;
        this.toastr.error(`Instant Notification Failed!`, 'Error');
      },
    });
  }

  handleTagging() {
    this.isLoading = true;
    const tagsToSave = {
      tags: [],
      taskId: this.task.id,
    };

    // Reduce load on API - filter whole tag obj with id to send to backend.
    for (let j = 0; j < this.allTags.length; j += 1) {
      for (let i = 0; i < this.Tags.length; i++) {
        if (this.Tags[i] === this.allTags[j].tag) {
          tagsToSave.tags.push(this.allTags[j]);
        }
      }
    }
    this.tagService.updateTagFromTask(tagsToSave).subscribe({
      next: (res) => {
        this.tagsSavedToDB = true;

        this.isLoading = false;
        this.toastr.success('Tags updated ');
      },
      error: (err) => {
        this.isLoading = false;
        this.toastr.error('Tags failed to update');
      },
    });
  }

  dismiss() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.tagAdded = true;
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            res,
          );
          this.toastr.success(`Request Dismissed!`);
          this.isLoading = false;
          setTimeout(() => {
            this.tagAddedEvent.emit(this.request);
          }, 999);
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error(`Request Dismissed!`);
        },
      });
  }
}
