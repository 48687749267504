<div
  class="tab_content_container"
  *ngIf="contextForm.get('template') as choosedTemplate"
>
  <mat-form-field appearance="fill">
    <mat-label>Template</mat-label>
    <mat-select [formControl]="choosedTemplate" required>
      <mat-option *ngFor="let template of templates" [value]="template">
        {{ template.label }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="choosedTemplate.invalid">Template is required</mat-error>
  </mat-form-field>

  <div
    class="lang_container"
    [ngClass]="{ vertical: choosedLanguage === 'userSettings' }"
  >
    <!-- Display both language -->
    <ng-container *ngIf="choosedLanguage === 'userSettings'">
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
      ></ng-container>
      <mat-divider [vertical]="true"></mat-divider>
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
      ></ng-container>
    </ng-container>

    <!-- Force dk language -->
    <ng-container *ngIf="choosedLanguage === 'dk'">
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
      ></ng-container>
    </ng-container>

    <!-- Force en language -->
    <ng-container *ngIf="choosedLanguage === 'en'">
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
      ></ng-container>
    </ng-container>
  </div>

  <!-- Always display dynamics field -->
  <ng-container *ngIf="choosedTemplate.valid">
    <ng-container *ngIf="getDynamicsFields(choosedTemplate) as fields">
      <ng-container *ngIf="fields && fields.length > 0">
        <div class="dynamic_datas">
          <p>
            The list of variables contained in the HTML template and which will
            be automatically filled. These variables must be returned by the
            Bucket Function.
          </p>

          <ul>
            <li *ngFor="let field of fields">
              {{ field.label }}
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #completeForm let-lang="lang">
    <ng-container *ngIf="choosedTemplate.value">
      <div class="lang_container_item">
        <h2>{{ lang === "dk" ? "Danish" : "English" }}</h2>
        <ng-container *ngIf="contextForm.get(lang) as fg">
          <div class="form_container">
            <mat-form-field appearance="fill">
              <mat-label> Main text </mat-label>
              <textarea
                matInput
                [formControl]="fg.get('plainText')"
                rows="5"
                required
              ></textarea>
              <mat-error
                *ngIf="
                  fg.get('plainText').invalid &&
                  fg.get('plainText').hasError('required')
                "
              >
                Main text is required
              </mat-error>
            </mat-form-field>

            <div
              *ngFor="let field of choosedTemplate.value?.fields"
              class="email_form_item"
            >
              <ng-container *ngIf="fg.get(field.label) as inputForm">
                <mat-form-field appearance="fill">
                  <mat-label> {{ field.label }} </mat-label>
                  <input
                    *ngIf="['text', 'url'].includes(field.type)"
                    matInput
                    [formControl]="inputForm"
                    [type]="field.type"
                    required
                  />
                  <textarea
                    *ngIf="field.type === 'textarea'"
                    matInput
                    [formControl]="inputForm"
                    rows="5"
                    required
                  ></textarea>
                  <mat-error
                    *ngIf="inputForm.invalid && inputForm.hasError('required')"
                  >
                    {{ field.label }} is required
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</div>
