import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmNotificationDialogComponent } from 'src/app/components/dialogs/confirmNotificationDialog/confirm-notification-dialog.component';
import { InformationDialogComponent } from 'src/app/components/dialogs/informationDialog/informationDialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-new-notification-segment',
  templateUrl: 'new-notification-segment.component.html',
  styleUrls: ['new-notification-segment.component.scss'],
})
export class NewNotificationSegmentComponent implements OnInit {
  segments$: Observable<string[]>;
  templates$: Observable<any[]>;

  form: UntypedFormGroup;

  isEmailWanted = false;
  isPushWanted = false;
  isWebWanted = false;
  isSmsWanted = false;
  selectedIndex: number;

  constructor(
    private stateService: StateService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit() {
    this.stateService.toggleWaitingPage(true);
    this.selectedIndex = 0;

    this.segments$ = this.notificationService.getSegmentNames().pipe(
      tap(() => {
        this.stateService.toggleWaitingPage(false);
      }),
    );
    this.templates$ = this.notificationService.getTemplates();
    this.initForm();
  }

  private initForm() {
    this.form = this.notificationService.getFormGroup();
  }

  onChangeWanted(type: string) {
    switch (type) {
      case 'email':
        if (this.isEmailWanted) {
          this.selectedIndex = 0;
        } else {
          this.goToSelectedOne(0);
        }
        break;
      case 'web':
        if (this.isWebWanted) {
          this.selectedIndex = 1;
        } else {
          this.goToSelectedOne(1);
        }
        break;
      case 'push':
        if (this.isPushWanted) {
          this.selectedIndex = 2;
        } else {
          this.goToSelectedOne(2);
        }
        break;
      case 'sms':
        if (this.isSmsWanted) {
          this.selectedIndex = 3;
        } else {
          this.goToSelectedOne(3);
        }
        break;
      default:
        console.log('Well done! You broke the code!');
        break;
    }
  }

  private goToSelectedOne(currentIndex: number) {
    if (currentIndex !== this.selectedIndex) return;

    if (this.isWebWanted) this.selectedIndex = 1;
    else if (this.isPushWanted) this.selectedIndex = 2;
    else if (this.isSmsWanted) this.selectedIndex = 3;
    else this.selectedIndex = 0;
  }

  tabsIsInvalid(...forms: AbstractControl[]) {
    return forms.some((form) => form?.touched && form?.invalid);
  }

  onClickSend() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.toastr.error('Please fill all the required fields!');
      return;
    }

    if (
      !this.isEmailWanted &&
      !this.isWebWanted &&
      !this.isPushWanted &&
      !this.isSmsWanted
    ) {
      this.toastr.error('Select at least one notification channel 🙂');
      return;
    }

    this.dialog
      .open(ConfirmNotificationDialogComponent, {
        minWidth: '80%',
        maxWidth: '80%',
        maxHeight: '80vh',
        minHeight: '80vh',
        data: {
          values: this.form.value,
          wanted: {
            email: this.isEmailWanted,
            web: this.isWebWanted,
            push: this.isPushWanted,
            sms: this.isSmsWanted,
          },
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.sendNotification();
        }
      });
  }

  private async sendNotification() {
    this.stateService.toggleWaitingPage(true);
    this.notificationService
      .sendNotification(this.form.value, {
        email: this.isEmailWanted,
        web: this.isWebWanted,
        push: this.isPushWanted,
        sms: this.isSmsWanted,
      })
      .subscribe(
        (res: any) => {
          this.stateService.toggleWaitingPage(false);
          this.displayInfoDialog(res);
        },
        (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(err.message);
        },
      );
  }

  private displayInfoDialog(notificationSegmentId: number) {
    let description: string[];
    if (notificationSegmentId === -1) {
      description = [
        'Your notification to a segment has been submitted. ',
        "It's a scheduled notification, you can check the status on table notification_schedule (or scheduled tabs if you prefer 🙃)",
      ];
    } else {
      description = [
        'Your notification to a segment has been submitted. ',
        `You can check the status of the process in the "Notification segments" page with id ${notificationSegmentId}`,
      ];
    }
    const ref = this.dialog.open(InformationDialogComponent, {
      width: '80%',
      data: {
        title: 'Submitted',
        description,
      },
    });
    ref.afterClosed().subscribe(() => {
      if (notificationSegmentId !== -1) {
        this.router.navigate(['/notification-segments', notificationSegmentId]);
        return;
      }
      this.router.navigate(['/notification-segments'], {
        queryParams: { tabs: 1 },
      });
    });
  }
}
