<div class="bans-container">
  <div id="controls">
    <mat-slide-toggle
      class="padding"
      [checked]="activeBansOnly"
      (change)="filterActiveBans($event.checked)"
    >
    </mat-slide-toggle>
    <span>{{ activeBansOnly ? "Active bans" : "All bans" }}</span>
  </div>

  <paginated-list
    [data]="filteredBans"
    (displayDataChanged)="updateDisplayBans($event)"
    (dataRequested)="fetchBans()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
  >
    <div class="list">
      <a
        class="item"
        *ngFor="let ban of displayBans"
        routerLink="{{ '/users/' + ban.userId }}"
      >
        <p class="id"><span class="bold">UserId: </span>{{ ban.userId }}</p>
        <p>
          <span class="bold">Banned on: </span
          >{{ convertTimestamp(ban.createdAt) }}
        </p>
        <p>
          <span class="bold">Banned until: </span
          >{{ convertTimestamp(ban.bannedUntil) }}
        </p>
        <p class="reason"><span class="bold">Reason: </span>{{ ban.reason }}</p>
        <p><span class="bold">Cancelled: </span>{{ isActive(ban) }}</p>
      </a>
    </div>
  </paginated-list>
</div>
