<mat-card>
  <div class="card_header">
    <h5>{{title}}</h5>
  </div>
  <div class="card_body">
    <div class="card_row">
      <p class="strong">Id</p>
      <p>{{review.id}}</p>
    </div>
    <div class="card_row">
      <p class="strong">From user</p>
      <p><a [routerLink]="['/users', review.fromUserId]">{{review.fromUser.firstName}} {{review.fromUser.lastName}}</a> </p>
    </div>
    <div class="card_row">
      <p class="strong">To user</p>
      <p><a [routerLink]="['/users', review.toUserId]">{{review.toUser.firstName}} {{review.toUser.lastName}}</a> </p>
    </div>
    <div class="card_row">
      <p class="strong">Rating</p>
      <p>{{review.rating}}</p>
    </div>
    <div class="card_row">
      <p class="strong">Text</p>
      <p>{{review.message}}</p>
    </div>
  </div>
</mat-card>