import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';
import { SocketService } from './socket.service';
@logClass()
@Injectable()
export class AdminActionRequestService {
  baseHrf = `${environment.apiUrl}/api/admin/admin-action-request`;

  constructor(private socketService: SocketService, private http: HttpClient) {}

  getUnhandledRequests(page = 1): Observable<any> {
    const url = `${this.baseHrf}/unhandled`;
    return this.http.get<any>(url, { params: { page } });
  }

  getUnhandledRequestsCount(): Observable<any> {
    const url = `${this.baseHrf}/count/unhandled`;
    return this.http.get<any>(url);
  }

  markRequestAsHandled(request: any) {
    const socketID = this.socketService.getSocketId();
    request.socketId = socketID;
    const url = `${this.baseHrf}/handle`;
    return this.http.post<any>(url, request);
  }
}
