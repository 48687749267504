import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Task } from "src/app/models/task.model";
import { User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";
import { ToastrService } from "ngx-toastr";


@Component({
    selector: 'user-header-image-dialog',
    templateUrl: 'userHeaderImage.component.html',
    styleUrls:['../dialogs.scss', 'userHeaderImage.component.scss']
})

export class UserHeaderImageDialogComponent implements OnInit {
    

    constructor(
        private toastr: ToastrService,
        private userService: UserService,
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<UserHeaderImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any){
            if (data.userId === undefined){
                throw new Error('No user id passed to user header image dialog')
            }
    }

    userData: User;
    userHeaderImage: any;

    ngOnInit(): void{
        this.fetchUserData();     
        this.fetchUserHeaderImage();
    }

    closeDialogEvent() {
        this.close();
    }

    fetchUserData() {
        this.userService.getUserProfile(this.data.userId)
        .subscribe(result => this.userData = result);
    }
    
    fetchUserHeaderImage(){  
        this.userService.getMediaHeader(this.data.userId)
        .subscribe(result => {
            this.userHeaderImage = result[0];    
        });
    }

    close(res: Task = null): void{
        this.dialogRef.close(res);
    }

    deleteHeaderImage(){
        this.userService.deleteUserMediaImage('header', this.userHeaderImage.id).subscribe({
            next: () => {
                this.toastr.success('Image deleted');
                this.close();
            },
            error: (err) => console.error(err)
        });

    }

}