<mat-card>
  <div class="card_header">
    <h5>{{title}}</h5>
    <button class="pinploy_button small_button"
            (click)="goToOfferDetails(offer.id)">Open offer details</button>
  </div>
  <div class="card_body">
    <div class="card_row">
      <p class="strong">Id</p>
      <p>{{offer.id}}</p>
    </div>
    <div class="card_row">
      <p class="strong">Status</p>
      <p>{{offer.status}}</p>
    </div>
    <div class="card_row">
      <p class="strong">Proposed price</p>
      <p>{{offer.priceBid}}</p>
    </div>
    <div class="card_row">
      <p class="strong">Payed</p>
      <p>{{offer.isPaid ? 'Yes' : 'No'}}</p>
    </div>
  </div>
</mat-card>