import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';

@Component({
  selector: 'task-list-dialog',
  templateUrl: 'taskListDialog.component.html',
  styleUrls: ['../dialogs.scss', 'taskListDialog.component.scss']
})
export class TaskListDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TaskListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }
}
