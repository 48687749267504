import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-note-card',
  templateUrl: './user-note-card.component.html',
  styleUrls: ['./user-note-card.component.scss', '../../cards.scss']
})
export class UserNoteComponent implements OnInit {
  @Input() noteInput: any;
  note: any;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.note = this.noteInput;

  }

  deleteNote() {
    this.userService.deleteUesrNoteById(this.note.userId, this.note.id).subscribe(res => {
      this.note.deletedAt = new Date();
    }, err => {
      console.log(err);
    })
  }

  formatDate(date: string) {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  }

}
