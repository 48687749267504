import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-give-grace-dialog',
  templateUrl: './userGiveGraceDialog.component.html',
  styleUrls: ['../dialogs.scss', './userGiveGraceDialog.component.scss'],
})
export class UserGiveGraceDialogComponent implements OnInit {
  type: 'ban' | 'handyhander-limitations';
  userId: number;

  minDate = new Date();
  untilDate = new UntypedFormControl();
  constructor(
    public toastr: ToastrService,
    public userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserGiveGraceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user penalties dialog');
    } else {
      this.type = data.type;
      this.userId = data.userId;
    }
  }
  ngOnInit(): void {

  }

  close(response?): void {
    if (response) {
      this.dialogRef.close(response);
    } else {
      this.dialogRef.close();
    }
  }

  isGraceBtnDisabled() {
    if (this.untilDate.value) {
      return false;
    }
    return true;
  }

  giveGrace() {
    this.userService
      .giveGracePeriod(this.userId, this.type, this.untilDate.value)
      .subscribe(
        (res) => {
          this.toastr.success(
            'Grace period given untill ' + this.untilDate.value,
            'Success',
          );
          this.close('success');
        },
        (err) => {
          console.log(err);
        },
      );
  }
}
