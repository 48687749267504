import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Report } from '../models/report.model';
import { SupportTicket } from '../models/supportTicket.model';
import { AdminActionRequest } from '../models/adminActionRequest.model';
interface ReportRead {
  targetId: number;
  type: 'task_comment' | 'offer' | 'offer_comment' | 'task' | string;
}

@Injectable()
export class StateService {
  constructor() {}
  // Used to toggle a 'waiting state' that the platform is in
  toggleWaitingComponentSource = new Subject<boolean>();

  toggleWaitingPage(status: boolean) {
    this.toggleWaitingComponentSource.next(status);
  }

  //used for websockets for reports
  public totalActiveReportsComponentSource = new Subject<any>();
  public readActiveReportsComponentSource = new Subject<any>();

  public updateTotalActiveReports(report: Report) {
    this.totalActiveReportsComponentSource.next(report);
  }

  public readActiveReports(
    targetId: number,
    type: 'task_comment' | 'offer' | 'offer_comment' | 'task' | string,
  ) {
    this.readActiveReportsComponentSource.next({ targetId, type });
  }

  //used for websockets for support tickets
  public totalActiveSupportTicketsComponentSource = new Subject<any>();
  public readActiveSupportTicketsComponentSource = new Subject<any>();

  public updateTotalActiveSupportTickets(supportTicket: SupportTicket) {
    this.totalActiveSupportTicketsComponentSource.next(supportTicket);
  }

  public readActiveSupportTickets(supportTicket: SupportTicket) {
    this.readActiveSupportTicketsComponentSource.next(supportTicket);
  }

  //used for websockets for admin action requests
  public totalActiveAdminActionRequestsComponentSource =
    new Subject<AdminActionRequest>();

  public readActiveAdminActionRequestsComponentSource =
    new Subject<AdminActionRequest>();

  public updateTotalActiveAdminActionRequests(
    adminActionRequest: AdminActionRequest,
  ) {
    this.totalActiveAdminActionRequestsComponentSource.next(adminActionRequest);
  }

  public readActiveAdminActionRequests(adminActionRequest: AdminActionRequest) {
    this.readActiveAdminActionRequestsComponentSource.next(adminActionRequest);
  }
}
