import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { EmailTemplate } from "src/app/models/EmailTemplate.model";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-email-section",
  templateUrl: "notification-email-section.component.html",
  styleUrls: ["notification-email-section.component.scss"],
})
export class NotificationEmailSectionComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() templates: any[] = [];
  @Input() contextForm: UntypedFormGroup;
  @Input() choosedLanguage: string;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    const templateForm = this.contextForm.get("template");
    templateForm?.valueChanges.subscribe((value) => {
      if (templateForm.valid) {
        this.contextForm.markAsUntouched();
      }
      if (value) {
        this.notificationService.getFormGroupForTemplate(
          this.contextForm,
          value
        );
        this.computeLanguage();
      }
    });
  }

  ngOnChanges(change: any) {
    if ("choosedLanguage" in change) {
      this.computeLanguage();
    }

    if ("disabled" in change) {
      this.computeDisabled(change.disabled.currentValue);
    }
  }

  /**
   * Disable or enable both language fields then call computeLanguage() to enable/disable the choosed language field
   */
  private computeDisabled(disabled: boolean) {
    this.contextForm.markAsUntouched();
    if (disabled) {
      this.contextForm.disable();
    } else {
      this.contextForm.enable();
      this.computeLanguage();
    }
  }

  private computeLanguage() {
    if (this.disabled) {
      this.contextForm.disable();
      return;
    }
    if (
      !this.contextForm ||
      !("en" in this.contextForm.controls) ||
      !("dk" in this.contextForm.controls)
    )
      return;

    if (this.choosedLanguage === "en") {
      this.contextForm.get("en").enable();
      this.contextForm.get("dk").disable();
    } else if (this.choosedLanguage === "dk") {
      this.contextForm.get("en").disable();
      this.contextForm.get("dk").enable();
    } else {
      this.contextForm.get("en").enable();
      this.contextForm.get("dk").enable();
    }
  }

  getDynamicsFields(fg: UntypedFormControl) {
    const value: EmailTemplate = fg.value;
    if (!value) return [];
    return value.fields.filter((field) => field.isDynamic);
  }
}
