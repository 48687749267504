import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { NotificationLog } from "src/app/models/notificationLog.model";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-log-details",
  templateUrl: "./notificationLogDetails.component.html",
  styleUrls: ["./notificationLogDetails.component.scss"],
})
export class NotificationLogDetailsComponent implements OnInit {
  notificationId: number;
  notificationLog$: Observable<NotificationLog>;

  parseJson = JSON.parse;

  constructor(
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.notificationLog$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        const notificationId = params.get("id");
        if (!notificationId || isNaN(+notificationId)) {
          this.router.navigate(["/notification-logs"]);
          this.toastr.error("Invalid notification id!", "Error");
          return;
        }
        this.notificationId = +notificationId;
        return this.notificationService.getNotificationLog(+notificationId);
      }),
      catchError((err) => {
        this.router.navigate(["/notification-logs"]);
        this.toastr.error(err.message, "Error");
        return [];
      })
    );
  }

  copyToClipboard(data: any): void {
    navigator.clipboard.writeText(JSON.stringify(data)).then(
      () => this.toastr.success("Copied to clipboard!"),
      (err) => this.toastr.error(err.message, "Failed to copy to clipboard!")
    );
  }

  formatUserIds(userIds: string): string {
    return userIds.split(";").filter(Boolean).join(", ");
  }

  formatDate(date: Date): string {
    return new Date(date).toLocaleString();
  }
}
