<div class="page_container">
  <h1>Requests</h1>
  <h2 *ngIf="requests.length == 0">
    There are currently no unhandled requests!
  </h2>

  <mat-form-field>
    <mat-label>Filter requests</mat-label>
    <mat-select
    (selectionChange)="filterRequests($event)"
    [(value)]="selectedType"
    >
      @for (type of types; track type) {
      <mat-option [value]="type.slug">{{ type.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div class="scroll-wrapper">
    <div
      *ngFor="let request of shownRequests"
      class="request"
    >
      <!-- IF THE REQUEST IS A FAILED TAGGING-->
      <ng-container
        *ngIf="
          request?.entityType == 'task-tagging-failed' &&
          request &&
          allTags &&
          allTagsWithAssociatedTagCat
        "
      >
        <app-request-tagging-failed
          *ngIf="request?.task"
          [allTags]="allTags"
          [allTagsWithAssociatedTagCat]="allTagsWithAssociatedTagCat"
          [task]="request.task"
          [request]="request"
          (tagAddedEvent)="requestHandled($event)"
        ></app-request-tagging-failed>
      </ng-container>

      <!-- IF THE REQUEST IS A USER MENTIONING MOBILEPAY IN CONVERSATION-->
      <ng-container
        *ngIf="request.entityType == 'user-mentioned-mobile-pay-in-message'"
      >
        <app-request-user-mentioned-mobile-pay
          *ngIf="request?.task"
          [request]="request"
          (requestHandledEvent)="requestHandled($event)"
        ></app-request-user-mentioned-mobile-pay>
      </ng-container>

      <!-- IF THE REQUEST IS A USER REJECTING THEIR CENSORRING-->
      <ng-container *ngIf="request.entityType == 'reject-automatic-censoring'">
        <app-request-rejected-censorring
          *ngIf="request"
          [request]="request"
          (requestHandledEvent)="requestHandled($event)"
        >
        </app-request-rejected-censorring>
      </ng-container>

      <!-- IF THE REQUEST IS A USER REQUESTING CVR CHECK-->
      <ng-container *ngIf="request.entityType == 'pending-company-approval'">
        <app-request-CVR-check
          *ngIf="request"
          [request]="request"
          (requestHandledEvent)="requestHandled($event)"
        >
        </app-request-CVR-check>
      </ng-container>
    </div>
  </div>
</div>
