import { BucketNotification } from "./BucketNotification.model";

export class BucketDefinition {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  isActive: boolean;
  type: "event" | "campaign";
  permissionGroup: string;
  bucketFunction: string;

  isLaunched: boolean;
  bucketNotifications?: BucketNotification[];
  launchDate?: Date;
  endDate?: Date;
  repeat?: "daily" | "weekly" | "monthly" | "yearly";
}
