import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';

interface Template {
  name: 'DK: Kontaktoplysninger forbudt'
  subject: 'Brud på retningslinjer | Handyhand'
  text: (userData: any) => string
}

const templateData: Template[] = [
  {
    name: 'DK: Kontaktoplysninger forbudt',
    subject: 'Brud på retningslinjer | Handyhand',
    text: (userData: any) =>
`Hej ${userData.firstName},

Tak fordi du bruger Handyhand!

Det er imod vores retningslinjer af dele kontaktinformationer på siden inden et bud er accepteret.

Dette kan have konsekvenser for de kontaktede serviceudbydere og vi kan ikke leverer sikker betaling, support og forsikring på opgaven når et bud ikkke accepteres.

For at benytte dig af en serviceudbyder fra Handyhand bedes du venligst acceptere et bud.

Mangler du information kan du dele eller forspørge detaljer i kommentartråden.`
  },
];

function isFunction(property: any): property is Function {
  return typeof property === 'function'
}

@Component({
  selector: 'message-user-dialog',
  templateUrl: 'messageUserDialog.component.html',
  styleUrls: ['../dialogs.scss', 'messageUserDialog.component.scss']
})
export class MessageUserDialogComponent implements OnInit {

  public text: string = '';
  public subject: string = '';
  public templates = templateData;
  public userData: any;
  public emailSelected = true;
  public phoneSelected = false;
  public contentEdited = false;

  constructor(
    public dialogRef: MatDialogRef<MessageUserDialogComponent>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (!data.userId) {
        throw new Error('No userId passed to dialog!');
      }
  }

  ngOnInit(): void {
    this.fetchUserData();
  }

  fetchUserData(): void {
    this.userService.getUserProfile(this.data.userId)
      .subscribe(result => this.userData = result);
  }

  applyTemplate(index: number, data: any) {
    if (this.contentEdited && !confirm('Choosing a template will discard your current subject/message. Are you sure?')) {
      return;
    }
    this.text = templateData[index].text(this.userData);
    this.subject = templateData[index].subject;
    this.contentEdited = false;
  }

  handleSend() {
    if (this.emailSelected) {
      this.userService.sendSimpleEmail(
        this.data.userId,
        this.text,
        this.subject,
      ).subscribe(console.log, console.error);
    }
    if (this.phoneSelected) {
      this.userService.sendSms(
        this.data.userId,
        this.text,
      ).subscribe(console.log, console.error);
    }
  }

  setContentEdited() {
    this.contentEdited = true;
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }
}
