<div class="search-container">
  <!-- Search -->
  <mat-form-field>
    <input matInput #searchInput placeholder="Search ..." [(ngModel)]="searchString" (keyup)="onSearchInput($event)">
  </mat-form-field>

  <!-- Results -->
  <div class="results-container">

    <!-- Users -->
    <div class="card-container" *ngIf="results?.users">
      <h4>Users</h4>
      <a *ngFor="let user of results.users" routerLink="{{'/users/' + user.id}}">
        <mat-card>
          <mat-card-content>
            <p>Id: {{user.id}}</p>
            <p>Name: {{user.firstName}} {{user.lastName}}</p>
            <p>Email: {{user.email}}</p>
            <p>Phone: {{user.phone}}</p>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
    <!-- Tasks -->
    <div class="card-container" *ngIf="results?.tasks">
      <h4>Tasks</h4>
      <a *ngFor="let task of results.tasks" routerLink="{{'/tasks/' + task.id}}">
        <mat-card>
          <mat-card-content>
            <p>ID: {{task.id}} Status: {{task.status.toUpperCase()}} UserID: {{task.userId}}</p>
            <p class="title">{{task.title}}</p>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
    <!-- Offers -->
    <div class="card-container" *ngIf="results?.offers">
      <h4>Offers</h4>
      <a *ngFor="let offer of results.offers" routerLink="{{'/offers/' + offer.id}}">
        <mat-card>
          <mat-card-content>
            <p>ID: {{offer.id}} Status: {{offer.status.toUpperCase()}}</p>
            <p>UserID: {{offer.userId}} TaskID: {{offer.taskId}} PriceBid: {{offer.priceBid}}</p>
            <p class="comment">{{offer.message}}</p>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
    <!-- Offer comments -->
    <div class="card-container" *ngIf="results?.offerComments">
      <h4>Offer comments</h4>
      <a *ngFor="let comment of results.offerComments" routerLink="{{'/comments/offer-comm/' + comment.id}}">
        <mat-card>
          <mat-card-content>
            <p>ID: {{comment.id}} Status: {{comment.status.toUpperCase()}}</p>
            <p>UserID: {{comment.userId}} TaskID: {{comment.taskId}} OfferID: {{comment.offerId}}</p>
            <p class="comment">{{comment.text}}</p>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
    <!-- Task comments -->
    <div class="card-container" *ngIf="results?.taskComments">
      <h4>Task comments</h4>
      <a *ngFor="let comment of results.taskComments" routerLink="{{'/comments/task-comm/' + comment.id}}">
        <mat-card>
          <mat-card-content>
            <p>ID: {{comment.id}} Status: {{comment.status.toUpperCase()}}</p>
            <p>UserID: {{comment.userId}} TaskID: {{comment.taskId}}</p>
            <p class="comment">{{comment.text}}</p>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
  </div>
</div>
