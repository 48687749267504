<div class="dialog_container">
  <h2>Merge another user into this user.</h2>
  <i
    >This will transfer all entities from searched for user into this user.
    (Tasks, Offers, Reviews etc.)</i
  >
  <i>The Stripe customer of the receiver is maintained</i>

  <div class="dialog_header">
    <div class="search_area">
      <mat-form-field class="search_field">
        <input
          class="no-spinners"
          matInput
          type="number"
          placeholder="Enter user id"
          [(ngModel)]="searchInt"
          (keyup.enter)="searchForUser()"
        />
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        (click)="searchForUser()"
      >
        Search
      </button>
    </div>
  </div>

  <div class="dialog_body">
    <div class="dialog_left">
      <app-user-card
        *ngIf="user"
        [user]="user"
        [title]="'User to merge into'"
      ></app-user-card>
    </div>

    <div class="dialog_right">
      <app-user-card
        *ngIf="userToMerge"
        [user]="userToMerge"
        [title]="'User to merge'"
      ></app-user-card>
    </div>
  </div>

  <div class="dialog_footer">
    <button
      mat-raised-button
      class="pinploy_button red_button"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="pinploy_button"
      (click)="mergeUsers()"
    >
      Merge
    </button>
  </div>
</div>
