<div
  class="tab_content_container"
  [ngClass]="{ vertical: choosedLanguage === 'userSettings' }"
>
  <!-- Display both language -->
  <ng-container *ngIf="choosedLanguage === 'userSettings'">
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
    ></ng-container>
    <mat-divider [vertical]="true"></mat-divider>
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
    ></ng-container>
  </ng-container>

  <!-- Force dk language -->
  <ng-container *ngIf="choosedLanguage === 'dk'">
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
    ></ng-container>
  </ng-container>

  <!-- Force en language -->
  <ng-container *ngIf="choosedLanguage === 'en'">
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
    ></ng-container>
  </ng-container>
  <ng-template #completeForm let-lang="lang">
    <div class="lang_container">
      <h2>{{ lang === "dk" ? "Danish" : "English" }}</h2>

      <div class="form_container" *ngIf="contextForm.get(lang) as fg">
        <!-- Message -->
        <ng-container *ngIf="fg.get('message') as inputForm">
          <mat-form-field appearance="fill">
            <mat-label> Message </mat-label>
            <input matInput [formControl]="inputForm" type="text" required />
            <mat-error
              *ngIf="inputForm.invalid && inputForm.hasError('required')"
            >
              Message is required
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
