import { Component, Inject, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/app/services/state.service';
@Component({
  selector: 'app-user-profile-merger',
  templateUrl: './user-profile-merger.component.html',
  styleUrls: ['../dialogs.scss', './user-profile-merger.component.scss'],
})
export class UserProfileMergerComponent implements OnInit {
  constructor(
    private stateService: StateService,
    private toastr: ToastrService,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserProfileMergerComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.user === undefined) {
      throw new Error('No user id passed to user header image dialog');
    }
  }
  searchInt: number;
  user: User;
  userToMerge: User;

  ngOnInit(): void {
    this.user = this.data.user;
  }

  searchForUser() {
    this.stateService.toggleWaitingPage(true);
    this.userService.getUserProfile(this.searchInt).subscribe(
      (user) => {
        if (!user || user === null) {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(`No user found with id ${this.searchInt}`);
          this.searchInt = null;
          this.userToMerge = null;
        } else if (user.id === this.user.id) {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error('You cannot merge a user with itself');
          this.searchInt = null;
          this.userToMerge = null;
        } else {
          this.userToMerge = user;
          this.stateService.toggleWaitingPage(false);
        }
      },
      (error) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error(`No user found with id ${this.searchInt}`);
        this.searchInt = null;
        this.userToMerge = null;
      },
    );
  }

  mergeUsers() {
    this.stateService.toggleWaitingPage(true);
    this.userService.mergeUsers(this.userToMerge.id, this.user.id).subscribe(
      (data) => {
        this.toastr.success('Users merged successfully');
        this.dialogRef.close('success');
      },
      (error) => {
        this.toastr.error('Failed to merge users');
        this.stateService.toggleWaitingPage(false);
      },
    );
  }

  closeDialog() {
    this.dialogRef.close('cancel');
  }
}
