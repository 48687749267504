import { Offer } from "./offer.model";
import { Task } from "./task.model";
import { User } from "./user.model";

export class OfferComment {
    id: number;
    status: 'active'|'removed';
    text: string;
    images?: string[];

    taskId: number;
    task: Task;

    offerId: number;
    offer: Offer;

    userId: number;
    user: User;

    createdAt: string;
    updatedAt: string;

    censored: Date;

    constructor(data: any){
        Object.assign(this, data);
        if(this.task == null) this.task = new Task();
        if(this.offer == null) this.offer = new Offer();
        if(this.user == null) this.user = new User();
    }
}
