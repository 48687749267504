<div class="tasks-container">
  <div id="controls"></div>

  <paginated-list
    [data]="tasks"
    (displayDataChanged)="updateDisplayTasks($event)"
    (dataRequested)="fetchTasks()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
  >
    <div class="list">
      <a
        class="item"
        *ngFor="let task of displayTasks"
        routerLink="{{ '/tasks/' + task.id }}"
      >
        <p class="id"><span class="bold">ID: </span>{{ task.id }}</p>
        <p class="title"><span class="bold">Title: </span>{{ task.title }}</p>
        <p class="status">
          <span class="bold">Status: </span>{{ task.status }}
        </p>
        <p class="budget">
          <span class="bold">Budget: </span>{{ task.budget }}
        </p>
        <p class="name">
          <span class="bold">Name: </span>
          {{ task.user.firstName }} {{ task.user.lastName }}
        </p>
      </a>
    </div>
  </paginated-list>
</div>
