import { Component, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Coupon } from "../../models/coupon.model";

import { CouponUsesComponent } from "./coupon-uses/couponUses.component";
import { OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { CouponService } from "../../services/coupon.service";

@Component({
  selector: "app-coupon-list",
  templateUrl: "./couponList.component.html",
  styleUrls: ["./common.scss", "./couponList.component.scss"],
})
export class CopuonListComponent implements OnInit{
  coupons: Coupon[] = [];
  displayCoupons: Coupon[] = [];
  filterKeys: string[] = ["id", "name", "code", "comment"];
  lastPage: number = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;
  selectedCoupon: Coupon | null = null;

  @ViewChild(CouponUsesComponent) couponUsesComponent: CouponUsesComponent;

  constructor(
    private title: Title,
    private authService: AuthService,
    private couponService: CouponService
  ) {}

  ngOnInit(): void {
      this.setMetaData();
  }

  fetchMoreCoupons(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.couponService.getAllCoupons(this.lastPage + 1).subscribe(
        (res) => {
          this.coupons = this.coupons.concat(res.coupons);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
        },
        (err) => this.authService.handleError(err, "Failed to get coupons!"),
        () => (this.isBusy = false)
      );
    }
  }

  setMetaData(): void {
    this.title.setTitle("Coupons");
  }
  updateDisplayCoupons(coupons: Coupon[]): void {
    this.displayCoupons = coupons;
  }

  getType(coupon: Coupon): string {
    return coupon.type === "percentage"
      ? `${coupon.value}%`
      : `${coupon.value / 100} kr.`;
  }

  showDetails(coupon: Coupon): void {
    this.selectedCoupon = coupon;
    this.couponUsesComponent.resetCouponUses();
    document.getElementById("coupons-container").style.display = "none";
    document.getElementById("details-container").style.display = "flex";
    document.getElementById("edit-container").style.display = "none";
  }

  hideDetails(): void {
    this.selectedCoupon = null;
    document.getElementById("coupons-container").style.display = "flex";
    document.getElementById("details-container").style.display = "none";
    document.getElementById("edit-container").style.display = "none";
  }

  showEdit() {
    document.getElementById("coupons-container").style.display = "none";
    document.getElementById("details-container").style.display = "none";
    document.getElementById("edit-container").style.display = "flex";
  }

  hideEdit() {
    document.getElementById("coupons-container").style.display =
      this.selectedCoupon === null ? "flex" : "none";
    document.getElementById("details-container").style.display =
      this.selectedCoupon === null ? "none" : "flex";
    document.getElementById("edit-container").style.display = "none";
  }

  showUses(): void {
    if (this.couponUsesComponent.lastPage === 0) {
      this.couponUsesComponent.fetchMoreCouponUses();
    }
    document.getElementById("details-container").style.display = "none";
    document.getElementById("uses-container").style.display = "flex";
  }

  hideUses(): void {
    document.getElementById("details-container").style.display = "flex";
    document.getElementById("uses-container").style.display = "none";
  }

  deleteCoupon(): void {
    if (this.selectedCoupon === null) {
      return;
    }

    // this.couponService.deleteCoupon(this.selectedCoupon.id).subscribe(
    //   () => {
    //     this.coupons = this.coupons.filter(
    //       (coupon) => coupon.id !== this.selectedCoupon?.id
    //     );
    //     this.hideDetails();
    //   },
    //   (err) => this.authService.handleError(err, "Failed to delete coupon!")
    // );
  }
}
