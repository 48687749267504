<div class="dialog_container">
  <i
    class="material-icons exit_icon"
    (click)="close()"
    >close</i
  >
  <h3>{{ title }}</h3>

  @if (descriptions.length > 0){
  <div class="text_info">
    <p *ngFor="let description of descriptions">{{ description }}</p>
  </div>
  } @if (object){
  <div class="object_info">
    <p *ngFor="let key of objectKeys">{{ key }}: {{ object[key] }}</p>
  </div>
  }
  <div class="buttons">
    <button
      class="pinploy_button small_button"
      (click)="close()"
    >
      OK
    </button>
  </div>
</div>
