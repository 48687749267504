import { Component, Input, OnInit, Output } from '@angular/core';
import { StateService } from 'src/app/services/state.service';

import { ToastrService } from 'ngx-toastr';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';

import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';

import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-request-user-mentioned-mobile-pay',
  templateUrl: './requestUserMentionedMobilePay.component.html',
  styleUrls: [
    './requestUserMentionedMobilePay.component.scss',
    '../requestHandlingPage.component.scss',
  ],
  animations: [
    trigger('fadeUpAnimation', [
      transition(':leave', [
        animate(
          '1000ms ease-out',
          keyframes([
            style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }), // Initial state
            style({ opacity: 1, transform: 'translateY(-2%)', offset: 0.4 }), // Start slow
            style({ opacity: 0, transform: 'translateY(-5%)', offset: 1 }), // Dissapear
          ]),
        ),
      ]),
    ]),
  ],
})
export class RequestUserMentionedMobilePayComponent implements OnInit {
  @Input() request: any;
  @Output() requestHandledEvent = new EventEmitter<any>();
  requestHandled: boolean = false;
  isLoading: boolean = false;

  constructor(
    private toastr: ToastrService,
    private stateService: StateService,
    private adminActionRequestService: AdminActionRequestService,
  ) {}

  ngOnInit() {}

  handleRequest() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'Mobilepay mentioned on task ' +
              this.request.task.id +
              ' marked as handled',
            'Success',
          );
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );

          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }
}
