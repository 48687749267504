<div
  class="type-container" 
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  <mat-spinner
    *ngIf="isLoading"
    class="mbpay-local-spinner"
  ></mat-spinner>
  <div
    class="box-wrapper"
    [ngClass]="{
      'box-wrapper': true,
      'box-wrapper--is-handled': request?.disabled,
      blur: isLoading
    }"
  >
    <div class="box">
      <div class="mb-pay-wrapper">
        <div class="request-information">
          <h2>User Mentioned Mobilepay</h2>
          <i>at: {{ request?.createdAt | date : 'hh:MM, dd/MM/yy' }}</i>
        </div>

        <div class="link-icon-area">
          <a
            href="/conversation/{{ request?.task?.id }}/search/mobilepay"
            target="_blank"
            matTooltip="Open entity in dedicated are"
          >
            <mat-icon>open_in_new</mat-icon>
          </a>
        </div>

        <div class="task-card-area">
          <app-task-card [task]="request?.task"></app-task-card>
        </div>
      </div>

      <div class="button-container">
        <button
          class="pinploy_button"
          (click)="handleRequest()"
          [disabled]="isLoading"
        >
          Mark request as handled
        </button>
      </div>
    </div>
  </div>
</div>
