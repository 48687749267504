import {
  Component,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MiscService } from '../../services/misc.service';
import { format, subHours } from 'date-fns';
import { map } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { BanUserDialogComponent } from '../../components/dialogs/ban-user/banUserDialog.component';
import { CensorEntryDialogComponent } from '../../components/dialogs/censor-entry/censorEntryDialog.component';
import { MessageUserDialogComponent } from '../../components/dialogs/message-user/messageUserDialog.component';
import { RemoveProfilePicDialogComponent } from 'src/app/components/dialogs/remove-profile-pic/removeProfilePicDialog.component';

type EntityType = 'user' | 'task' | 'offer' | 'offer-comment' | 'task-comment';
type CommentType = 'offer' | 'offer-comment' | 'task-comment';
type FilterType =
  | 'none'
  | 'offer'
  | 'offer-comment'
  | 'task-comment'
  | 'reports';
type FilterLabels = { name: FilterType; label: string }[];

interface ReportData {
  data: { [reportId: number]: any };
  offer: { [offerId: number]: number[] };
  offer_comment: { [offerCommentId: number]: number[] };
  task_comment: { [taskCommentId: number]: number[] };
  user: { [userId: number]: number[] };
  review: { [reviewId: number]: number[] };
}

const dataTypes = {
  offer: 'offer',
  'offer-comment': 'offer_comment',
  'task-comment': 'task_comment',
};

@Component({
  selector: 'content-feed',
  templateUrl: './contentFeed.component.html',
  styleUrls: ['./contentFeed.component.scss'],
})
export class ContentFeedComponent implements AfterViewInit {
  @ViewChild('startInput') startInput: ElementRef<HTMLInputElement>;

  data: any[] = [];
  filteredData: any[] = [];
  displayData: any[] = [];
  entriesPerPage = 100;
  pageNumber = 1;
  filter: FilterType = 'none';

  filterLabels: FilterLabels = [
    { name: 'none', label: 'No filter' },
    { name: 'offer', label: 'Offers' },
    { name: 'offer-comment', label: 'Offer Comments' },
    { name: 'task-comment', label: 'Task Comments' },
    { name: 'reports', label: 'Entities with reports' },
  ];

  reportData: ReportData = {
    data: {},
    offer: {},
    offer_comment: {},
    task_comment: {},
    user: {},
    review: {},
  };

  public start = this.formatDate(subHours(new Date(), 24));

  constructor(
    private miscService: MiscService,
    private reportService: ReportService,
    private deviceDetector: DeviceDetectorService,
    private router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private location: Location,
  ) {}

  ngAfterViewInit(): void {
    this.fetchReports();
    this.fetchData();
  }

  fetchData(start?: string) {
    this.miscService
      .getContentFeedData(start)
      .pipe(
        map((res: any[]) =>
          res.map((entry) => {
            if (entry.images) {
              entry.images = entry.images.split('\t');
            }
            return entry;
          }),
        ),
      )
      .subscribe((res) => {
        this.data = res;
        this.pageNumber = 1;
        this.applyFilterAndPagination();
      });
  }

  getMaxNumPages(): number {
    return Math.floor(this.filteredData.length / this.entriesPerPage) + 1;
  }

  getPaginationString(): string {
    if (this.filteredData.length === 0) {
      return '0 - 0 : 1 / 1';
    }

    const start = (this.pageNumber - 1) * this.entriesPerPage + 1;
    const end =
      this.pageNumber < this.getMaxNumPages()
        ? this.entriesPerPage * this.pageNumber
        : start - 1 + (this.filteredData.length % this.entriesPerPage);
    return `${start} - ${end} / ${this.filteredData.length}`;
  }

  scrollToTop(): void {
    document
      .getElementById('controls')
      .scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  applyFilterAndPagination(): void {
    switch (this.filter) {
      case 'none':
        this.filteredData = this.data.slice();
        break;
      case 'offer':
      case 'offer-comment':
      case 'task-comment':
        this.filteredData = this.data.filter(
          (entry) => entry.type === this.filter,
        );
        break;
      case 'reports':
        this.filteredData = this.data.filter((entry) =>
          this.hasReports(entry.type, entry.id),
        );
        break;
    }
    const start = (this.pageNumber - 1) * this.entriesPerPage;
    this.displayData = this.filteredData.slice(
      start,
      start + this.entriesPerPage,
    );
    this.cdRef.detectChanges();
  }

  changeEntriesPerPage(value: number): void {
    this.entriesPerPage = Math.max(1, Math.min(1000, Math.floor(value)));
    this.pageNumber = 1;
    this.applyFilterAndPagination();
  }

  prevPage(): void {
    if (this.pageNumber <= 1) {
      return;
    }
    this.pageNumber -= 1;
    this.applyFilterAndPagination();
  }

  nextPage(): void {
    if (this.pageNumber >= this.getMaxNumPages()) {
      return;
    }
    this.pageNumber += 1;
    this.applyFilterAndPagination();
  }

  fetchReports() {
    this.reportService.getReports().subscribe((res) => {
      res.forEach((report) => {
        this.reportData.data[report.id] = report;
        if (
          ['offer', 'offer_comment', 'task_comment', 'review', 'user'].includes(
            report.type,
          )
        ) {
          if (!this.reportData[report.type][report.itemId]) {
            this.reportData[report.type][report.itemId] = [report.id];
          } else if (
            !this.reportData[report.type][report.itemId].includes(report.id)
          ) {
            this.reportData[report.type][report.itemId].push(report.id);
          }
        }
      });
    });
  }

  refetch() {
    this.fetchData(this.startInput.nativeElement.value);
  }

  generateEntityUrl(type: EntityType, id: number) {
    switch (type) {
      case 'user':
        return `/users/${id}`;
      case 'task':
        return `/tasks/${id}`;
      case 'offer':
        return `/offers/${id}`;
      default:
        throw new Error('Unknown entity type');
    }
  }

  generateCommentUrl(type: CommentType, id: number): string {
    switch (type) {
      case 'offer':
        return `/comments/offer/${id}`;
      case 'offer-comment':
        return `/comments/offer-comm/${id}`;
      case 'task-comment':
        return `/comments/task-comm/${id}`;
      default:
        throw new Error('Unknown entity type');
    }
  }

  hasReports(type: string, id: number) {
    const reportType = dataTypes[type];
    if (reportType) {
      return (
        this.reportData[reportType][id] !== undefined &&
        this.reportData[reportType][id].length > 0
      );
    }
  }

  getReports(type: string, id: number) {
    const reportType = dataTypes[type];
    if (reportType) {
      const reportIds: number[] = this.reportData[reportType][id];
      return reportIds.map((id) => this.reportData.data[id]);
    }
    return [];
  }

  formatDate(date: string | Date) {
    date = typeof date === 'string' ? new Date(date) : date;
    return format(date, 'dd-MM-yyyy');
  }

  formatDateTime(date: string | Date) {
    date = typeof date === 'string' ? new Date(date) : date;
    return format(date, 'dd-MM-yyyy kk:mm:ss');
  }

  openEdit(type: CommentType, id: number) {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog
      .open(CensorEntryDialogComponent, {
        width: width,
        data: { type, id },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.updateEntry(type, id, res);
        }
      });
  }

  openBanUser(userId: number) {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(BanUserDialogComponent, {
      width: width,
      data: { userId },
    });
  }

  openMessageUser(userId: number) {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(MessageUserDialogComponent, {
      width: width,
      data: { userId },
    });
  }

  updateEntry(type: CommentType, id: number, data: any) {
    const entry = this.data.find(
      (item: any) => item.type === type && item.id === id,
    );
    if (!entry) {
      throw new Error('Could not find entry');
    }

    const updatedText = type === 'offer' ? data.message : data.text;
    if (updatedText) {
      entry.text = updatedText;
    }
    if (data.censored !== undefined) {
      entry.censored = data.censored;
    }
  }

  openRemoveProfilePicture(userId: number) {
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';
    this.dialog.open(RemoveProfilePicDialogComponent, {
      width: width,
      data: { userId },
    });
  }

  formatType(type: string) {
    switch (type) {
      case 'offer':
        return 'O';
      case 'offer-comment':
        return 'OC';
      case 'task-comment':
        return 'TC';
      default:
        return '?';
    }
  }
}
