import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class FaqService {
  dashboardHref = `${environment.dashboardAPIUrl}/api/faqs`

  constructor(private http: HttpClient) {
  }

  getMainFaqs():Observable<any> {
    const url = `${this.dashboardHref}/mainCats`;
    return this.http.get(url);
  }

  getFAQTitles(countryCode: string, category: string, refeer: string = ''): Observable<any> {
    const url = `${this.dashboardHref}/titles/${countryCode}/${category}/${refeer}`;
    return this.http.get(url);
  }
}
