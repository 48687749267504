import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { MiscService } from 'src/app/services/misc.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search-field',
  templateUrl: './searchField.component.html',
  styleUrls: ['./searchField.component.scss'],
})
export class SearchFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('searchDialog') searchDialog: any;
  @ViewChild('searchInput') searchInput: any;
  @ViewChild('dropdown') dropdown: any;
  private clickListener: Function;
  private clickListener2: Function;

  private timeOut: ReturnType<typeof setTimeout> | null;
  private searchInputChange = new Subject<string>();
  private searchInputSubscription: Subscription;
  searchQuery: string;
  loading = false;
  results: any;
  searchFocussed = false;
  searchTypeSelected = 'all';
  isInputFocused = false;

  constructor(
    private miscService: MiscService,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.searchInputSubscription = this.searchInputChange
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.searchQuery = searchValue;
        this.fetchDataForSearch();
      });
  }

  ngOnInit() {
    this.attachEventListeners();
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
    this.isInputFocused = true;
    this.focusSearch(null);
  }

  attachEventListeners() {
    this.clickListener = this.renderer.listen('document', 'click', (event) => {
      if (
        !this.searchDialog?.nativeElement?.contains(event.target) &&
        this.searchFocussed
      ) {
        if (this.searchFocussed && this.searchQuery?.length > 0) {
          this.searchFocussed = false;
        }
      }
    });
    this.clickListener2 = this.renderer.listen('document', 'click', (event) => {
      if (this.searchFocussed && this.searchQuery?.length > 0) {
        this.searchFocussed = true;
      }
    });
  }

  fetchDataForSearch(): void {
    this.loading = true;
    if (this.timeOut !== null) {
      clearTimeout(this.timeOut);
    }

    this.timeOut = setTimeout(() => {
      if (!this.searchQuery) {
        this.results = {};
        this.loading = false;
      } else {
        switch (this.searchTypeSelected) {
          case 'users':
            this.miscService
              .getSearchResults(this.searchQuery, 'users')
              .subscribe(
                (res) => {
                  this.results = res;
                  if (this.results) {
                    this.searchFocussed = true;
                  }
                  if (res.users.length === 0) {
                    this.toastr.warning('No users found');
                  }
                },
                (err) => {
                  this.authService.handleError(err, 'Failed to search');
                },
                () => {
                  this.loading = false;
                },
              );
            break;
          case 'tasks':
            this.miscService
              .getSearchResults(this.searchQuery, 'tasks')
              .subscribe(
                (res) => {
                  this.results = res;
                  if (this.results) {
                    this.searchFocussed = true;
                  }
                  if (res.tasks.length === 0) {
                    this.toastr.warning('No tasks found');
                  }
                },
                (err) => {
                  this.authService.handleError(err, 'Failed to search');
                },
                () => {
                  this.loading = false;
                },
              );
            break;
          case 'offers':
            this.miscService
              .getSearchResults(this.searchQuery, 'offers')
              .subscribe(
                (res) => {
                  this.results = res;
                  if (this.results) {
                    this.searchFocussed = true;
                  }
                  if (res.offers.length === 0) {
                    this.toastr.warning('No offers found');
                  }
                },
                (err) => {
                  this.authService.handleError(err, 'Failed to search');
                },
                () => {
                  this.loading = false;
                },
              );
            break;
          case 'offercomments':
            this.miscService
              .getSearchResults(this.searchQuery, 'offercomments')
              .subscribe(
                (res) => {
                  this.results = res;
                  if (this.results) {
                    this.searchFocussed = true;
                  }
                  if (res.offerComments.length === 0) {
                    this.toastr.warning('No offer comments found');
                  }
                },
                (err) => {
                  this.authService.handleError(err, 'Failed to search');
                },
                () => {
                  this.loading = false;
                },
              );

            break;
          case 'all':
            this.miscService
              .getSearchResults(this.searchQuery, 'all')
              .subscribe(
                (res) => {
                  this.results = res;
                  if (this.results) {
                    this.searchFocussed = true;
                  }
                  if (
                    res.users.length === 0 &&
                    res.tasks.length === 0 &&
                    res.offers.length === 0 &&
                    res.offerComments.length === 0 &&
                    res.taskComments.length === 0
                  ) {
                    this.toastr.warning('No results found');
                  }
                },
                (err) => {
                  this.authService.handleError(err, 'Failed to search');
                },
                () => {
                  this.loading = false;
                },
              );

            break;
        }
      }
    }, 250);
  }

  navToUser(id: string) {
    this.router.navigate(['users', id]);
    this.searchFocussed = false;
  }
  navToTask(id: string) {
    this.router.navigate(['tasks', id]);
    this.searchFocussed = false;
  }
  navToOffer(taskId: string, offerId: string) {
    this.router.navigate(['tasks', taskId, 'offer', offerId]);
    this.searchFocussed = false;
  }
  navToOfferComment(taskId: string, offerCommentId: string) {
    this.router.navigate(['tasks', taskId, 'offer-comm', offerCommentId]);
    this.searchFocussed = false;
  }
  navToTaskComment(taskId: string, taskCommentId: string) {
    this.router.navigate(['tasks', taskId, 'task-comm', taskCommentId]);
    this.searchFocussed = false;
  }

  get hasResults(): boolean {
    return (
      this.results &&
      Object.values(this.results).some((array: any) => array.length !== 0)
    );
  }

  onSearchInput(event: any): void {
    switch (true) {
      case /^!\S?/.test(event.target.value):
        this.searchTypeSelected = 'users';
        this.searchQuery = '';
        break;
      case /^#\S?/.test(event.target.value):
        this.searchTypeSelected = 'tasks';
        this.searchQuery = '';
        break;
      case /^\$\S?/.test(event.target.value):
        this.searchTypeSelected = 'offers';
        this.searchQuery = '';
        break;
      case /^\//.test(event.target.value):
        this.searchTypeSelected = 'offercomments';
        this.searchQuery = '';
        break;
      case /^%\S?/.test(event.target.value):
        this.searchTypeSelected = 'taskcomments';
        this.searchQuery = '';
        break;
      default:
        break;
    }

    if (!/^[\!\#\$\%\/]$/.test(event.target.value)) {
      this.searchInputChange.next(event.target.value);
    }

    if (event.key === 'Enter') {
      if (this.searchQuery) {
        this.fetchDataForSearch();
      }
    }
  }

  onSelectionChange(event: any) {
    switch (event.value) {
      case 'users':
        this.searchQuery = '';
        break;
      case 'tasks':
        this.searchQuery = '';
        break;
      case 'offers':
        this.searchQuery = '';
        break;
      case 'offercomments':
        this.searchQuery = '';
        break;
      case 'taskcomments':
        this.searchQuery = '';
        break;
      default:
        this.searchQuery = '';
        break;
    }
  }

  focusSearch(event: any) {
    this.results = {};
    this.searchFocussed = true;
  }

  unFocusSearch(event: any) {
    this.searchFocussed = false;
    this.searchQuery = '';
  }

  checkBlur(event: FocusEvent) {
    if (event.relatedTarget === null) {
      this.isInputFocused = false;
      return;
    }
    if (this.dropdown?.nativeElement.contains(event.relatedTarget)) {
      return;
    }
    this.isInputFocused = false;
  }

  onDropdownClosed(): void {
    this.searchInput.nativeElement.focus();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape() {
    this.searchInput.nativeElement.blur();
    this.searchTypeSelected = 'all';
    this.isInputFocused = false;
    this.searchFocussed = false;
    this.searchQuery = '';
  }
}
