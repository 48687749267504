<div id="coupons-container">
  <div id="controls">
    <button (click)="showEdit()">Add new coupon</button>
  </div>
  <paginated-list
    [data]="coupons"
    (displayDataChanged)="updateDisplayCoupons($event)"
    (dataRequested)="fetchMoreCoupons()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
  >
    <div class="list">
      <a
        class="item"
        *ngFor="let coupon of displayCoupons"
        (click)="showDetails(coupon)"
      >
        <p class="id"><span class="bold">ID: </span>{{ coupon.id }}</p>
        <p class="long-content">
          <span class="bold">Name: </span>{{ coupon.name }}
        </p>
        <p class="long-content">
          <span class="bold">Comment: </span>{{ coupon.comment }}
        </p>
        <p><span class="bold">Code: </span>{{ coupon.code }}</p>
        <p><span class="bold">Type: </span>{{ getType(coupon) }} off</p>
      </a>
    </div>
  </paginated-list>
</div>

<div id="details-container">
  <app-coupon-details
    [coupon]="selectedCoupon"
    (close)="hideDetails()"
    (edit)="showEdit()"
    (delete)="deleteCoupon()"
    (showUses)="showUses()"
  ></app-coupon-details>
</div>

<div id="edit-container">
  <app-coupon-create-edit
    [coupon]="selectedCoupon"
    (close)="hideEdit()"
  ></app-coupon-create-edit>
</div>

<div id="uses-container">
  <app-coupon-uses
    [coupon]="selectedCoupon"
    (close)="hideUses()"
  ></app-coupon-uses>
</div>
