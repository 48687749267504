import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class CommentService {
  private baseHref = `${environment.apiUrl}/api`;

  constructor(
    private http: HttpClient,
  ) {}

  // GET /api/offer-comments/:id
  getOfferCommentById(id: number): Observable<any> {
    const url = `${this.baseHref}/offer-comments/${id}`;
    return this.http.get(url);
  }

  // PUT /api/offer-comments/:id
  editOfferComment(id: number, data: { text?: string, censored?: Date, sendEmail?: boolean }): Observable<any> {
    const url = `${this.baseHref}/offer-comments/${id}`;
    return this.http.put(url, {
      text: data.text,
      censored: data.censored ? data.censored.toISOString() : null,
      sendEmail: data.sendEmail
    });
  }

  // GET /api/comments/:id
  getTaskCommentById(id: number): Observable<any> {
    const url = `${this.baseHref}/comments/byId/${id}`;
    return this.http.get(url);
  }

  createAdminOfferComment(data: { offerId: number, taskId: number, text: string, user: User}): Observable<any> {
    const url = `${this.baseHref}/offer-comments`;
    return this.http.post(url, data);
  }

  // PUT /api/comments/:id
  editTaskComment(id: number, data: { text?: string, censored?: Date, sendEmail?: boolean }): Observable<any> {
    const url = `${this.baseHref}/comments/${id}`;
    return this.http.put(url, {
      text: data.text,
      censored: data.censored ? data.censored.toISOString() : null,
      sendEmail: data.sendEmail
    });
  }

  createAdminTaskComment(data: { taskId: number, parentId: number, text: string, user: User}): Observable<any> {
    const url = `${this.baseHref}/comments`;
    return this.http.post(url, {
      taskId: data.taskId,
      parentId: data.parentId,
      text: data.text,
      user: data.user
    });
  }
}
