<div class="container">
  <h3>{{ taskId }}: {{ taskTitle }}</h3>
  <p>{{ taskDescription }}</p>
  <div class="tag-field">
    <mat-form-field class="input">
      <mat-chip-listbox #taglist>
        <mat-chip-option
          selected
          color="primary"
          *ngFor="let tag of selectedTags"
          [selectable]="true"
          [removable]="true"
          (removed)="removeTag(tag.id)"
        >
          {{ tag.tag }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
        <input
          placeholder="Add tags"
          [matChipInputFor]="taglist"
          #tagInput
          [matAutocomplete]="auto"
          [formControl]="tagInputValue"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          class="input-field"
        />
      </mat-chip-listbox>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectTag($event)"
      >
        <mat-option
          *ngFor="let tag of filteredTags | async"
          [value]="tag.tag"
        >
          {{ tag.tag }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="buttons">
    <button
      class="pinploy_button button-margins"
      (click)="saveAndClose(false)"
    >
      Save & Close
    </button>
    <button
      class="pinploy_button button-margins"
      (click)="saveAndClose(true)"
    >
      Save & Next
    </button>
  </div>
</div>
