import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class TagService {
  baseHref = `${environment.apiUrl}/api/tag`;
  constructor(
    private http: HttpClient,
  ) {}

  getTaskTagsFromTaskId(taskId): Observable<any[]> {
    const url = `${this.baseHref}/tasks-tags/${taskId}`;
    return this.http.get<any[]>(url);
  }

  updateTagFromTask(data): Observable<any[]> {
    const url = `${this.baseHref}/update-tags-on-task`;
    return this.http.post<any>(url, data);
  }

  getAllTags(): Observable<any[]> {
    const url = `${this.baseHref}/get-all-tags`;
    return this.http.get<any[]>(url);
  }

  getUsersTagsFromUserId(userId): Observable<any[]> {
    const url = `${this.baseHref}/users-tags/${userId}`;
    return this.http.get<any[]>(url);
  }

  updateTagFromUser(data): Observable<any[]> {
    const url = `${this.baseHref}/update-tags-on-user`;
    return this.http.post<any>(url, data);
  }

  getTagsAssociatedByTagCategory(): Observable<any> {
    const url = `${this.baseHref}/get-tags-associated-by-cat`;
    return this.http.get<any>(url);
  }
}
