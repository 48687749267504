import { NgModule } from "@angular/core";
import { AppComponent } from "./root/app.component";
import { components } from "./app.components";
import { services } from "./app.services";
import { imports } from "./app.imports";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DecoratorService } from "./services/decorator.service";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    imports: [imports, ReactiveFormsModule, FormsModule, MatDialogModule],
    declarations: [components],
    providers: [services],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(decoratorService: DecoratorService) {}
}
