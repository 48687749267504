import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { SupportTicket } from '../models/supportTicket.model';

import { environment } from '../../environments/environment';
import { SupportTicketAdmin } from '../models/supportTicketAdmin.model';
import { SocketService } from './socket.service';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class SupportTicketService {
  baseHrf = `${environment.apiUrl}/api/support-ticket`;
  dashboardHref = `${environment.dashboardAPIUrl}/api/support/`;

  constructor(private http: HttpClient, private socketService: SocketService) {}

  getSupportTicket(id: number): Observable<SupportTicket> {
    if (id && Number.isInteger(id)) {
      const url = `${this.baseHrf}/detail/${id}`;
      return this.http.get<SupportTicket>(url);
    }
    if (id && typeof id === 'string') {
      id = +id;
      const url = `${this.baseHrf}/detail/${id}`;
      return this.http.get<SupportTicket>(url);
    }
  }

  getSupportTicketAnswer(
    supportTicketId: number,
  ): Observable<SupportTicketAdmin> {
    if (supportTicketId && Number.isInteger(supportTicketId)) {
      const url = `${this.baseHrf}/support-ticket-answer/${supportTicketId}`;
      return this.http.get<SupportTicketAdmin>(url);
    }
    if (supportTicketId && typeof supportTicketId === 'string') {
      supportTicketId = +supportTicketId;
      const url = `${this.baseHrf}/support-ticket-answer/${supportTicketId}`;
      return this.http.get<SupportTicketAdmin>(url);
    }
  }

  getSupportTickets(): Observable<SupportTicket[]> {
    const url = `${this.baseHrf}/all`;
    return this.http.get<SupportTicket[]>(url);
  }

  getUnhandledSupportTicketsCount(): Observable<number> {
    const url = `${this.baseHrf}/unhandled-count`;
    return this.http.get<number>(url);
  }

  getAllSupportTicketsFromUserId(userId: number): Observable<SupportTicket[]> {
    if (userId && Number.isInteger(userId)) {
      const url = `${this.baseHrf}/all/${userId}`;
      return this.http.get<SupportTicket[]>(url);
    }
    if (userId && typeof userId === 'string') {
      userId = +userId;
      const url = `${this.baseHrf}/all/${userId}`;
      return this.http.get<SupportTicket[]>(url);
    }
  }

  getSupportEmailTemplates(): Observable<any> {
    const url = `${this.dashboardHref}templates`;
    return this.http.get<any>(url);
  }

  getSupportTicketTemplateByCatOrSubCat(catData): Observable<any> {
    if (catData) {
      const url = `${this.dashboardHref}by-cat-or-subcat`;
      return this.http.post<any>(url, catData);
    }
  }

  updateSupportTicketStatus(statusObj, id): Observable<any> {
    const socket = this.socketService.getSocketId();
    statusObj.socketId = socket;
    if (id && Number.isInteger(id)) {
      const url = `${this.baseHrf}/update/${id}`;
      return this.http.put<any>(url, statusObj);
    }
    if (id && typeof id === 'string') {
      id = +id;
      const url = `${this.baseHrf}/update/${id}`;
      return this.http.put<any>(url, statusObj);
    }
  }

  createSupportTicketAdmin(data): Observable<SupportTicketAdmin> {
    const socket = this.socketService.getSocketId();
    data.socketId = socket;
    if (data) {
      const url = `${this.baseHrf}/create-admin-ticket`;
      return this.http.post<SupportTicketAdmin>(url, data);
    }
  }
}
