import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'user-penalties--dialog',
  templateUrl: 'userPenaltiesDialog.component.html',
  styleUrls: ['../dialogs.scss', 'userPenaltiesDialog.component.scss'],
})
export class UserPenaltiesDialogComponent implements OnInit {
  constructor(
    public userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserPenaltiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user penalties dialog');
    }
  }

  userData: User;
  userAppliedPenalties = [];
  userPendingPenalties = [];
  userCanceledPenalties = [];
  userCompletedPenalties = [];
  userTransferedPenalties = [];

  ngOnInit(): void {
    this.userService.getPenalties(this.data.userId).subscribe((res: any) => {
      this.userAppliedPenalties = res.filter((p) => p.status === 'applied');
      this.userPendingPenalties = res.filter((p) => p.status === 'pending');
      this.userCanceledPenalties = res.filter((p) => p.status === 'canceled');
      this.userCompletedPenalties = res.filter((p) => p.status === 'completed');
      this.userTransferedPenalties = res.filter(
        (p) => p.status === 'transfered',
      );
    });
  }

  closeDialogEvent() {
    this.close();
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }
}
