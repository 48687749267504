import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { NotificationSegment } from "src/app/models/notificationSegment";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification-scheduled-details",
  templateUrl: "./notification-scheduled-details.component.html",
  styleUrls: ["./notification-scheduled-details.component.scss"],
})
export class NotificationScheduledDetails implements OnInit {
  notificationId: number;
  notification$: Observable<NotificationSegment>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.notification$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        const notificationId = params.get("id");
        if (!notificationId || isNaN(+notificationId)) {
          this.navigateBack();
          this.toastr.error("Invalid notification id!", "Error");
          return;
        }
        this.notificationId = +notificationId;
        return this.notificationService.searchScheduledNotification(
          +notificationId
        );
      }),
      catchError((err) => {
        this.navigateBack();
        this.toastr.error(err.message, "Error");
        return [];
      })
    );
  }

  navigateBack(): void {
    this.router.navigate(["/notification-segments"], {
      queryParams: { tabs: 1 },
    });
  }
}
