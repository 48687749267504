<div class="report_container">
  <h4 class="section_title">Report properties</h4>
  <div class="properties_section">
    <!-- ID -->
    <div class="field_row">
      <p>ID</p>
      <p>{{report.id}}</p>
    </div>

    <div class="field_row">
      <p>Status</p>
      <p>{{report.status}}</p>
    </div>

    <div class="field_row">
      <p>Reason</p>
      <p>{{report.reason}}</p>
    </div>

    <div class="field_row">
      <p>Type</p>
      <p>{{report.type}}</p>
    </div>

    <div class="field_row">
      <p>Creation date</p>
      <p>{{report.createdAt | date:'medium'}}</p>
    </div>

    <div class="field_row">
      <p>Update date</p>
      <p>{{report.updatedAt | date:'medium'}}</p>
    </div>

    <div class="field_row">
      <p>Report message</p>
      <p class="text_field">{{report.message}}</p>
    </div>
  </div>
  <div class="action_row">
    <button *ngIf="this.report.status == 'active'" class="pinploy_button small_button save_btn" [disabled]="saving"
      (click)="onSetStatusRemoved()" [ngClass]="{ 'disabled': saving}">
      Close report</button>
    <button *ngIf="this.report.status == 'removed'" class="pinploy_button small_button save_btn" [disabled]="saving"
      (click)="onSetStatusActive()" [ngClass]="{ 'disabled': saving}">
      Set active</button>
    <button class="pinploy_button small_button save_btn" [matMenuTriggerFor]="emailMenu"
      [disabled]="report.task == null" [ngClass]="{'disabled': report.task == null}">
      Send an e-mail</button>
    <mat-menu #emailMenu="matMenu" yPostion='below' [overlapTrigger]="false">
      <button mat-menu-item (click)="onSendEmail('doNotShareContactInfo')">Do not share contact info</button>
      <button mat-menu-item (click)="onSendEmail('rudeLanguage')">Rude language</button>
    </mat-menu>
    <mat-spinner *ngIf="saving" diameter="30"></mat-spinner>
  </div>
  <mat-divider></mat-divider>
  <h4 class="section_title">Related objects</h4>
  <div class="object_section">
    <app-user-card *ngIf="report.target" title="Target user" [user]="report.target"></app-user-card>
    <app-user-card *ngIf="report.reporter" title="Reporter user" [user]="report.reporter"></app-user-card>
    <app-task-card *ngIf="report.task" title="Task" [task]="report.task"></app-task-card>
    <app-offer-card *ngIf="report.offer" title="Offer" [offer]="report.offer"></app-offer-card>
    <app-comment-card *ngIf="report.taskComment" title="Reported task comment" [comment]="report.taskComment"
      [type]="report.type"></app-comment-card>
    <app-comment-card *ngIf="report.offerComment" title="Reported offer comment" [comment]="report.offerComment"
      [type]="report.type"></app-comment-card>
    <app-reported-review-card *ngIf="report.review" title="Reported review" [review]="report.review"></app-reported-review-card>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="report.task">
    <app-task-conversation [inputType]="conversationType" [inputTypeId]="conversationTypeId"></app-task-conversation>
  </div>
</div>