import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'user-tasks-dialog',
  templateUrl: 'userTasks.component.html',
  styleUrls: ['../dialogs.scss', 'userTasks.component.scss'],
})
export class UserTasksDialogComponent implements OnInit {
  constructor(
    public userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserTasksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user tasks dialog');
    }
  }

  userData: User;
  userTasks = [];
  openUserTasks = [];
  ongoingUserTasks = [];
  acceptedUserTasks = [];
  completedUserTasks = [];
  cancelledUserTasks = [];
  expiredUserTasks = [];
  erasedUserTasks = [];
  hasAcceptedOffer: boolean;

  ngOnInit(): void {
    this.fetchUserData();

    // All tasks owned by the user
    this.userTasks = this.data.userTasks;

    // Tasks that the user owns, that are open, that has not been accepted by a Handyhander/PP
    const tempOpenUserTasks = this.userTasks.filter(
      (task) => task.status === 'pending',
    );
    // this.openUserTask is equal to tempOpenUserTasks, but only tasks that is not accepted by a Handyhander/PP
    this.openUserTasks = tempOpenUserTasks.filter(
      (task) =>
        task.offers.filter((offer) => offer.status === 'accepted').length === 0,
    );

    // Tasks that the user owns, that has been accepted by a Handyhander/PP
    const tempOngoingUserTasks = this.userTasks.filter(
      (task) => task.status === 'pending',
    );
    this.ongoingUserTasks = tempOngoingUserTasks.filter(
      (task) =>
        task.offers.filter((offer) => offer.status === 'accepted').length > 0,
    );

    // Tasks that the user owns, that has been completed
    this.completedUserTasks = this.userTasks.filter(
      (task) => task.status === 'completed',
    );

    // Tasks that the owner has cancelled before accepting any offers
    this.cancelledUserTasks = this.userTasks.filter(
      (task) => task.status === 'canceled',
    );

    // Tasks that the owner has, that has expired
    this.expiredUserTasks = this.userTasks.filter(
      (task) => task.status === 'expired',
    );

    // Tasks that the owner has, that has been erased
    this.erasedUserTasks = this.userTasks.filter(
      (task) => task.status === 'erased',
    );
  }

  closeDialogEvent() {
    this.close();
  }

  fetchUserData(): void {
    this.userService
      .getUserProfile(this.data.userId)
      .subscribe((result) => (this.userData = result));
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }
}
