<div>
    <h3 class="header">Create new user note</h3>
</div>

<div class="dialog_container">
    <div class="dialog_column_container">

        <mat-form-field appearance="outline">
            <mat-label>Note</mat-label>
            <textarea cdkTextareaAutosize cdkAutosizeMaxRows="10" matInput type="text"
                [(ngModel)]="userNote"></textarea>
        </mat-form-field>

    </div>

    <div class="buttons">
        <button class="pinploy_button small_button red_button" (click)="close()">Cancel</button>
        <button class="pinploy_button small_button" (click)="submit()">Create note</button>

    </div>
</div>
